<div class="col-sm-12">
    <div class="text-center">
        <div class="text-center">
            <small class="text-danger mb-2 fw-bold text-xs">
              <i class="fa fa-info-circle me-1"></i>
              Please make sure you have your camera enabled!
            </small>
          </div>
    
        <div class="form-group justify-content-center">
            <label for="id_number" class="form-label fw-bold text-xxs">
                ID Number <span class="text-danger fw-bold">*</span>
            </label>
            <input
                type="text"
                class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm justify-content-center"
                placeholder="Enter an SA ID Number"
                v-model="client_id"
                disabled
                maxlength="13"
            />
            <div class="d-flex flex-column col-12 col-md-9 col-lg-8 mx-auto mt-1"></div>
        </div>
    
        <button
            class="btn bg-green btn-sm text-xs mt-3"
            @click="start_liveness_check"
            :disabled="!client_id"
        >
            Start Facial Biometrics
        </button>
    
        <LivenessCheck 
            :use_id_input="false"
            ref="liveness_check"
            @set_photo_src="set_photo_src"
        />
    </div>
</div>
