<div class="form-group mb-4 w-75">
    <label for="first_name" class="fw-bold text-xs text-start d-block">First Name <span class="text-danger">*</span></label>
    <input
      v-model="first_name"
      type="text"
      class="form-control rounded-small shadow-sm w-100"
      id="first_name"
      placeholder="e.g., Joe"
      required
    />
  </div>

  <!-- Last Name -->
  <div class="form-group mb-4 w-75">
    <label for="last_name" class="fw-bold text-xs text-start d-block">Last Name <span class="text-danger">*</span></label>
    <input
      v-model="last_name"
      type="text"
      class="form-control rounded-small shadow-sm w-100"
      id="last_name"
      placeholder="e.g., Doe"
      required
    />
  </div>

  <!-- Email Address -->
  <div class="form-group mb-4 w-75">
    <label for="email_address" class="fw-bold text-xs text-start d-block">Email Address <span class="text-danger">*</span></label>
    <input
      v-model="email_address"
      type="email"
      class="form-control rounded-small shadow-sm w-100"
      id="email_address"
      placeholder="e.g., example@test.com"
      required
    />
  </div>

  <!-- Phone Number -->
  <div class="form-group mb-4 w-75">
    <label for="phone_number" class="fw-bold text-xs text-start d-block">Phone Number <span class="text-danger">*</span></label>
    <input
      v-model="phone_number"
      type="text"
      class="form-control rounded-small shadow-sm w-100"
      id="phone_number"
      placeholder="e.g., 9123456789"
      required
    />
  </div>