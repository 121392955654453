import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validate_id } from "@/utils/validations";


export default {
  name: 'BankAccountVerification',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components:{
    BreadCrumb,
    ClientLogo
  },

  data() {
    return {
        banks: [],
        account_types: [],
        id_types: [],
        id_type:'',
        bank: '',
        last_name:'',
        id_number:'',
        account_type:'',
        account_number: '',
        error_messages: {
          id_type:'',
          bank: '',
          last_name:'',
          id_number:'',
          account_type:'',
          account_number: '',

      },
    };
  },
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  watch: {
    id_number: function (val) {
      if (val && val.length == 13) {
        var id_test = validate_id(val);
        if(id_test){
          this.id_type = "SID";
        }
        else{
          if(this.id_type == "SID"){
            this.id_type = "";
          }
        }
      }
      else{
        if(this.id_type == "SID"){
          this.id_type = "";
        }
      }
    },
  },
  methods: {

    get_banks(){
        const TOKEN = localStorage.getItem('token');
        const GET_BANKS_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_banks/`;
        
        $.ajax({
          url: GET_BANKS_URL,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            this.loading = true;
          },
          success: (response) => {
            this.banks = response;
            this.loading = false; 
          },
          error: () => {
            this.loading = false; 
            let error_message = 'Error Getting banks';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
    },

    get_account_types(){
        const TOKEN = localStorage.getItem('token');
        const GET_ACCOUNT_TYPES_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_account_types/`;
        
        $.ajax({
          url: GET_ACCOUNT_TYPES_URL,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            this.loading = true;
          },
          success: (response) => {
            this.account_types = response;
            this.loading = false; 
          },
          error: () => {
            this.loading = false; 
            let error_message = 'Error account types';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
    },

    get_id_types(){
      const TOKEN = localStorage.getItem('token');
      const GET_ID_TYPES_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_id_types/`;
      
      $.ajax({
        url: GET_ID_TYPES_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.id_types = response;
          this.loading = false; 
        },
        error: () => {
          this.loading = false; 
          let error_message = 'Error account types';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    validate_fields() {
      this.error_messages = {};

      if (!this.last_name) {
        this.error_messages.last_name = "Please enter your surname";
      }
      if (!this.id_number) {
        this.error_messages.id_number = "Please enter your id number";
      }
      if (!this.id_type) {
        this.error_messages.id_type = "Please select id type";
      }
      if (!this.bank) {
          this.error_messages.bank = "Please select a bank.";
      }
      if (!this.account_type) {
        this.error_messages.account_type = "Please select account type.";
      }
      if (!this.account_number) {
        this.error_messages.account_number = "Account number is required.";
      }
      return Object.keys(this.error_messages).length === 0;
    },

    bank_account_individual_verification(){ 
        if (!this.validate_fields()) {
          return;
        }

        const TOKEN = localStorage.getItem('token');
        const BANK_ACCOUNT_INDIVIDUAL_VERIFY_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/bank_account_individual_verify/`;

        const FORM_DATA = {
            workflow_id:  this.workflow_id,
            bank_name: this.bank,
            bank_account: this.account_number,
            account_type: this.account_type,
            id_type: this.id_type,
            surname: this.last_name,
            id_number: this.id_number
        };

        $.ajax({
            url: BANK_ACCOUNT_INDIVIDUAL_VERIFY_URL,
            method: 'POST',
            contentType: 'application/json',
            headers: {
                Authorization: `Token ${TOKEN}` 
            },
            data: JSON.stringify(FORM_DATA),
            beforeSend: () => {
                Swal.fire({
                    text: 'Loading ...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            },
            success: (response) => {
                Swal.close();

                if(response.status == "error"){
                  Swal.fire({
                    icon: 'error',
                    text: "Our service is currently unavailable. Please contact your service provider and try again later",
                  }).then(() => {
                    this.$router.push({ name: 'thank_you_page' });
                  });
                }else{
                  this.fetch_current_service();
                }

            },
            error: () => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  text: "Bank details not submitted, please try again",
                })
            }

        });
    
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_banks();
    this.get_account_types();
    this.get_id_types();
    this.get_customer_data();
  }
};
