<div class="container-fluid">
    <div class="row mt-0">
        <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4">
            <div class="card-header bg-white border-0">
            Template Management
             <button type="button" class="btn btn-dark shadow rounded-small text-xxs float-end " @click="add_template">
                <i class="fas fa-plus"></i> Add Template
            </button>

            </div>
            <div class="card-body">
            <table id="templates_table" class="table-responsive w-100">
                <thead class="bg-dark text-white">
                <tr>
                    <th class="bg-dark text-center text-uppercase text-xxs font-weight-bolder opacity-7">No.</th>
                    <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Template Name</th>
                    <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Description</th>
                    <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Template Type</th>
                    <th class="bg-dark text-uppercase text-xxs font-weight-bolder opacity-7">Date Created</th>
                    <th class="bg-dark text-center text-uppercase text-xxs font-weight-bolder opacity-7">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(template, index) in templates" :key="template.id">
                    <td class="text-center text-xxs ">{{ index + 1 }}</td>
                    <td class="text-xxs text-start">{{ template.template_name }}</td>
                    <td class="text-xxs  text-start">{{ template.description }}</td>
                    <td class="text-xxs text-start">{{ template.template_type }}</td>
                    <td class="text-xxs text-start">{{ template.date_created }}</td>
                    <td class="text-xxs text-center ">
                        <button class="btn btn-dark rounded-small btn-sm me-1 text-xxs mb-2 shadow fw-bold">Update</button>
                        <button class="btn bg-green rounded-small btn-sm me-1 text-xxs mb-2 shadow fw-bold" @click="view_template_configuration(template.id)">Configure</button>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        </div>
    </div>

    <!-- add template Modal -->
    <div class="modal fade" id="add_template_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="add_template_modal_label" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-s fw-bold" id="add_template_modal_label">Add Template</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                <label class="form-label fw-bold"><small>Template Name</small></label>
                <input v-model="template_name" type="text" class="form-control text-xs" required placeholder="Enter Template Name">
                </div>
              
                <div class="col mb-2">
                    <label class="form-label fw-bold"><small>Select Template Type</small></label>
                    <select v-model="template_type" class="form-select text-xs" required>
                        <option value="" disabled hidden>Select Type</option>
                        <option v-for="(template_type, index) in template_types" :key="template_type.id">
                            {{ template_type.template_type }}
                        </option>
                        <option value="Other">Other(specify)</option>
                    </select>
                </div>

                <div v-if="template_type === 'Other'" class="col mb-2">
                    <input v-model="new_template_type" type="text" class="form-control text-xs" required placeholder="Please specify template type">
                </div>

                <div class="col mb-2">
                    <label class="form-label fw-bold"><small>Description</small></label>
                    <input v-model="template_description" type="text" class="form-control text-xs" required placeholder="Enter Description">
                </div>

                <div class="col mb-2">
                    <label class="form-label fw-bold"><small>Upload Template</small></label>
                    <input type="file" accept=".pdf" @change="on_file_change" class="form-control text-xs" required="True">
                </div>
                <div class="col text-end">
                    <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill mt-3" @click="create_new_template">Save</button>
                </div>
            </div>
          </div>
        </div>
    </div>

    <!-- assign workflow Modal -->
    <div class="modal fade" id="assign_template_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="assign_template_modal_label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content bg-white">
            <div class="modal-header bg-green text-white border-bottom-0">
              <h5 class="modal-title text-s fw-bold" id="assign_template_modal_label">Assign Template to Workflow</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table id="workflows_table" class="table-responsive w-100">
                    <thead class="bg-gray">
                    <tr>
                        <th class="text-center text-uppercase text-xs font-weight-bolder opacity-7">No.</th>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7">Workflow Name</th>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7">Assign</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(workflow, index) in workflows" :key="workflow.id">
                        <td class="text-center text-xs fw-bold">{{ index + 1 }}</td>
                        <td class="text-xs fw-bold text-start">{{ workflow.workflow_name }}</td>
                        <td class="text-xs fw-bold text-start">
                            <label class="switch">
                                <input type="checkbox"  @change="assign_workflow(workflow.id)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
    </div>
</div>