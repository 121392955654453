import { validate_invalid_characters, validate_all_fields} from "@/utils/validations";
import UserInfoFields from "@/components/UserInfoFields/UserInfoFields.vue";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
    components:{
        UserInfoFields,
        BreadCrumb,
        ClientLogo
    },
    name: 'SearchFcra',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    data: () => ({ 
        first_name: '',
        last_name: '',
        error: '',
        json_data: {},
        error_messages: {
            first_name: '',
            last_name: ''
          },
    }),
    computed: {
        workflow_id() {
          return this.$store.getters.get_workflow_id;
        }
    },
    methods: {
        validate_first_name() {
            this.first_name = validate_invalid_characters(this.first_name);
          },
          validate_last_name() {
            this.last_name =validate_invalid_characters(this.last_name);
          },
          validate_all_fields() {
            const fields = ['first_name', 'last_name'];
            const data = {
              first_name: this.first_name,
              last_name: this.last_name,
            };
      
            return validate_all_fields(fields, data, this.error_messages);
          },
        search_fcra_api() {
            if (!this.validate_all_fields()) {

                return;
            }
            const TOKEN = localStorage.getItem('token');
            const SEARCH_FCRA_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/search_fcra_result_api/`;

            this.error = '';
            $.ajax({
                url: SEARCH_FCRA_API,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                data: JSON.stringify({
                    firstname: this.first_name,
                    lastname: this.last_name,
                    workflow_id: this.workflow_id
                }),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => Swal.showLoading(),
                    });
                },
                success: () => {
                    Swal.close();
                    this.fetch_current_service();
                },
                error: (error) => {
                Swal.close();
                let error_message = 'Something went wrong!';

                if (error.responseJSON) {
                    if (error.responseJSON === "Invalid date of birth") {
                        error_message = error.responseJSON;
                        $('.invalid-dob').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.invalid-dob').text('').addClass('d-none');
                        }, 3000);
                    }
                    error_message = error.responseJSON;
                    $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                }else {
                    $('.error-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.error-message').text('').addClass('d-none');
                        }, 3000);
                    }
                }
            });
        }
    },

    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_customer_data();
      }
}
