<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
  <BreadCrumb label="CIPC Verification" />

  <div class="d-flex justify-content-center align-items-center min-vh-50">
    <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
      <div class="form-group mt-1">
        <label for="id_number" class="form-label fw-bold text-xs">ID Number <span
            class="text-danger fw-bold">*</span></label>
        <input 
          id="id_number"
          type="text"
          class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm justify-content-center"
          v-model="id_number"
          placeholder="Enter an SA ID Number"
          @input="handle_input"
          maxlength="13">
        <div class="d-flex flex-column col-12 col-md-9 col-lg-8 mx-auto mt-1">
          <small class="text-danger fw-bold text-xs d-none id-message">
            Please fill out this field
          </small>
          <small class="text-danger fw-bold text-xs d-none invalid-message">
            Please enter a valid ID number
          </small>
        </div>
        <label for="company_name" class="form-label fw-bold text-xs">Company Name <span
            class="text-danger fw-bold">*</span></label>
        <div class="col">
          <input id="company_name" type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="company_name" placeholder="Enter Company Name" />
        </div>
        <small class="form-text text-danger d-none company-message text-xs fw-bold">
          Please fill out this field
        </small>

        <label for="reg_number" class="form-label fw-bold text-xs mt-2">Company Registration Number <span
            class="text-danger fw-bold">*</span></label>
        <div class="col">
          <input id="company_registration" type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            v-model="company_registration" placeholder="Company Registration" @input="validate_reg_number" />
        </div>
        <small class="form-text text-danger d-none reg-message text-xs fw-bold">
          Please fill out this field
        </small>
        <small class="form-text text-danger d-none text-xs fw-bold error-message"></small>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button type="submit" class="btn bg-green btn-sm mt-2 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
      @click="validate_id_input">
      Continue
    </button>
  </div>
</div>