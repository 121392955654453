<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
  <BreadCrumb label="My Address Information" />

  <div>
    <IdNumberInput @update-id-number="handle_id_input" v-if="!address_info" />

    <div class="text-center">
      <button
        v-if="!address_info"
        type="button"
        class="btn bg-green btn-sm mt-1 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
        @click="submit_id"
      >
        Search
      </button>
    </div>

    <div
      class="d-flex justify-content-center align-items-center min-vh-50"
      v-if="address_info"
    >
      <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-6 p-3">
        <p class="text-xs text-center">
          Please select an address from your address history below or add a new
          address using the form provided.
        </p>
        <hr class="fw-light" />
        <div class="d-flex justify-content-center">
          <ul class="nav nav-tabs mb-2" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active text-dark text-xs"
                id="address-history-tab"
                data-bs-toggle="tab"
                data-bs-target="#address-history"
                type="button"
                role="tab"
                aria-controls="address-history"
                aria-selected="false"
              >
                Address History
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link text-dark text-xs"
                id="add-new-address-tab"
                data-bs-toggle="tab"
                data-bs-target="#add-new-address"
                type="button"
                role="tab"
                aria-controls="add-new-address"
                aria-selected="true"
              >
                Add New Address
              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade"
            id="add-new-address"
            role="tabpanel"
            aria-labelledby="add-new-address-tab"
          >
            <div>
              <form>
                <div class="mb-3">
                  <label class="text-xs fw-bold">Street Number</label>
                  <input
                    type="text"
                    class="form-control text-xs"
                    v-model="new_address.street_number"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="text-xs fw-bold">Address</label>
                  <input
                    type="text"
                    class="form-control text-xs"
                    v-model="new_address.address"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="text-xs fw-bold">City</label>
                  <input
                    type="text"
                    class="form-control text-xs"
                    v-model="new_address.city"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="text-xs fw-bold">Province</label>
                  <select
                    class="form-control text-xs"
                    v-model="new_address.province"
                    required
                  >
                    <option value="" hidden>Select Province</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                    <option value="Free State">Free State</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="North West">North West</option>
                    <option value="Western Cape">Western Cape</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="text-xs fw-bold">Postal Code</label>
                  <input
                    type="text"
                    class="form-control text-xs"
                    v-model="new_address.postal_code"
                    required
                  />
                </div>
              </form>
              <div class="text-center">
                <button type="button" class="btn btn-bg-green rounded-small text-xs" @click="add_address">
                  Add Address
                </button>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade show active"
            id="address-history"
            role="tabpanel"
            aria-labelledby="address-history-tab"
          >
            <div class="row" v-if="address_info.length > 0">
              <div
                class="col-sm-6 p-1"
                v-for="(address, index) in address_info"
              >
                <div class="card shadow rounded-3 h-100">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="">
                          <i class="fas fa-map-marker-alt"></i>
                          <span class="fw-bold text-xs px-2 pb-3">Address</span>
                        </label>
                        <p class="text-s">{{ address.Address }}</p>
                      </div>
                      <div class="col-sm-12 row">
                        <div class="col-sm-6">
                          <label class="">
                            <i class="fas fa-calendar-alt"></i>
                            <span class="fw-bold text-xs px-2 pb-3"
                              >Last Updated</span
                            >
                          </label>
                          <p class="text-xs">{{ address.LastUpdatedDate }}</p>
                        </div>
                        <div class="col-sm-6">
                          <label class="">
                            <i class="fas fa-calendar-alt"></i>
                            <span class="fw-bold text-xs px-2 pb-3"
                              >First Reported</span
                            >
                          </label>
                          <p class="text-xs">{{ address.FirstReportedDate }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-footer d-flex justify-content-center bg-white border-0 rounded-3"
                  >
                    <button
                      type="button"
                      class="btn btn-bg-green rounded-small text-xs"
                      @click="select_address(address)"
                    >
                      Select
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="card shadow rounded-3 border-light">
                <div class="card-body text-center">
                  <p
                    class="d-flex justify-content-center align-items-center flex-column"
                  >
                    <i
                      class="fas fa-exclamation-triangle text-warning text-md p-2"
                    ></i>
                    <span class="text-xs text-muted p-2">
                      No address history found.
                    </span>
                    <span class="text-xs text-muted">
                      Please add a new address using the form provided below.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
