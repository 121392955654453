
export default {
  name: 'UploadProofOfAddress',

  data() {
    return {
      is_form_data_ready: false,
      form_data: [],
      responses: [],
      duplicate_fields: [], // Stores dynamic sets of duplicated fields
      validation_errors: {}, // To store validation error messages dynamically
      service_type:"",
      service_id: "",
    };
  },
  mounted() {
    // Simulating API call to get form data
    setTimeout(() => {
      this.is_form_data_ready = true;
    }, 1000);
    this.get_service_id();
  },

  methods: {
    add_more_fields() {
      const new_fields = this.responses.map(field => ({
        ...field,
        value: "", // Reset value for the duplicate fields
      }));
      this.duplicate_fields.push(new_fields);
    },
    remove_fields(index) {
      this.duplicate_fields.splice(index, 1);
    },

    get_service_id(){
      const TOKEN = localStorage.getItem('token');
      const GET_SERVICE_ID_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_service_id/`;
  
      $.ajax({
        url: GET_SERVICE_ID_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Getting service id...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          if(response.status === "success") {

            this.service_id = response.service_id
            this.fetch_custom_service_question()

          }else{

            Swal.fire({
              text: 'An error occurred',
              icon: 'error',
            });
            Swal.close();
          }
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error',
          });
          Swal.close();
        },
      });

    },

    fetch_custom_service_question() {
      const TOKEN = localStorage.getItem('token');
      const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_questions_for_service_api/${this.service_id}`;
  
      $.ajax({
        url: GET_QUESTIONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Getting selected service...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          // Assign questions and service type from the response
          this.form_data = response.questions;
          this.service_type = response.service_type; // Add a new data property for service type
          // Map responses for questions
          this.responses = this.form_data.map((field) => ({
            ...field,
            value: '', // Initialize with empty value
            error_message: '',
          }));

          // Clear duplicate fields upon new fetch
          this.duplicate_fields = [];
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error',
          });
          Swal.close();
        },
      });
    },
    emit_responses() {
      this.$emit('update-responses', this.form_data);
    },

    on_file_change(event, index) {
      const file = event.target.files[0]; // Get the selected file
      const MAX_SIZE = 5 * 1024 * 1024; // 5 MB
  
      // Check for file size
      if (file && file.size > MAX_SIZE) {
        event.target.value = null; // Clear the file input
        Swal.fire({
          text: 'File is too large.',
          icon: 'warning',
          confirmButtonText: 'OK'
        });
        return;
      }
  
      // If the file is valid, store it in the responses array
      this.responses[index].value = file; // Store the file in the responses array
      this.emit_responses(); // Emit the changes if needed
    },
    submit_form() {

      const TOKEN = localStorage.getItem('token');
      const SAVE_RESPONSE_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_response_api/`;

      // Flatten duplicate fields and combine with original responses
      const all_responses = [
        ...this.responses,
        ...this.duplicate_fields.flat(),
      ];
    
      const FORM_DATA = {
        responses: all_responses.map(field => ({
          service_id: this.service_id,
          question_id: field.id,
          value: field.value,
        })),
      };

      // Check if any response includes a file
      const has_file = this.responses.some(field => field.has_file);

      let data_to_send;
      if (has_file) {
          // If there's a file, create a FormData instance
          const data = new FormData();

          // Append responses, handling files if present
          this.responses.forEach((field, index) => {
              data.append(`responses[${index}][service_id]`,  this.service_id);
              data.append(`responses[${index}][question_id]`, field.id);
              if (field.has_file) {
                  data.append(`responses[${index}][uploaded_file]`, field.value);
              } else {
                  data.append(`responses[${index}][value]`, field.value);
              }
          });

          data_to_send = data;
      } else {
          data_to_send = JSON.stringify(FORM_DATA);
      }
      // Determine content type based on the presence of files
      const content_type = has_file ? false : 'application/json';

      $.ajax({
        url: SAVE_RESPONSE_API,
        method: 'POST',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: data_to_send,
        processData: !has_file,  // Do not process data if using FormData
        contentType: content_type,
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          this.$router.push('/thank_you_page');
        },
        error: () => {
          Swal.close();
        }
      });
    }
  }
};
