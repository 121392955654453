<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
	<BreadCrumb label="AFIS Criminal Check" />

    <div class="d-flex justify-content-center align-items-center min-vh-50">
        <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
            <div class="form-group mt-3">
                <label for="first_name" class="fw-bold text-xs text-start d-block">First Name <span
                    class="text-danger">*</span></label>
            <input v-model="first_names" type="text" class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                id="first_name" placeholder="e.g. Joe" required />

            <label for="last_name" class="fw-bold text-xs text-start d-block mt-3">Last Name <span
                    class="text-danger">*</span></label>
            <input v-model="last_name" type="text" class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                id="last_name" placeholder="e.g. Doe" required />

            <label for="email_address" class="fw-bold text-xs text-start d-block mt-3">Email Address <span
                    class="text-danger">*</span></label>
            <input v-model="email_address" type="email" class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                id="email_address" placeholder="joedoe@example.co.za" required />

            <label for="phone_number" class="fw-bold text-xs text-start block mt-3">Phone Number <span
                    class="text-danger">*</span></label>
            <input v-model="phone_number" type="text" class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                id="phone_number" placeholder="e.g. 072 641 2558" required />

            <label for="id_number" class="fw-bold text-xs text-start d-block mt-3">ID Number <span
                    class="text-danger">*</span></label>
            <input v-model="id_number" type="text" class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                id="id_number" placeholder="e.g. 9812160854083" required />
            </div>
            <div class="text-center mb-3 mt-4">
                <button class="btn btn-sm bg-green secondary-text-dark rounded-small fw-bold text-xs shadow-sm"
                    @click.prevent="afis_crim_check">
                    Continue
                </button>
            </div>
        </div>
    </div>
</div>