<div class="container-fluid">
  <div class="card rounded-3 mt-4 shadow">
    <div class="card-header bg-white border-0 rounded-top">
     Applications
    </div>
  <div class="container mt-3">
    <ul class="nav nav-tabs mb-4 d-flex justify-content-between text-xs" id="myTab" role="tablist">
      <li class="nav-item flex-fill me-2" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark border-0 " :class="{ active: currentTab === 'Pending' }"
                @click="switchTab('Pending')">
          Pending
        </button>
      </li>
      <li class="nav-item flex-fill me-2" role="presentation">
        <button class="nav-link w-100 fw-bold border-0 secondary-text-dark" :class="{ active: currentTab === 'Approved' }"
                @click="switchTab('Approved')">
          Approved
        </button>
      </li>
      <li class="nav-item flex-fill me-2" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark border-0" :class="{ active: currentTab === 'Deactivated' }"
                @click="switchTab('Deactivated')">
          Deactivated
        </button>
      </li>
      <li class="nav-item flex-fill" role="presentation">
        <button class="nav-link w-100 fw-bold secondary-text-dark border-0" :class="{ active: currentTab === 'Declined' }"
                @click="switchTab('Declined')">
          Declined
        </button>
      </li>
    </ul>

    <div class="tab-content ms-2 me-2">
      <!-- Pending Tab -->
      <div v-if="currentTab === 'Pending'" class="tab-pane fade show active">
        <table  id="applications-table" class="table table-clear">
          <thead class="bg-dark text-white ">
            <tr>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7">NAME</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">DATE CREATED</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">APPLICATION STATUS</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(application, index) in filteredData" :key="application.id">
              <td class="text-xxs text-start">{{ index + 1 }}</td>
              <td class="text-xxs ">{{ application.full_name }}</td>
              <td class="text-xxs text-start">{{ application.date_created }}</td>
              <td class="text-primary text-xs fw-bold"><span class="badge rounded-pill bg-info text-xxs">{{ application.application_status }}</span></td>
              <td>
                <button @click="view_pending_application(application.user_id)" class="btn bg-green btn-sm text-xxs rounded-small shadow">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Approved Tab -->
      <div v-if="currentTab === 'Approved'" class="tab-pane fade show active">
        <table id="applications-table" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7">NAME</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">DATE CREATED</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">APPLICATION STATUS</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(application, index) in filteredData" :key="application.id">
              <td class="text-xxs text-start">{{ index + 1 }}</td>
              <td class="text-xxs ">{{ application.full_name }}</td>
              <td class="text-xxs text-start">{{ application.date_created }}</td>
              <td class="text-success text-xxs"><span class="badge bg-success text-xxs rounded-small shadow">{{ application.application_status }}</span></td>
              <td>
                <button @click="view_approved_application(application.user_id)" class="btn bg-green btn-sm text-xxs fw-bold rounded-small shadow">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Deactivated Tab -->
      <div v-if="currentTab === 'Deactivated'" class="tab-pane fade show active">
        <table id="applications-table" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs text-start">ID</th>
              <th class="text-uppercase text-xxs ">NAME</th>
              <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">DATE CREATED</th>
              <th class="text-uppercase text-xxs text-start">APPLICATION STATUS</th>
              <th class="text-uppercase text-xxs text-start">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(application, index) in filteredData" :key="application.id">
              <td class="text-xxs text-start">{{ index + 1 }}</td>
              <td class="text-xxs ">{{ application.full_name }}</td>
              <td class="text-xxs text-start">{{ application.date_created }}</td>
              <td class="text-warning text-xs fw-bold"><span class="badge rounded-small bg-warning text-xxs shadow">{{ application.application_status }}</span></td>
              <td>
                <button @click="view_deactivated_application(application.user_id)" class="btn bg-green btn-sm text-xxs fw-bold rounded-small shadow">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Declined Tab -->
      <div v-if="currentTab === 'Declined'" class="tab-pane fade show active">
        <table id="applications-table" class="table table-clear">
          <thead class="bg-dark text-white">
            <tr>
              <th class="text-uppercase text-xxs ">ID</th>
              <th class="text-uppercase text-xxs ">NAME</th>
              <th class="text-uppercase text-xxs text-start">DATE CREATED</th>
              <th class="text-uppercase text-xxs text-start">APPLICATION STATUS</th>
              <th class="text-uppercase text-xxs text-start">ACTION</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(application, index) in filteredData" :key="application.id">
              <td class="text-xxs text-start">{{ index + 1 }}</td>
              <td class="text-xxs ">{{ application.full_name }}</td>
              <td class="text-xxs text-start">{{ application.date_created }}</td>
              <td class="text-danger text-xxs "><span class="badge rounded-small bg-danger shadow">{{ application.application_status }}</span>
                </td>
              <td>
                <button @click="view_declined_application(application.user_id)" class="btn bg-green btn-sm text-xxs rounded-small">View</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div>
</div>