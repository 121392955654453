import { allow_num } from "@/utils/validations";
export default {
  name: "IdNumberInput",
  data() {
    return {
      id_number: "",
    };
  },
  mounted() {
    this.id_number = this.$props.id_number;
  },
  props: {
    id_number: {
      required: true,
      type: String,
    },
  },
  methods: {
    allow_num(event) {
      this.id_number = allow_num(event);
    },
  },
  watch: {
    id_number: function (new_val) {
      this.$emit("update-id-number", new_val);
    },
  },
};
