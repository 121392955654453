<div class="container-fluid">
  <div class="card view-card border-0 mt-5 rounded-3 shadow p-2">
  <ApplicationComponent />
  
  <!-- Buttons -->
  <div class="text-end mt-4">
    <button
      class="btn bg-green btn-sm text-xxs fw-bold rounded-small shadow me-2"
      @click.prevent="approve_application">
      Approve
    </button>
    <button class="btn btn-danger btn-sm text-xxs fw-bold text-white rounded-small shadow" @click.prevent="decline_application">Decline</button>
  </div>
</div>
</div>