<div class="container-fluid">
  <div class="card rounded-3 mt-4 p-0">
    <div class="card-header bg-white border-0">
      Assets
      <button type="button" class="btn btn-dark btn-sm text-xxs fw-bold rounded-small shadow float-end" 
              data-bs-toggle="modal" data-bs-target="#create_asset_modal">
        <i class="fas fa-plus"></i> New Asset
      </button>
    </div>

    <div class="container mt-3">
      <table id="assets_table" class="table table-clear">
        <thead class="bg-dark text-white">
          <tr>
            <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">ID</th>
            <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Asset Name</th>
            <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Number Of Workflows</th>
            <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Created</th>
            <th class="text-xxs">Action</th>
          </tr>
        </thead>
             <tbody>
          <tr v-for="(asset, index) in assets" :key="asset.id" class="text-xs">
            <td class="text-xxs text-start">{{ index + 1 }}</td>
            <td class="text-xxs text-start">{{ asset.asset_name }}</td>
            <td class="text-xxs text-start">{{0}}</td>
            <td class="text-xxs text-start">{{ asset.date_created.split('T')[0] }}</td>
            <td>
              <button class="btn btn-dark btn-sm text-xxs fw-bold rounded-small me-3" @click="view_asset(asset.id)">View</button>
              
              <button v-if="!asset.is_assigned" 
                      class="btn bg-green btn-sm text-white text-xxs fw-bold rounded-small"
                      data-bs-toggle="modal"
                      @click="open_assign_modal(asset.id)"
                      data-bs-target="#assign_asset_to_workflow_modal">
                Assign to a Workflow
              </button>

              <button v-else 
                      class="btn bg-green btn-sm text-white text-xxs fw-bold rounded-small" 
                      disabled>
                Assigned
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal fade" id="create_asset_modal" tabindex="-1" aria-labelledby="create_asset_modal_label" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="create_asset_modal_label"><strong>Create Asset</strong></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="create_asset">

            <div class="mb-3">
              <label class="form-label fw-bold"><small>Asset name

              </small></label>
              <input v-model="asset_name" type="text" class="form-control text-xs" placeholder="name" name="name" required>
              <div class="name-message d-none">Asset name is required</div>
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold"><small>Description</small></label>
              <input v-model="asset_description" type="text" class="form-control text-xs" placeholder="Description" name="description" required>
              <div class="description-message d-none">Description is required</div>
            </div>

            <div class="mb-3">
              <label class="form-label fw-bold"><small>Asset Type</small></label>
              <select v-model="asset_type" class="form-select text-xs" name="asset_type" required>
                <option value="" disabled>Select Asset Type</option>
                <option v-for="type in asset_types" :key="type.id" :value="type.id">{{ type.asset_type }}</option>
              </select>
              <div class="type-message d-none">Asset type is required</div>
            </div>

            <div class="card-img-top">
              <div>
              <img v-if="image_url" :src="image_url" alt="Preview" class="img-fluid rounded" />
              <div v-else class="placeholder">No image selected</div>

              </div>
            </div>


        
            <div class="d-flex justify-content-between mt-4">
              
              <div>
                <button
                  type="button"
                  class="btn bg-green text-xs fw-bold rounded-small shadow"
                  @click="trigger_file_upload"
                >
                <i class="fa fa-upload "></i> Upload
                </button>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  @change="handle_file_upload"
                  ref="fileInput"
                  class="d-none text-xs upload-input"
                >
              </div>
              <div>
                <button 
                  type="submit" 
                  class="btn bg-green text-xs fw-bold rounded-small shadow"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Assign assset to workflow Modal -->
<div class="modal fade" id="assign_asset_to_workflow_modal" tabindex="-1" aria-labelledby="assign_asset_to_workflow_modal_Label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="assign_asset_to_workflow_modal_Label"><strong>Assign to workflow</strong></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="assign_to_workflow(selected_asset_id, selected_workflow_id)">
          <div class="mb-3">
            <label class="form-label fw-bold"><small>Workflow</small></label>
              <select v-model="selected_workflow_id" class="form-select text-xs" required>
              <option value="" disabled hidden>Select workflow</option>
              <option v-for="workflow in active_workflows" :key="workflow.id" :value="workflow.id">
                {{ workflow.workflow_name }}
              </option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill">Assign</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>