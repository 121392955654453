// utils/registrationUtils.js

export function handle_registration_redirect(response, router, store, fetch_current_service,GET_CLIENT_ID) {
    const role = response.role.toUpperCase();
    const status = response.status;

    switch (role) {
        case 'CUSTOMER': {

            if (GET_CLIENT_ID == null){
                sessionStorage.clear(); 
                localStorage.clear(); 
                router.push('/');
            } else {
            const workflow_id = response.workflow_id;
            
            if (workflow_id !== null) {
                store.dispatch('update_workflow_id', workflow_id);
                if (fetch_current_service) {
                    fetch_current_service();
                }
            } else {
                router.push({ name: 'assigned_workflows' });
            }
        }
            break;
        }
        case 'CLIENTADMIN':
            handle_client_redirect(status, router);
            break;
        case 'SYSTEMADMIN':
            router.push('system_admin/admin_dashboard');
            break;
        case 'DIRECTOR': {
            switch (status) {
                case 'no_consent':
                    router.push('/director_consent');
                    break;
                case 'not_verified':
                    router.push('/facial_biometrics_verification');
                    break;
                case 'no_proof_of_address':
                    router.push('/upload_proof_of_address');
                    break;
                default:
                    router.push('/thank_you_page');
            }
            break;
        }
        case 'CLIENT':
            router.push('client/project_table');
            break;
        default:
            router.push('/company_pending');
    }
}

function handle_client_redirect(status, router) {
    switch (status) {
        case 'no_profile_info':
            router.push('/id_verification');
            break;
        case 'not_verified':
            router.push('/facial_biometrics');
            break;
        case 'no_company_profile':
            router.push('/company_verification');
            break;
        case 'failed_verification':
        case 'pending':
        case 'declined':
            router.push('/company_pending');
            break;
        case 'approved':
            router.push('client/project_table');
            break;
        default:
            router.push('client/project_table');
    }
}
