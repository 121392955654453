import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
  name: "ViewWorkflowStatus",
  components: {
    AdminNavBar,
    AdminSidebar
  },
  data() {
    return {
      current_tab: localStorage.getItem("workflow_tabs") || 'pending',
      data: {
        incomplete: [],
        pending: [],
        approved: [],
        declined: [],
      },
    };
  },
  computed: {
    filtered_data() {
      return this.data[this.current_tab];
    }
  },
  methods: {
    switch_tab(tab) {
      this.current_tab = tab;
      localStorage.setItem("workflow_tabs", tab);
      this.$nextTick(() => {
        this.initDataTable();
      });
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable("#workflows-table")) {
          $("#workflows-table").DataTable().destroy();
        }
        $("#workflows-table").DataTable({
          responsive: true,
        });
      });
    },
    view_workflow_information(workflow_id){
      this.$router.push({ name: 'view_single_workflow', params: { workflow_id } });
    },
    get_workflows() {
          const TOKEN = localStorage.getItem('token');
          const GET_WORKFLOWS_URL = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/get_workflows/`;
    
          $.ajax({
            url: GET_WORKFLOWS_URL,
            method: 'GET',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`,
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            },
            success: (response) => {
              Swal.close();

                this.data.incomplete = response.data.incomplete || [];
                this.data.pending = response.data.pending || [];
                this.data.approved = response.data.approved || [];
                this.data.declined = response.data.declined || [];
    
              this.$nextTick(() => {
                this.initDataTable();
              });
            },
            error: () => {
              Swal.close();
              Swal.fire({
                text: 'Failed to retrieve data from the server, try again later.',
                icon: 'error',
              });
            }
          });
        },
  },
  mounted() {
    if (!validateToken(this.$router)) {
      this.$router.push({ name: "user_login" });
      return;
    }
    this.get_workflows();
  },
};
