import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";

export default {
    name: 'DirectorInfo',
    mixins: [MAIN_CONTROLLER],
    components:{
      ClientLogo,
      BreadCrumb
    },
    data: () => ({ 
      director_info: [],
      phone_number: {},
      email: {}

    }),

    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      },
      masked_directors() {
        return this.director_info.map((director) => {
          return {
            ...director,
            masked_id_number: this.mask_id_no(director.id_number),
          };
        });
      },
    },

    methods: {
      mask_id_no(id_number) {
        const start = id_number.slice(0, 3);
        const end = id_number.slice(-3);
        const stars = "*".repeat(id_number.length - 6);
        return `${start}${stars}${end}`;
      },
      get_directors_info(){

        const TOKEN = localStorage.getItem('token');
        const GET_DIRECTOR_INFO_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_director_information/${this.workflow_id}`;

        $.ajax({
          method: "GET",
          url: GET_DIRECTOR_INFO_URL,
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          success: (response) => {
            
            this.director_info = response.data || [];
            
          },
          error: () => {
        
          }
        });
      },
      update_director_information() {
        const collected_data = this.director_info.map((director, index) => ({
          full_name: director.full_name,
          id_number: director.id_number, // Unmasked ID number
          workflow_id: this.workflow_id,
          email: this.email[index] || '',
          phone_number: this.phone_number[index] || '',
        }));

        const TOKEN = localStorage.getItem('token');
        const UPDATE_DIRECTOR_INFO_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/update_director_info/`;
     
        $.ajax({
          url: UPDATE_DIRECTOR_INFO_URL,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify(collected_data),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: () => {
            Swal.close();
            this.fetch_current_service();

          },
          error: () => {
            Swal.fire({
              icon: 'error',
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            })
          }
        });
      },
    },
    mounted() {
      if (!validateToken(this.$router)) {
        return;
      }
      this.get_directors_info();
    }
    };