import { LOGOUT } from "@/utils/logout.js";

export default {
    name: 'AdminSidebar',
    mixins: [LOGOUT],

    data: () => ({
        user_info: {},
    }),
    methods: {
        get_user_information() {
            const TOKEN = localStorage.getItem('token');
            const GET_USER_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_user_api/`;
        
            $.ajax({
                url: GET_USER_INFORMATION_API,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    this.user_info = response.data;
                },
                error: () => {
                    Swal.close();
                }
            });
        },

        route_to(){
           this.logout();
        }
    },

    

    mounted() {
        this.get_user_information();
    
    }
}