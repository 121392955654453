import { LOGOUT } from "@/utils/logout.js";


export default {
    mixins: [LOGOUT],
    name: 'CompanyPending',

    methods: {
        route_to() {
          this.logout();
        },
    }

};