<form method="post" @submit.prevent="login">
  <div class="text-center mb-3">
    <p class="text-dark fw-bold text-md">Welcome Back</p>
    <p class="text-dark text-xs">
      Enter your email and password to access your account
    </p>
  </div>
  <hr class="fw-light" />
  <div class="form-group mb-3">
    <label for="" class="fw-bold text-xs text-start d-block mb-2">Email</label>
    <input
      type="text"
      class="form-control rounded text-xs"
      placeholder="Enter Email"
      v-model="username"
      name="username"
    />
    <small class="text-danger p-2 text-xxs fw-bold d-none username-message"
      >Please fill out this field</small
    >
  </div>

  <div class="form-group mb-3">
    <label for="" class="fw-bold text-xs text-start d-block mb-2"
      >Password</label
    >
    <div class="input-group position-relative">
      <input
        class="form-control rounded text-xs pr-5"
        placeholder="Enter Password"
        v-model="password"
        name="password"
        :type="show_password ? 'text' : 'password'"
      />
      <span
        class="eye-icon"
        @click="toggle_password_view"
      >
        <i :class="show_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
      </span>
    </div>
    <small class="text-danger p-2 text-xxs fw-bold d-none password-message"
      >Please fill out this field</small
    >
  </div>

  <div>
    <GetQuestions
      :client_admin_id="client_admin_id"
      ref="get_questions"
      page_type="login"
      @update-responses="store_responses"
    />
  </div>

  <small class="text-danger p-2 text-xxs fw-bold d-none login-message"></small>
  <div class="mb-3">
    <button
      type="submit"
      class="btn btn-bg-green secondary-text-dark text-xs w-75 fw-bold mt-2"
      @click="route_to"
    >
      Login
    </button>
  </div>

  <div class="mt-1 text-center">
    <span class="text-dark text-xs">Not Registered?</span>
    <a class="text-primary text-xs"
      ><router-link to="/user_registration"> Create an account</router-link></a
    >
  </div>
  <div class="text-center">
    <span class="text-dark text-xs">Forgot password?</span>
    <a :href="reset_url" class="text-mute text-xs"> Reset password</a>
  </div>
</form>
