import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validate_registration_number } from "@/utils/validations.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";

export default {
  name: 'BankAccountVerification',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components:{
    BreadCrumb,
    ClientLogo
  },

  data() {
    return {
        banks: [],
        account_types: [],
        bank: '',
        account_type:'',
        reg1: '',
        reg2: '',
        reg3: '',
        email_address: '',
        company_name: '',
        account_number: '',
        company_registration: '',
        error_messages: {
          email_adress: '',
          company_registration: '',
          bank: '',
          account_type: '',
          account_number: '',
          company_name:''
      },

    };
  },
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  methods: {

    validate_reg_number(event) {
      const position = event.target.selectionStart;
      if (event.key === 'Backspace' && position === 0) return;

      const { formatted_registration, new_cursor_pos, reg1, reg2, reg3 } = validate_registration_number(event.target.value, position);
      
      this.company_registration = formatted_registration;
      this.reg1 = reg1;
      this.reg2 = reg2;
      this.reg3 = reg3;

      this.$nextTick(() => {
          event.target.selectionStart = event.target.selectionEnd = new_cursor_pos;
      });
  },

    get_banks(){
        const TOKEN = localStorage.getItem('token');
        const GET_BANKS_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_banks/`;
        
        $.ajax({
          url: GET_BANKS_URL,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            this.loading = true;
          },
          success: (response) => {
            this.banks = response;
            this.loading = false; 
          },
          error: () => {
            this.loading = false; 
            let error_message = 'Error Getting banks';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
    },

    get_account_types(){
        const TOKEN = localStorage.getItem('token');
        const GET_ACCOUNT_TYPES_URL = `${process.env.API_BASE_URL}/verify_api/customer_management_api/get_account_types/`;
        
        $.ajax({
          url: GET_ACCOUNT_TYPES_URL,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            this.loading = true;
          },
          success: (response) => {
            this.account_types = response;
            this.loading = false; 
          },
          error: () => {
            this.loading = false; 
            let error_message = 'Error account types';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
    },

    validate_fields() {
      this.error_messages = {};

      if (!this.email_address) {
        this.error_messages.email_address = "Email address is required.";
      }

      if (!this.company_name) {
          this.error_messages.company_name = "Company name is required.";
      }
      if (!this.company_registration) {
        this.error_messages.company_registration = "Company registration is required.";
      }
      if (!this.bank) {
          this.error_messages.bank = "Please select a bank.";
      }
      if (!this.account_type) {
        this.error_messages.account_type = "Please select account type.";
      }
      if (!this.account_number) {
        this.error_messages.account_number = "Company account number is required.";
      }
      return Object.keys(this.error_messages).length === 0;
    },

    bank_account_verification(){
        if (!this.validate_fields()) {
          return;
        }

        const TOKEN = localStorage.getItem('token');
        const BANK_ACCOUNT_VERIFY_URL = `${process.env.API_BASE_URL}/verify_api/compliance_verification/bank_account_verify/`;

        const FORM_DATA = {
            company_name: this.company_name,
            reg1: this.reg1,
            reg2: this.reg2,
            reg3: this.reg3,
            workflow_id:  this.workflow_id,
            email_address: this.email_address,
            bank_name: this.bank,
            bank_account: this.account_number,
            account_type: this.account_type,
        };

        $.ajax({
            url: BANK_ACCOUNT_VERIFY_URL,
            method: 'POST',
            contentType: 'application/json',
            headers: {
                Authorization: `Token ${TOKEN}` 
            },
            data: JSON.stringify(FORM_DATA),
            beforeSend: () => {
                Swal.fire({
                    text: 'Loading ...',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            },
            success: (response) => {
                Swal.close();

                if(response.status == "error"){
                  Swal.fire({
                    icon: 'error',
                    text: "Our service is currently unavailable. Please contact your service provider and try again later",
                  }).then(() => {
                    this.$router.push({ name: 'thank_you_page' });
                  });
                }else{
                  this.fetch_current_service();
                }

            },
            error: () => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  text: "Bank details not submitted, please try again",
                })
            }

        });
    
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_banks();
    this.get_account_types();
    this.get_customer_data();
  }
};
