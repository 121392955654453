<div class="client-data container" v-if="main_data.length">
    <!-- Main Data: Render headings and fields -->
    <div v-for="(item, index) in main_data" :key="index" class="row mb-2">
        
        <!-- If the item is a header, show it as an underlined h2 -->
        <template v-if="item.type === 'heading'">
            <lable class="text-md">{{ item.label }}</lable>
            <hr class="fw-light mb-2" />
        </template>

        <!-- Otherwise, show a bold label and its value -->
        <template v-else-if="item.type === 'input' || item.type === 'label'">
            <div v-if="item.label === 'message' && item.value === 'No Record Found'">
                <lable class="text-md">Report Information</lable>
                <hr class="fw-light mb-2" />
                <div class="text-center col-sm-12">
                    <i class="fas fa-info-circle  text-info p-3"></i>
                    <p class="text-xs">No previous address information could be found.</p>
                </div>
            </div>
            <div class="field col-12" v-else>
                <div class="row">
                    <div class="col-sm-6">
                        <span class="fw-bold text-xs">{{ item.label }}:</span>
                    </div>
                    <div class="col-sm-6">
                        <span class="text-xs">{{ item.value }}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>

    <!-- Consumer Address History List -->
    <div v-if="consumer_address_history_groups.length" class="address-history">
        <lable class="text-md">Consumer Address History</lable>
        <hr class="fw-light mb-2" />
        <div class="accordion" id="address-history-accordion">
            <div v-for="(group, group_index) in consumer_address_history_groups" :key="'group-' + group_index" class="accordion-item mb-2 border-0 border-bottom">
                <h2 class="accordion-header" :id="'heading-' + group_index">
                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-' + group_index" aria-expanded="true" :aria-controls="'collapse-' + group_index">
                        <h6 class="text-xs mb-0 text-dark">
                            Address Entry: 
                            <span v-for="(item, item_index) in group" :key="item_index" class="text-xs">
                                <span v-if="item.label === 'First Reported Date'">({{ item.value }})</span>
                            </span>
                        </h6>
                    </button>
                </h2>
                <div :id="'collapse-' + group_index" class="accordion-collapse collapse" :aria-labelledby="'heading-' + group_index" data-bs-parent="#address-history-accordion">
                    <div class="accordion-body">
                        <div v-for="(item, item_index) in group" :key="item_index" class="row mb-1">
                            <div class="col-sm-6">
                                <span class="fw-bold text-xs">{{ item.label }}:</span>
                            </div>
                            <div class="col-sm-6">
                                <span class="text-xs">{{ item.value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>