<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
  <BreadCrumb label="Facial Biometrics Verification" />

  <div class="d-flex justify-content-center align-items-center min-vh-50">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-1">
      <div class="text-center">
        <small class="text-danger mb-2 fw-bold text-xs">
          <i class="fa fa-info-circle me-1"></i>
          Please make sure you have your camera enabled!
        </small>
      </div>

      <div class="form-group justify-content-center">
        <label for="id_number" class="form-label fw-bold text-xxs">
          ID Number <span class="text-danger fw-bold">*</span>
        </label>
        <input
          id="id_number"
          type="text"
          class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm justify-content-center"
          v-model="id_number"
          placeholder="Enter an SA ID Number"
          @input="handle_input"
          maxlength="13"
        >
        <div class="d-flex flex-column col-12 col-md-9 col-lg-8 mx-auto mt-1">
          <small class="text-danger fw-bold text-xs d-none id-message">
            Please fill out this field
          </small>
          <small class="text-danger fw-bold text-xs d-none invalid-message">
            Please enter a valid ID number
          </small>
        </div>
      </div>

      <div class="text-center mt-3">
        <button 
          class="btn bg-green shadow-sm rounded-small text-xs fw-bold shadow mt-2 mt-md-0"
          @click="show_instructions"
          :disabled="!id_number || !validate_id(id_number)"
        >
          Start Facial Recognition
        </button>
      </div>

      <div v-if="showCamera" class="row mt-4 justify-content-center">
        <div class="camera-container position-relative">
          
          <div v-if="loading" class="loading-overlay">
            Loading camera and face detection...
          </div>
      
          <video ref="video" class="camera-feed" autoplay playsinline></video>
          <canvas ref="canvas" class="camera-canvas"></canvas>
      
          <!-- Face Overlay Circle -->
          <div class="face-overlay">
            <svg class="progress-circle" viewBox="0 0 100 100">
              <circle
                cx="50"
                cy="50"
                r="40"
                pathLength="100"
                :style="progressStyle"
              />
            </svg>
          </div>
      
          <!-- Status and Timer -->
          <div class="status-message" v-if="status_message" v-html="formattedStatusMessage">
          
          </div>
          <div class="timer" v-if="show_timer">
            {{ timer_count }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end position-fixed bottom-0 end-0 p-5 mb-5">
    <!-- Full button for larger devices -->
    <button 
        type="button" 
        class="btn bg-green text-xxs rounded-small d-none d-sm-flex align-items-center justify-content-center fw-bold" 
        data-bs-toggle="modal" 
        data-bs-target="#show_correct_image_instruction"
    >
        <i class="fa-solid fa-info-circle me-1"></i>
        <span>View Instructions</span>
    </button>

    <!-- Icon-only button for smaller devices -->
    <button 
        type="button" 
        class="btn bg-green text-xs rounded-small d-flex d-sm-none align-items-center justify-content-center" 
        data-bs-toggle="modal" 
        data-bs-target="#show_correct_image_instruction"
    >
        <i class="fa-solid fa-info-circle"></i>
    </button>
</div>

  <!-- Instructions Modal -->
  <div class="modal fade" id="show_instruction" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="text-center mt-3 mb-4">
          <img :src="$clientLogo" width="150px" />
        </div>
        <p class="text-center text-xs">We need to verify your identity to secure your basic information.</p>
        <p class="text-center text-xs fw-bold mb-1">Please read the below instructions carefully:</p>
        <div class="text-center">
          <hr class="bg-secondary reduced-opacity">
        </div>
        <div class="modal-body px-4">
          <div class="d-flex align-items-center text-xs mb-3">
            <i class="fa fa-camera me-3 text-success"></i>
            <span>Ensure your camera is enabled and working properly</span>
          </div>
          <div class="d-flex align-items-center text-xs mb-3">
            <i class="fa fa-lightbulb me-3 text-success"></i>
            <span>Make sure you are in a well-lit area</span>
          </div>
          <div class="d-flex align-items-center text-xs mb-3">
            <i class="fa fa-glasses me-3 text-success"></i>
            <span>Remove any glasses, hats, or face coverings</span>
          </div>
          <div class="d-flex align-items-center text-xs mb-3">
            <i class="fa fa-user-circle me-3 text-success"></i>
            <span>Ensure you're the only person in the frame</span>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn bg-green fw-bold rounded-small text-xxs px-4" data-bs-dismiss="modal" @click="startFaceRecognition">
            Take Photo
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
  

<div class="modal fade" id="show_correct_image_instruction" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
          <p class="text-center text-xs fw-bold mb-1">Please read the below instructions carefully:</p>
          <div class="text-center">
              <hr class="bg-secondary reduced-opacity">
          </div>
          <div class="modal-body px-4">
              <!-- Instruction Messages -->
              <div class="d-flex align-items-center text-xs mb-3">
                  <i class="fa fa-glasses me-3 text-success"></i>
                  <span>Remove any hats, sunglasses, or any form of eyewear.</span>
              </div>
              <div class="d-flex align-items-center text-xs mb-3">
                  <i class="fa fa-user-circle me-3 text-success"></i>
                  <span>Move your face closer to the camera, ensuring your neck is excluded from the circle.</span>
              </div>

              <!-- Animation or GIF Section -->
              <div class="text-center mt-4">
                  <p class="text-success text-xs fw-bold mb-2">
                      <i class="fa fa-info-circle me-2"></i>Follow the animation below for guidance:
                  </p>
                  <img src="@/assets/images/facial_guide_one.gif" alt="Face Guidance Animation" class="rounded" width="250">
              </div>
          </div>

          <!-- Modal Footer -->
          <div class="modal-footer justify-content-center">
              <button type="button" class="btn bg-green fw-bold rounded-small text-xxs" data-bs-dismiss="modal" @click="startFaceRecognition">
                  Let's Go
              </button>
          </div>
      </div>
  </div>
</div>
