import { validate_id, allow_num  } from "@/utils/validations";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
  name: 'IncomeEstimator',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],

  components: {
    BreadCrumb,
    ClientLogo
  },

  data:() =>({ id_number: '', loading: false,  error: ''}),
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  methods: {
    allow_num(event) {
        this.id_number = allow_num(event);
    },
    handle_id_input(id_number) {
      this.id_number = id_number; // Update the id_number data property with the new value
    },
    submit_id() {
      if (!this.id_number.trim()) {
        $('.id-message').removeClass('d-none');
        setTimeout(() => {
          $('.id-message').addClass('d-none');
        }, 3000);
        return false;
      }

      if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
          $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
      }
      this.income_estimator();
    },

    income_estimator() {
      const TOKEN = localStorage.getItem('token');
      const INCOME_PREDICTOR_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/experian_income_predictor/`;
    
      this.loading = true;
      this.error = '';
      const DATA = {
        id_number: this.id_number,
        workflow_id: this.workflow_id,
      };

      $.ajax({
        url: INCOME_PREDICTOR_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
          Swal.close();
          this.fetch_current_service();
          var status_message = response.status;

          if (status_message == "success") {
            this.fetch_current_service();
          } else if (status_message == "Results not found please contact your service provider") {
            Swal.fire({
              icon: 'error',
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            })
          }
        },
        error: (error) => {
          this.loading = false;
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
            $('.error-message').text(error_message).removeClass('d-none');
                setTimeout(() => {
                    $('.error-message').text('').addClass('d-none');
                }, 3000);
          }else {
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
            }
        }
      });
    }
  },

  mounted(){
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_customer_data();
  }
};