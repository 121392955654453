import { validate_invalid_characters, validate_gender,validate_date_of_birth } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
  name: 'AMLVerification',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo
  },
  data: () => ({ 
    first_name: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
    country_code: '',
    error: '',
    json_data: {},
    error_messages: {
      first_name: '',
      last_name: '',
      date_of_birth: '',
      gender: '',
      country_code: '',
    },
    isFieldDisabled: false
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  methods: {
    date_of_birth_validation() {
      validate_date_of_birth(this.date_of_birth, '.invalid-dob');
    },
    countries_code() {
      this.country_code= validate_invalid_characters(this.country_code);
    },
    validate_first_name() {
      this.first_name = validate_invalid_characters(this.first_name);
    },

    validate_last_name() {
      this.last_name = validate_invalid_characters(this.last_name);
    },

    validate_gender_input() {
      this.gender = validate_gender(this.gender);
    },

    validate_all_fields() {
      let valid = true;
      let message = 'This field is required';

      const fields = ['first_name', 'last_name', 'date_of_birth', 'gender', 'country_code'];

      fields.forEach((field) => {
        if (!this[field]) {
          this.error_messages[field] = message;
          valid = false;
        } else {
          this.error_messages[field] = '';
        }
      });

      return valid;
    },

    async get_customer_data() {
      const TOKEN = localStorage.getItem('token');
      const CUSTOMER_DATA_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_customer_data/`;
    
      try {
        const response = await $.ajax({
          url: CUSTOMER_DATA_URL,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => Swal.showLoading(),
            });
          }
        });
    
        Swal.close();
        
        // Populate fields and set disabled state based on whether data exists
        this.first_name = response.first_name || '';
        this.last_name = response.last_name || '';
        this.date_of_birth = response.date_of_birth || '';
        this.gender = response.gender || '';
        this.country_code = response.birth_place_country_code || '';
        
        // Only disable fields that have prepopulated data
        this.isFieldDisabled = {
          first_name: !!response.first_name,
          last_name: !!response.last_name,
          date_of_birth: !!response.date_of_birth,
          gender: !!response.gender,
          country_code: !!response.birth_place_country_code
        };
        
      } catch (error) {
        Swal.close();
        console.error('Error fetching customer data:', error);
      }
    },

    anti_money_laundering_application_api() {
      if (!this.validate_all_fields()) {
            return;
      }
      const TOKEN = localStorage.getItem('token');
      const ANTI_MONEY_LAUNDERING_APPLICATION_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/anti_money_laundering_application/`;

      this.error = '';

      $.ajax({
        url: ANTI_MONEY_LAUNDERING_APPLICATION_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          first_name: this.first_name,
          last_name: this.last_name,
          date_of_birth: this.date_of_birth,
          workflow_id: this.workflow_id
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: (response) => {
          let application_id = response.application_id;
          this.anti_money_laundering_api(application_id);
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON) {
            error_message = error.responseJSON;
            $('.error-message').text(error_message).removeClass('d-none');
            setTimeout(() => {
              $('.error-message').text('').addClass('d-none');
            }, 3000);
          } else {
            $('.error-message').text(error_message).removeClass('d-none');
            setTimeout(() => {
              $('.error-message').text('').addClass('d-none');
            }, 3000);
          }
        }
      });
    },
    anti_money_laundering_api(application_id) {
      const TOKEN = localStorage.getItem('token');
      const ANTI_MONEY_LAUNDERING_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/anti_money_laundering/`;

      this.error = '';

      $.ajax({
        url: ANTI_MONEY_LAUNDERING_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          application_id: application_id,
          first_name: this.first_name,
          last_name: this.last_name,
          date_of_birth: this.date_of_birth,
          gender: this.gender,
          country_code: this.country_code,
          workflow_id: this.workflow_id
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: () => {
          Swal.close();
          this.fetch_current_service();
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Something went wrong!';
          if (error.responseJSON) {
            if (error.responseJSON === "Invalid date of birth") {
              error_message = error.responseJSON;
              $('.invalid-dob').text(error_message).removeClass('d-none');
              setTimeout(() => {
                $('.invalid-dob').text('').addClass('d-none');
              }, 3000);
            }
            error_message = error.responseJSON;
            $('.error-message').text(error_message).removeClass('d-none');
            setTimeout(() => {
              $('.error-message').text('').addClass('d-none');
            }, 3000);
          }else {
            $('.error-message').text(error_message).removeClass('d-none');
            setTimeout(() => {
              $('.error-message').text('').addClass('d-none');
            }, 3000);
          }
        }
      });
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_customer_data();
  }
};