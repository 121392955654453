import { LOGOUT } from "@/utils/logout.js";

export default {
  mixins: [LOGOUT],
  data: () => ({
    user_info: {}
  }),
  computed: {
    page_title() {
      switch (this.$route.name) {
        case 'project_table':
          return 'Projects';
        case 'project_dashboard':
          return 'Project Dashboard';
        case 'service_dashboard':
          return 'Services';
        case 'client_services':
          return 'Select Services';
        case 'workflow_services':
          return 'Your Services';
        case 'service_rules':
          return 'Service Rules'
        case 'create_workflows':
          return 'Workflows';
        case 'question_management':
          return 'Question Management';
        case 'form_management':
          return 'Form Management';
        case 'manage_form':
          return 'Manage Form';
        case 'template_management':
          return 'Template Management';
        case 'view_service':
          return 'View Service';
        case 'reasons_for_usage':
          return 'Reasons For Usage';
        case 'arrange_workflow':
          return 'Workflows';
        case 'upload_consent':
          return 'Terms and Conditions';
        case 'confirm_workflow':
          return 'Workflow Details';
        case 'view_workflow':
          return 'View Workflow';
        case 'edit_workflow':
          return 'Edit Workflow';
        case 'instant_verify':
          return 'Instant Verify';
        case 'instant_services':
          return 'Instant Services';
        case 'services':
          return 'Your Services';
        case 'assigned_workflows':
          return 'Dashboard'
        case 'customer_profile':
          return 'Profile'
        case 'verify_logs':
          return 'Service Logs';
        case 'client_admin':
          return 'Users';
        case 'create_asset':
          return 'Asset';
        case 'view_asset':
          return 'Asset Information';
        case 'customer_data':
          return 'View Customer in Your Workflow'
        case 'conditional_questions':
          return 'Add Conditional Questions'
        case 'template_configuration':
          return 'Template Configuration';
        case 'view_client_admin':
          return 'Client Admin Details'
        case 'view_client':
          return 'Client Details'
        default:
          return 'Page';
      }
    }
  },
  methods: {
    get_user_information() {
      const TOKEN = localStorage.getItem('token');
      const GET_USER_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/system_management_api/get_user_api/`;

      $.ajax({
        url: GET_USER_INFORMATION_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          Swal.close();
          this.user_info = response.data;
        },
        error: () => {
          Swal.close();
        }
      });
    },
    get_initial(name) {
      if (typeof name == 'string' && name.length > 0) {
        return name.charAt(0).toUpperCase();
      } else {
        return '';
      }
    },
    route_to() {
      this.logout();
    },
    go_back() {
      this.$router.push({ name: 'project_table' });
    },
    go_back_to_workflows() {
      this.$router.push({ name: 'project_dashboard' });
    },
    go_back_to_assigned_workflows() {
      this.$router.push({ name: 'assigned_workflows' })
    },
    go_back_to_services() {
      this.$router.push({ name: 'service_dashboard' });
    },
    hide_back_icon() {
      const current_route = this.$route.name;
      const hide_on_routes = ['project_table', 'assigned_workflows', 'customer_profile'];
      return !hide_on_routes.includes(current_route);
    },
    hide_burger_icon() {
      const current_route = this.$route.name;
      const hide_on_routes = ['assigned_workflows', 'customer_profile'];
      return !hide_on_routes.includes(current_route);
    },
    show_logout_icon() {
      const show_on_routes = ['customer_profile', 'assigned_workflows'];
      return show_on_routes.includes(this.$route.name);
    },
    show_back_to_workflows() {
      const current_route = this.$route.name;
      const show_on_routes = ['edit_workflow', 'view_workflow', 'workflow_services', 'service_rules', 'arrange_workflow', 'upload_consent', 'reasons_for_usage', 'confirm_workflow'];
      return show_on_routes.includes(current_route);
    },
    show_back_to_services() {
      const current_route = this.$route.name;
      const show_on_routes = ['view_service']; // Add routes where you want to show "Back to Services"
      return show_on_routes.includes(current_route);
    },
    toggle_sidebar() {
      this.$emit('toggle-sidebar');
    },
    redirect_to_customer_profile() {
      if (this.$route.name === 'assigned_workflows') {
        this.$router.push({ name: 'customer_profile' });
      }
    },
    admin_back_to_workflows() {
      const current_route = this.$route.name;
      const show_on_routes = ['view_single_workflow'];
      return show_on_routes.includes(current_route);
    },
    admin_go_back_to_workflows() {
      this.$router.push({ name: 'view_workflow_status' });
    },
  },
  mounted() {
    this.get_user_information();
  }
}
