import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import ApplicationComponent from '../application_component/ApplicationComponent.vue';
import { validateToken } from '@/utils/auth';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default {
  name: 'ViewApprovedApplication',
  components: {
    AdminNavBar,
    ApplicationComponent
  },
  data: () => ({ 
    user_id: null
   }),
  methods: {
    update_application_status(action) {
      const TOKEN = localStorage.getItem('token');
      const ACTION_APPLICATION_URL = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/action_application_api/${this.user_id}`;

      $.ajax({
        url: ACTION_APPLICATION_URL,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          action: action
        }),
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            confirmButtonText: 'OK'
          }).then(() => {
            this.$router.push({ name: 'client_applications' });
          });
        },
        error: (response) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.responseJSON.error || 'An error occurred while processing the application.',
            confirmButtonText: 'OK'
          });
        }
      });
    },
    deactivate_application() { {
        this.update_application_status('deactivate');
      }
    },
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
  }
    var user_id = this.user_id || this.$route.params.user_id;
    this.user_id = user_id;
  }
};