import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validate_email, validate_phone_number } from '@/utils/validations.js';
import { show_error } from '@/utils/validations.js';
import { validateToken } from '@/utils/auth';
import Swal from 'sweetalert2';
import $ from 'jquery';
import AddressComponent from './address_component/AddressComponent.vue';


export default {
  name: 'ClientAdminUsers',
  components: {
    ClientSidebar,
    NavBar,
    AddressComponent
  },
  data: () => ({
    currentTab: 'Profile',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    date_joined: '',
    id_number: '',
    audit_trails: [],
    documents: [],
    current_sub_tab: 'CreditBureau', 
    active_credit_bureau_service: null, 
    active_system_generated_service: null, 
    credit_bureau_services: {},
    system_generated_services: {},
    selected_document: null,
    selected_document_url: null,
    customer_image: ''
  }),
  computed: {
    nm_results() {
      const get_aml = this.credit_bureau_services.find(
        service => (service.service_name === "Anti Money Laundering" || service.service_name === "Search FCRA") &&
          service.response &&
          service.response.Fields?.Applicants_IO?.Applicant?.[0]?.Attributes?.DSWatchlistVerification
      );
      return get_aml?.response?.Fields?.Applicants_IO?.Applicant?.[0]?.Attributes?.DSWatchlistVerification?.NmResults;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    selectedServiceResult() {
      if (this.current_sub_tab === 'CreditBureau') {
        return this.credit_bureau_services.find(service => service.name === this.active_credit_bureau_service)?.result;
      } else if (this.current_sub_tab === 'SystemGenerated') {
        return this.system_generated_services.find(service => service.name === this.active_system_generated_service)?.questions;
      }
      return null;
    }
  },
  methods: {
    switchTab(tab) {
      this.currentTab = tab;
      localStorage.setItem('Profile', tab);
    },

    switch_sub_tab(tab) {
      this.current_sub_tab = tab;
      this.active_credit_bureau_service = null;
      this.active_system_generated_service = null;
    },

    toggle_service(serviceName) {
      if (this.current_sub_tab === 'CreditBureau') {
        this.active_credit_bureau_service = this.active_credit_bureau_service === serviceName ? null : serviceName;
      } else if (this.current_sub_tab === 'SystemGenerated') {
        this.active_system_generated_service = this.active_system_generated_service === serviceName ? null : serviceName;
      }
    },

    validate_inputs() {
      if (!validate_email(this.email)) return false;
      if (!validate_phone_number(this.phone_number)) return false;
      return true;
    },

    getWorkflowId() {
      try {
        this.workflow_id = this.storeWorkflowId || 0;
      } catch (error) {
        console.error('Error retrieving workflow ID:', error);
        this.workflow_id = 0;
      }
    },

    get_customer_data() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        show_error('.error-message', 'Server configuration error. Please try again later.');
        return;
      }

      const customer_id = this.$route.params.id;
      const GET_CUSTOMER_DATA_URL = `${BASE_URL}/verify_api/workflow_management_api/view_customer_data/${customer_id}/${this.workflow_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_CUSTOMER_DATA_URL,
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          let data = response.data;
          let documents = response.documents
          let audit_trails = response.audit_trails
          this.first_name = data.first_name;
          this.last_name = data.last_name; // Corrected typo
          this.email = data.email;
          this.phone_number = data.phone_number;
          this.date_joined = data.date_joined;
          if (data.profile && data.profile.id_number) {
            this.id_number = data.profile.id_number;
          } else {
            this.id_number = null;
          }
          this.audit_trails = audit_trails;
          this.documents = documents;
          this.isIframeVisible = new Array(this.documents.length).fill(false);
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON.error,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    toggle_iframe(document) {
      this.selected_document = this.selected_document && this.selected_document.id === document.id
        ? null
        : document;
    },

    show_document(url) {
      this.selected_document_url = this.selected_document_url === url ? null : url;
    },

    get_customer_service_responses() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      
      if (!BASE_URL) {
        show_error('.error-message', 'Server configuration error. Please try again later.');
        return;
      }

      const customer_id = this.$route.params.id;
      const GET_SERVICE_RESPONSES = `${BASE_URL}/verify_api/workflow_management_api/get_customer_service_responses/${customer_id}/${this.workflow_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_SERVICE_RESPONSES,
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close()
          this.system_generated_services = response.system_generated;
          this.credit_bureau_services = response.credit_bureau;
          this.customer_image = response.customer_image;
        }
      })
    },
    format_questions(questions) {
      if (typeof questions === "string") {
        try {
          // Attempt to parse as JSON
          questions = JSON.parse(questions.replace(/'/g, '"'));
        } catch (error) {
          console.error("Failed to parse response for question:", questions, error);
        }
      }
      return questions;
    },
    format_label(key) {
      if (typeof key !== 'string' || !key.trim()) return '';

      return key
        .replace(/[_-]/g, ' ')
        .replace(/[^a-zA-Z0-9]/g, ' ') // Replace non-alphanumeric characters with a space
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters following a lowercase letter
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase letters followed by a lowercase
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .trim() // Trim leading/trailing spaces
        .split(' ') // Split the string into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Join the words back into a single string
    },
    preprocess_data(data, parent_key = '') {
      let result = [];

      // Helper function to format keys for uniqueness and clarity
      let format_key = (key, parent) => (parent ? `${parent}.${key}` : key);

      // Process each key/value pair
      Object.entries(data).forEach(([key, value]) => {
        let current_key = format_key(key, parent_key);

        if (Array.isArray(value)) {
          // For arrays
          result.push({
            type: 'label',
            key: current_key,
            label: key,
            value: null, // No direct value, just a label for the array
          });

          value.forEach((item, index) => {
            if (typeof item === 'object' && item !== null) {
              // Recursively process nested objects or arrays within arrays
              result.push(...this.preprocess_data(item, `${current_key}[${index}]`));
            } else {
              result.push({
                type: 'input',
                key: `${current_key}[${index}]`,
                label: `${key} [${index}]`,
                value: item,
              });
            }
          });
        } else if (typeof value === 'object' && value !== null) {
          result.push({
            type: 'heading',
            key: current_key,
            label: key,
          });
          // Recursively process nested objects
          result.push(...this.preprocess_data(value, current_key));
        } else {
          result.push({
            type: 'input',
            key: current_key,
            label: key,
            value: value,
          });
        }
      });
      return result;
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    const saved_tab = localStorage.getItem('Profile');
    this.currentTab = saved_tab ? saved_tab : 'Profile';
    this.get_customer_data();
    this.get_customer_service_responses();
  }
};
