<div class="client-sidebar shadow">
  <div class="sidebar-brand text-center mt-3">
    <img :src="$clientLogo" alt="Logo" class="sidebar-logo img-fluid" />
  </div>

  <hr class="fw-bold mb-4 mt-1 mx-4 hr" />

  <!-- System Admin Section -->
  <ul v-if="user_info.user_role == 'SystemAdmin'" class="nav flex-column mb-auto">
    <span class="text-xxs fw-bold p-0">Dashboard</span>
    <li class="nav-item mt-0 mb-0 p-2">
      <router-link to="/system_admin/admin_dashboard" class="nav-link" active-class="active">
        <img :src="sidebarIcons.dashboard" class="icon-small me-2" />
        Dashboard
      </router-link>
    </li>
    <span class="text-xxs fw-bold p-0 mt-1">Management</span>
    <li class="nav-item mt-0 mb-0 p-2">
      <router-link 
        to="/system_admin/client_applications" 
        class="nav-link" 
        active-class="active"
        >
        <img :src="sidebarIcons.applications" class="icon-small me-2" />
        Applications
      </router-link>
    </li>
    <li class="nav-item mt-0 mb-0 p-2">
      <router-link 
        to="/system_admin/view_workflow_status"
        class="nav-link " 
        active-class="active"
      >
          <i class="fa fa-code-fork  me-2 green-color"></i>
          Workflows
      </router-link>
  </li>
    <li class="nav-item mt-0 mb-0 p-2">
      <router-link
        to="/system_admin/users"
        class="nav-link"
        active-class="active"
      >
        <img :src="sidebarIcons.users" class="icon-small me-2" />
        Users
      </router-link>
    </li>
    <li class="nav-item mt-0 mb-0 p-2">
      <router-link to="/system_admin/service_applications" class="nav-link" active-class="active">
        <img :src="sidebarIcons.verified" class="icon-small me-2" />
        Instant Verify Requests
      </router-link>
    </li>
  </ul>

  <!-- Client Admin/Client Dashboard Section -->
  <ul
    class="nav flex-column me-1 text-start"
    v-if="(user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client') && show_project_dashboard_tabs && this.$store.getters.get_project_id"
  >
    <li class="nav-item">
      <router-link :to="{ name: 'project_dashboard' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.dashboard" class="icon-small me-2" />
        Dashboard
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'service_dashboard' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.services " class="icon-small me-2" />
        Services
      </router-link>
    </li>
  </ul>

  <!-- Client Admin/Client Management Section -->
  <ul
    class="nav flex-column me-1 text-start"
    v-if="(user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client') && show_project_tabs"
  >
    <span class="text-xxs fw-bold p-0">Management</span>
    <li class="nav-item">
      <router-link :to="{ name: 'question_management' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.list" class="icon-small me-2" />
        Questions
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'form_management' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.report" class="icon-small me-2" />
        Forms
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'template_management' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.layout" class="icon-small me-2" />
        Templates
      </router-link>
    </li>
    <li v-if="user_info.user_role == 'ClientAdmin'" class="nav-item mt-0 mb-0 p-2">
      <router-link :to="{ name: 'client_admin' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.users" class="icon-small me-2" />
        Users
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'create_asset' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.gold" class="icon-small me-2" />
        Assets
      </router-link>
    </li>

    <!-- Services Section for Project Table -->
    <template v-if="$route.name === 'project_table' && user_info.user_role == 'ClientAdmin'">
      <span class="fw-bold text-xxs p-0">Services</span>
      <li class="nav-item">
        <router-link :to="{ name: 'instant_verify' }" class="nav-link fw-bold" active-class="active">
          <img :src="sidebarIcons.verified" class="icon-small me-2" />
          Instant Verify
        </router-link>
      </li>
    </template>
  </ul>

  <!-- Instant Verify Section -->
  <ul
    class="nav flex-column me-1 text-start"
    v-if="(user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client') && show_instant_verify_tabs"
  >
    <li class="nav-item">
      <router-link :to="{ name: 'instant_verify' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.dashboard" class="icon-small me-2" />
        Dashboard
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'instant_services' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.services" class="icon-small me-2" />
        Services
      </router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'services' }" class="nav-link fw-bold" active-class="active">
        <img :src="sidebarIcons.verified" class="icon-small me-2" />
        Your Services
      </router-link>
    </li>
  </ul>

  <!-- Logout Section -->
  <ul>
    <div class="logout">
      <li class="nav-item">
        <a class="nav-link fw-bold" @click="route_to" href="#" role="button">
          <img :src="sidebarIcons.logout" class="icon-small me-2" />
          Logout
        </a>
      </li>
    </div>
  </ul>
</div>