<div class="container-fluid">
  <div class="row">
    <div class="col-md-6">
      <div class="card rounded-3 mt-4 shadow">
        <div class="card-header bg-dark text-white">Workflow Information</div>
        <div class="card-body d-flex flex-column">
          <div class="d-flex flex-column my-2">
            <label class="text-xs fw-bold mb-1">Workflow name</label>
            <p class="text-xs">
              {{ workflow_data ? workflow_data.workflow_name : "Unknown" }}
            </p>
          </div>
          <div class="d-flex flex-column my-2">
            <label class="text-xs fw-bold mb-1">Workflow Description</label>
            <p class="text-xs">
              {{ workflow_data ? workflow_data.workflow_description :
              "Unknown"}}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 row my-4">
      <div class="col-sm-6">
        <div class="card card-bg shadow rounded-3 border-0 h-100">
          <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
              <div>
                <h6>Services Selected</h6>
                <p class="mb-0 sub_heading fw-bold text-md">
                  {{ workflow_data ? workflow_data.services.length : 0 }}
                </p>
              </div>
              <div class="align-self-center rounded-circle p-2 shadow bg-green">
                <i
                  class="fas fa-network-wired fa-lg me-auto secondary-text-dark"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card card-bg shadow rounded-3 border-0 h-100">
          <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
              <div>
                <h6>Workflow Services Cost</h6>
                <p class="mb-0 sub_heading text-md fw-bold">
                  R {{ workflow_data ? workflow_data.total_cost : 0.00 }}
                </p>
              </div>
              <div class="align-self-center rounded-circle p-2 shadow bg-green">
                <i
                  class="fas fa-money-bill-alt fa-lg me-auto secondary-text-dark"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mt-4">
        <div class="card card-bg shadow rounded-3 border-0 h-100">
          <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
              <div>
                <h6>Customers Onboarded</h6>
                <p class="mb-0 sub_heading text-md fw-bold">
                  {{ workflow_data ? workflow_data.customers.length : 0 }}
                </p>
              </div>
              <div class="align-self-center rounded-circle p-2 shadow bg-green">
                <i class="fas fa-users fa-lg me-auto secondary-text-dark"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mt-4">
        <div class="card card-bg shadow rounded-3 border-0 h-100">
          <div class="card-body">
            <div class="d-flex justify-content-between px-md-1">
              <div>
                <h6>Current Activate Status</h6>
                <p class="mb-0 sub_heading text-md-xs fw-bold">
                  {{ workflow_data ?
                  capitilize_string(workflow_data.workflow_status) : 'Unknown'
                  }}
                </p>
              </div>
              <div class="align-self-center rounded-circle p-2 shadow bg-green">
                <i class="fa fa-cogs fa-lg me-auto secondary-text-dark"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid" v-if="workflow_data">
    <div class="card card-outline-primary mt-2 rounded-3 shadow">
      <div class="card-body p-0 px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="card-progress-tracker fw-bold text-center mb-0">
            <li
              :class="workflow_data.workflow_completed.workflow_exists ? 'completed': 'in-completed'"
            >
              Workflow Created
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card card-outline-primary mt-2 rounded-3 shadow">
      <div class="card-body p-0 px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="card-progress-tracker fw-bold text-center mb-0">
            <li
              :class="workflow_data.workflow_completed.workflow_services_exist ? 'completed': 'in-completed'"
            >
              Services Selected
            </li>
          </ul>
          <button
            v-if="workflow_data.workflow_completed.workflow_services_exist"
            @click="this.view_service = !this.view_service"
            class="btn btn-primary-color rounded-pill text-xs"
          >
            <span v-if="view_service == false"> View </span>
            <span v-else> Hide </span>
          </button>
        </div>
        <transition name="slide-fade">
          <div class="row" v-if="view_service == true">
            <hr class="fw-light" />
            <div
              class="card p-0 col-sm-3 mx-1 mt-2 mb-2 shadow-small"
              v-for="service in workflow_data.services"
            >
              <div class="card-body d-flex flex-column justify-content-between">
                <div>
                  <h6 class="fw-bold">
                    {{ capitilize_string(service.service.service_name) }}
                  </h6>
                </div>
                <div class="card-footer bg-white pb-0">
                  <p>
                    <span class="text-muted">Cost:</span>
                    <span
                      class="fw-bold text-primary float-inline-end"
                      v-if="service.service.service_details.length > 0"
                      >R {{ service.service.service_details[0].service_cost
                      }}</span
                    >
                    <span class="fw-bold text-primary float-inline-end" v-else
                      >R 0.00</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="card card-outline-primary mt-2 rounded-3 shadow">
      <div class="card-body p-0 px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="card-progress-tracker fw-bold text-center mb-0">
            <li
              :class="workflow_data.workflow_completed.consent_provided ? 'completed': 'in-completed'"
            >
              Terms and Condition Uploaded
            </li>
          </ul>
          <button
            v-if="workflow_data.workflow_completed.consent_provided"
            @click="this.view_terms = !this.view_terms"
            class="btn btn-primary-color rounded-pill text-xs"
          >
            <span v-if="view_terms == false"> View </span>
            <span v-else> Hide </span>
          </button>
        </div>
        <transition name="slide-fade">
          <div v-if="view_terms == true">
            <hr class="fw-light" />
            <iframe
              :src="workflow_data.terms_and_conditions"
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </transition>
      </div>
    </div>

    <div class="card card-outline-primary mt-2 rounded-3 shadow">
      <div class="card-body p-0 px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="card-progress-tracker fw-bold text-center mb-0">
            <li
              :class="workflow_data.workflow_completed.reason_for_usage_exists ? 'completed': 'in-completed'"
            >
              Reason for Workflow Provided
            </li>
          </ul>
          <button
            v-if="workflow_data.workflow_completed.reason_for_usage_exists"
            @click="this.view_reasons = !this.view_reasons"
            class="btn btn-primary-color rounded-pill text-xs"
          >
            <span v-if="view_reasons == false"> View </span>
            <span v-else> Hide </span>
          </button>
        </div>
        <transition name="slide-fade">
          <div v-if="view_reasons == true" class="modern-list mt-3">
            <hr class="fw-light" />
            <ul class="list-group">
              <li
                  :class="['list-group-item d-flex justify-content-between align-items-center text-xs py-4 border-0', index !== workflow_data.list_reasons.length - 1 ? 'border-bottom' : '']"
                v-for="(reason, index) in workflow_data.list_reasons"
                :key="index"
              >
                <span v-if="reason.is_selected == true">
                  <i class="fas fa-chevron-right text-primary me-2"></i>
                  {{ reason.usage__reason }}
                </span>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>

    <div class="card card-outline-primary mt-2 mb-2 rounded-3 shadow">
      <div class="card-body p-0 px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="card-progress-tracker fw-bold text-center mb-0">
            <li
              :class="workflow_data.is_confirmed ? 'completed': 'in-completed'"
            >
              Workflow Confirmed
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card-footer text-end mb-2" v-if="workflow_data">
      <button
        class="btn btn-danger text-xs rounded-small"
        v-if="workflow_data.workflow_status == 'pending' || workflow_data.workflow_status == 'approved'"
        @click="decline_workflow()"
      >
        Decline
      </button>
      <button
        class="btn btn-primary-color text-xs rounded-small mx-1"
        v-if="workflow_data.workflow_status == 'pending' || workflow_data.workflow_status == 'declined'"
        @click="approve_workflow()"
      >
        Approve
      </button>
    </div>
  </div>
</div>
