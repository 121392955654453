
<div>
  <div class="text-center mb-3">
    <h2 class="text-dark fw-bold text-md">Create Your Account</h2>
    <p class="text-dark text-xs">Please fill out the form below to set up a new account.</p>
  </div>
  <hr class="fw-light" />

  <form @submit.prevent="handle_register">
    <!-- First Name -->
    <div class="form-group mb-2">
      <label class="fw-bold text-xs text-start d-block mb-2">First Name</label>
      <input
        type="text"
        class="form-control rounded-small text-xs shadow-sm"
        v-model="first_name"
        placeholder="First Name"
        @input="validate_name('first_name')"
      />
      <small class="text-danger p-2 text-xxs fw-bold d-none first-name-message">
        Please fill your first name
      </small>
    </div>

    <!-- Last Name -->
    <div class="form-group mb-2">
      <label class="fw-bold text-xs text-start d-block mb-2">Last Name</label>
      <input
        type="text"
        class="form-control rounded-small text-xs shadow-sm"
        v-model="last_name"
        placeholder="Last Name"
        @input="validate_name('last_name')"
      />
      <small class="text-danger p-2 text-xxs fw-bold d-none last-name-message">
        Please fill your last name
      </small>
    </div>

    <!-- Email -->
    <div class="form-group mb-2">
      <label class="fw-bold text-xs text-start d-block mb-2">Email</label>
      <input
        type="email"
        class="form-control rounded-small text-xs shadow-sm"
        v-model="email"
        placeholder="Email"
        @input="convert_email_to_lowercase"
      />
      <small class="text-danger p-2 text-xxs fw-bold d-none email-message">
        Please fill your email address
      </small>
      <small class="text-danger p-2 text-xxs fw-bold d-none email-exists-message"></small>
      <small class="text-danger p-2 text-xxs fw-bold d-none email-error-message"></small>
    </div>

    <!-- Phone Number -->
    <div class="form-group mb-2">
      <label class="fw-bold text-xs text-start d-block mb-2">Contact Number</label>
      <input
        type="text"
        class="form-control rounded-small text-xs shadow-sm"
        v-model="phone_number"
        placeholder="Contact Number"
        maxlength="10"
        @input="validate_phone_number"
      />
      <small class="text-danger p-2 text-xxs fw-bold d-none phone-number-message">
        Please provide your cellphone number
      </small>
      <small class="text-danger p-2 text-xxs fw-bold" v-if="phone_number_error">
        Phone number must contain 10 numbers
      </small>
      <small class="text-danger p-2 text-xxs fw-bold d-none mobile-exists-message"></small>
    </div>

    <!-- Password -->
    <div class="form-group mb-2">
      <label class="fw-bold text-xs text-start d-block mb-2">Password</label>
      <div class="input-group position-relative">
        <input
            class="form-control rounded-small text-xs shadow-sm"
            v-model="password"
            placeholder="Password"
            :type="show_password ? 'text' : 'password'"
            @input="check_password_strength"
            @focus="password_field_active = true"
            @blur="password_field_active = false"
        />
        <span class="eye-icon" @click="toggle_password_view">
          <i :class="show_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
      </div>

      <div v-if="password && password_field_active" class="password-strength-container mt-2">
        <div class="strength-label d-flex justify-content-between align-items-center mb-1">
            <span class="text-muted">Password Strength:</span>
            <span :class="strengthTextClass">{{ password_strength }}</span>
        </div>
        <div class="strength-bar">
            <div :class="['strength-level', strengthBarClass]"></div>
        </div>
      </div>

      <!-- Password Requirements -->
      <div v-if="password && password_field_active" class="password-requirements mt-2">
        <div class="requirement-item" :class="{ 'requirement-met': requirements.lowercase }">
            <i :class="requirements.lowercase ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
            At least one lowercase
        </div>
        <div class="requirement-item" :class="{ 'requirement-met': requirements.uppercase }">
            <i :class="requirements.uppercase ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
            At least one uppercase
        </div>
        <div class="requirement-item" :class="{ 'requirement-met': requirements.numeric }">
            <i :class="requirements.numeric ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
            At least one numeric
        </div>
        <div class="requirement-item" :class="{ 'requirement-met': requirements.special }">
            <i :class="requirements.special ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
            At least one special character
        </div>
        <div class="requirement-item" :class="{ 'requirement-met': requirements.length }">
            <i :class="requirements.length ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
            Minimum 8 characters
        </div>
      </div>
      <small class="text-danger p-2 text-xxs fw-bold d-none password-message">
        Please create a password
      </small>
      <small class="text-danger p-2 text-xxs fw-bold d-none password-requirements-message"></small>
    </div>

    <!-- Confirm Password -->
    <div class="form-group mb-2">
      <label class="fw-bold text-xs text-start d-block mb-2">Confirm Password</label>
      <div class="input-group position-relative">
        <input
          class="form-control rounded-small text-xs shadow-sm"
          v-model="confirm_password"
          placeholder="Confirm Password"
          :type="show_confirm_password ? 'text' : 'password'"
          @input="password_match"
        />
        <span class="eye-icon" @click="toggle_confirm_password_view">
          <i :class="show_confirm_password ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
        </span>
      </div>
      <small class="text-danger p-2 text-xxs fw-bold d-none confirm-password-message">
        Please confirm your password
      </small>
      <small class="text-danger p-2 text-xxs fw-bold d-none match-password-message">
        Passwords do not match
      </small>
    </div>

    <!-- Dynamic Questions Component -->
    <div>
      <GetQuestions
        :client_admin_id="client_admin_id"
        ref="get_questions"
        page_type="register"
        @update-responses="store_responses"
      />
    </div>

    <!-- Submit Button -->
    <div class="mb-3">
      <small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>
      <button 
        type="submit" 
        class="btn btn-bg-green secondary-text-dark text-xs w-75 fw-bold mt-4"
      >
        Continue
      </button>
    </div>
  </form>

  <!-- Login Link -->
  <div class="text-center mb-2">
    <span class="text-dark text-xs">Already have an account?</span>
    <router-link to="/" class="text-primary text-xs">Login here</router-link>
  </div>
</div>