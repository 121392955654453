<div class="container-fluid bg-white vh-100 p-0 d-flex flex-column align-items-center">
  <div class="text-center mt-5 mb-2">
    <img :src="$clientLogo" width="250px" />
  </div>

  <div class="text-center mt-1 w-100">
    <nav aria-label="breadcrumb" class="w-50 mx-auto">
      <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2">
        <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
          Form
        </li>
      </ol>
    </nav>
  </div>

  <div v-if="is_form_data_ready" class="mt-2 w-50 d-flex flex-column align-items-center">
    <div v-for="(field, index) in form_data" :key="index" class="mb-4 w-100">
      <label :for="field.question" class="fw-bold text-xs ms-7">
        {{ field.question }} <span v-if="field.is_required" class="text-danger">*</span>
      </label>

      <div class="form-group mb-2 w-75 mx-auto">
        <div v-if="field.has_text || field.has_number">
          <input
            :type="field.question_type"
            class="form-control rounded-small shadow-sm w-100"
            v-model="responses[index].value"
            :placeholder="field.question"
            :required="field.is_required"
          />
        </div>

        <div v-if="field.has_checkbox">
          <div v-for="option in field.options" :key="option.option" class="form-check">
            <input
              type="radio"
              class="form-check-input rounded-small shadow-sm"
              v-model="responses[index].value"
              :value="option.option"
              :id="`checkbox_${index}_${option.option}`"
            />
            <label :for="`checkbox_${index}_${option.option}`" class="form-check-label text-xs">
              {{ option.option }}
            </label>
          </div>
        </div>

        <div v-if="field.has_selection">
          <select
            class="form-select form-select-sm rounded-small shadow-sm w-100"
            v-model="responses[index].value"
            :id="`select_${index}`"
          >
            <option value="" disabled hidden>{{ field.question }}</option>
            <option v-for="option in field.options" :key="option.option" :value="option.option">
              {{ option.option }}
            </option>
          </select>
        </div>

        <div v-if="field.has_file">
          <input
            type="file"
            class="form-control rounded-small shadow-sm w-100"
            @change="on_file_change($event, index)"
            ref="file_input"
          />
        </div>

        <div v-if="field.has_date">
          <input
            type="date"
            class="form-control rounded-small shadow-sm w-100"
            v-model="responses[index].value"
            :required="field.is_required"
          />
        </div>

        <small v-if="field.error_message" class="text-danger fw-bold">
          {{ field.error_message }}
        </small>
      </div>
    </div>

    <div
      v-for="(fields, group_index) in duplicate_fields"
      :key="group_index"
      class="mb-5 w-100"
    >
      <hr class="w-75 mx-auto mb-4" />

      <div
        v-for="(field, field_index) in fields"
        :key="`dup_${group_index}_${field_index}`"
        class="form-group mb-3 w-75 mx-auto"
      >
        <label :for="field.question" class="fw-bold text-xs ms-6">
          {{ field.question }} <span v-if="field.is_required" class="text-danger">*</span>
        </label>
        <input
          v-if="field.has_text || field.has_number"
          :type="field.question_type"
          class="form-control rounded-small shadow-sm w-100"
          v-model="field.value"
          :placeholder="field.question"
          :required="field.is_required"
        />

        <div v-if="field.has_checkbox">
          <div v-for="option in field.options" :key="option.option" class="form-check">
            <input
              type="radio"
              class="form-check-input rounded-small shadow-sm"
              v-model="field.value"
              :value="option.option"
              :id="`checkbox_${field_index}_${option.option}`"
            />
            <label :for="`checkbox_${field_index}_${option.option}`" class="form-check-label text-xs">
              {{ option.option }}
            </label>
          </div>
        </div>

        <div v-if="field.has_selection">
          <select
            class="form-select form-select-sm rounded-small shadow-sm w-100"
            v-model="field.value"
            :id="`select_${field_index}`"
          >
            <option value="" disabled hidden>{{ field.question }}</option>
            <option v-for="option in field.options" :key="option.option" :value="option.option">
              {{ option.option }}
            </option>
          </select>
        </div>

        <div v-if="field.has_file">
          <input
            type="file"
            class="form-control rounded-small shadow-sm w-100"
            @change="on_file_change($event, field_index)"
            ref="file_input"
          />
        </div>

        <div v-if="field.has_date">
          <input
            type="date"
            class="form-control rounded-small shadow-sm w-100"
            v-model="field.value"
            :required="field.is_required"
          />
        </div>

        <small v-if="field.error_message" class="text-danger fw-bold">
          {{ field.error_message }}
        </small>
      </div>
    </div>

    <div
      v-if="service_type === 'multi flex form'"
      class="d-flex justify-content-end align-items-center mb-3 w-100 pe-3"
    >
      <button
        class="btn btn-sm btn-danger text-white rounded-circle shadow-sm me-2 fw-bold"
        v-if="duplicate_fields.length > 0"
        @click="remove_fields(group_index)"
        title="Remove"
      >
        <i class="fa fa-trash"></i>
      </button>

      <button
        class="btn btn-sm btn-danger text-xxs text-white rounded-small shadow-sm fw-bold d-flex align-items-center me-5r"
        @click.prevent="add_more_fields"
        title="Add More"
      >
        <span class="me-1 text-xs fw-bold">+</span> Add More
      </button>
    </div>

    <div class="text-center mb-3 mt-5">
      <button
        class="btn btn-sm bg-green text-dark rounded-small fw-bold text-xs shadow-sm"
        @click.prevent="submit_form"
      >
        Continue
      </button>
    </div>
  </div>
</div>
  
