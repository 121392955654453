<div class="container-fluid">
  <div v-if="loading" class="text-center mt-4">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div v-else>
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-5 shadow">
          <div class="card-header bg-white border-0 fw-bold">
            Assigned Workflows
          </div>
          <div class="card-body">

            <div class="table-responsive">
              <table id="workflows-table" class="table w-100">
                <thead class="text-white">
                  <tr class="bg-dark">
                    <th class="text-uppercase bg-dark text-xs font-weight-bolder opacity-7 text-start">ID</th>
                    <th class="text-uppercase bg-dark text-xs font-weight-bolder opacity-7">Workflow Name</th>
                    <th class="text-uppercase bg-dark text-xs font-weight-bolder opacity-7 text-start">Company Name</th>
                    <th class="text-uppercase bg-dark text-xs font-weight-bolder opacity-7 text-start">Date Created</th>
                    <th class="text-uppercase bg-dark text-xs font-weight-bolder opacity-7 text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(workflow, index) in workflows" :key="workflow.id">
                    <td class="text-xxs text-start">{{ index + 1 }}</td>
                    <td class="text-xxs">{{ workflow.name }}</td>
                    <td class="text-xxs text-start">{{ workflow.company_name }}</td>
                    <td class="text-xxs text-start">{{ workflow.date_created.split('T')[0]}}</td>
                    <td>
                      <button 
                        type="button" 
                        class="btn bg-green rounded-small secondary-text-dark text-xxs shadow btn-sm fw-bold me-2" 
                        @click="onboard_workflow(workflow.workflow_id)">
                        Onboard
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



  