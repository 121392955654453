import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";

export default {
  name: 'ClientServices',
  mixins: [LOGOUT],
  
  data: () => ({
    personnel_services: [],
    compliance_services: [],
    custom_forms: [],
    credit_services: [],
    selected_forms:[],
    selected_services: [],
    is_selected : false,
    activeTab: 'personnel',
  }),
  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  
  methods: {
    showTab(tab) {
      this.activeTab = tab;
    },
    setup_horizontal_scroll() {
      this.$nextTick(() => {
        const container = this.$refs.services_container;
        if (!container) {
          console.error('services_container ref is not defined');
          return;
        }
        let isDragging = false;
        let startX;
        let scrollLeft;

        container.addEventListener('mousedown', (e) => {
          isDragging = true;
          startX = e.pageX - container.offsetLeft;
          scrollLeft = container.scrollLeft;
        });
        container.addEventListener('mouseleave', () => {
          isDragging = false;
        });
        container.addEventListener('mouseup', () => {
          isDragging = false;
        });
        container.addEventListener('mousemove', (e) => {
          if (!isDragging) return;
          e.preventDefault();
          const x = e.pageX - container.offsetLeft;
          const walk = (x - startX) * 2;
          container.scrollLeft = scrollLeft - walk;
        });
      });
    },
    open_modal(service) {
      $('#service_description_label').text(service.service_name);
      $('#service_description').text(service.service_details[0].service_description);
      $('#service_cost').text(service.service_details[0].service_cost);
      $('#service_input').empty();
      $('#service_output').empty();

      try {
        const INPUT_STRING = service.service_details[0].service_input.replace(/'/g, '"');
        const OUTPUT_STRING = service.service_details[0].service_output.replace(/'/g, '"');
        const INPUT_ARRAY = JSON.parse(INPUT_STRING);
        const OUTPUT_ARRAY = JSON.parse(OUTPUT_STRING);

        INPUT_ARRAY.forEach(input => {
          const INPUT_ITEM = $('#input_items').clone().removeAttr("id").text(input);
          $('#service_input').append(INPUT_ITEM);
        });

        OUTPUT_ARRAY.forEach(output => {
          const OUTPUT_ITEM = $('#output_items').clone().removeAttr("id").text(output);
          $('#service_output').append(OUTPUT_ITEM);
        });
      } catch (error) {
        console.error("Error parsing service input/output", error);
      }

      $('#service_description_modal').modal('show');
    },
    form_preview(form) {
      this.selected_forms = form;
      this.formInputs = form.form_inputs || [];
      $('#custom_form_preview_modal').modal('show');
    },
    get_services() {
      const TOKEN = localStorage.getItem('token');
      const GET_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/service_management_api/get_services_api/`;

      $.ajax({
        url: GET_SERVICES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          if (response.redirect){
            
            this.logout();
            return;
          }
          let services = response['services']
          const SERVICES = Array.isArray(services) ? services : [services];
          
          SERVICES.forEach(service => {
            if (service.service_details && service.service_details.length > 0) {
              let category_name = service.service_details[0].service_categories.category_name;
              
              if (category_name === 'personnel_verification') {
                this.personnel_services.push(service);
              } else if (category_name === 'compliance_verification') {
                this.compliance_services.push(service);
              } else if (category_name === 'credit_verification') {
                this.credit_services.push(service);
              }
            } else {
              this.custom_forms.push(service);
            }
          });

          // Update selected services after fetching
          this.get_selected_services(this.project_id);
        },
        error: (error) => {
          let error_message = 'Failed to get services';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },

    get_selected_services() {
      const TOKEN = localStorage.getItem('token');
      const GET_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_project_services/${this.project_id}`;
      
      $.ajax({
        url: GET_SELECTED_SERVICES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.selected_services = Array.isArray(response.all_services) ? response.all_services : [];
          this.update_service_selection();

        },
        error: (error) => {
          Swal.close();
          let error_message = 'Failed to get selected services';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    update_service_selection() {
      if (!Array.isArray(this.selected_services)) {
        this.selected_services = []; // Default to empty array
      }

      // Assuming selected_services contains service IDs
      let selected_service_ids = this.selected_services.map(service => service.service_id);
      let all_services = [...this.personnel_services, ...this.compliance_services, ...this.credit_services, ...this.custom_forms];

      all_services.forEach(service => {
        service.is_selected = selected_service_ids.includes(service.id);
      });
    },
    select_services(service, service_type) {
      this.toggle_service_selection(service, service_type);
      const TOKEN = localStorage.getItem('token');
      const SELECT_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/select_project_services_api/`;
      
      const FORM_DATA = {
        is_selected: service.is_selected, // Ensure this reflects the checkbox state
        project_id: this.project_id
      };

      if (service_type === 'form') {
        FORM_DATA.form_id = service.forms[0].id;
      } else {
        FORM_DATA.service_id = service.id;
      }
    
      $.ajax({
        url: SELECT_SERVICES_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify(FORM_DATA),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          localStorage.setItem('user_state', 'services_selected');
          let status = response.status;

          if (status === 'success'){
              this.toggle_service_selection(service, service_type);
          }else if(status === 'error'){
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.message,
              confirmButtonText: 'OK'
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = 'There was an error selecting this service. Please try again.';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    toggle_service_selection(service, service_type) {
      if (service.is_selected) {
        if (service_type === 'form'){
          if (!this.selected_services.some(s => s.id === service.forms[0].id)) {
            this.selected_services.push(service);
          }
        }
        else{
          if (!this.selected_services.some(s => s.id === service.id)) {
            this.selected_services.push(service);
          }
        }
      } else {
        // Remove service from selected_services if it's unselected
        this.selected_services = this.selected_services.filter(s => s.id !== service.id);
      }
    
    },
    select_custom_form(form) {    
      if (form.is_selected) {
        // Add to selected services if it's selected
        if (!this.selected_services.some(selected => selected.id === form.id)) {
          this.selected_services.push(form);
        }
      } else {
        // Remove from selected services if it's unselected
        this.selected_services = this.selected_services.filter(selected => selected.id !== form.id);
      }
    },
    route_to() {
      const isAtLeastOneServiceSelected =
        this.personnel_services.some(service => service.is_selected) ||
        this.compliance_services.some(service => service.is_selected) ||
        this.credit_services.some(service => service.is_selected);
      if (isAtLeastOneServiceSelected) {
        this.$router.push({ name: 'arrange_workflow' });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Please select at least one service before proceeding.',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    },
    next() {
      //Handles navigation based on the previous route and the presence of services for the given project and workflow.
      let project_id = this.project_id;
      let workflow_id = this.workflow_id;
      const TOKEN = localStorage.getItem('token');
      let previous_route_name = localStorage.getItem('previous_route_name');

      if (previous_route_name === 'service_dashboard') {
        this.$router.push({ name: 'service_dashboard' });
      } else {
        const GET_REDIRECTS = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/redirect_on_next_select_services_button/${project_id}/${workflow_id}`;

        $.ajax({
          url: GET_REDIRECTS,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          success: () => {
            this.$router.push({
              name: 'workflow_services',
              params: { project_id: project_id, workflow_id: workflow_id }
            });
          },
          error: () => {
            Swal.close();
          }
        });
      }
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
        return;
    }
    this.setup_horizontal_scroll();
    this.get_services();
    this.get_selected_services();
  }
};