<div class="container-fluid bg-white vh-100 p-0">
    <ClientLogo />
    <BreadCrumb label="Director Information" />

    <div class="d-flex flex-column align-items-center min-vh-50">
        <div
            v-for="(director, index) in masked_directors"
            :key="index"
            class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3 mb-4 rounded"
        >
            <h5 class="fw-bold text-xs text-start w-75 mb-2">Director {{ index + 1 }}</h5>

            <div class="form-group mt-3">
                <label
                    for="full_name"
                    class="fw-bold text-xs text-start d-block"
                >Full Name</label>
                <input
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    :id="'full_name_' + index"
                    v-model="director.full_name"
                    disabled
                />

                <label
                    for="id_number"
                    class="fw-bold text-xs text-start d-block mt-3"
                > ID Number</label>
                <input
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    :id="'id_number_' + index"
                    :value="director.masked_id_number"
                    disabled
                />

                <label
                    for="email"
                    class="fw-bold text-xs text-start d-block mt-3"
                >Email <span class="text-danger">*</span></label>
                <input
                    type="email"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    v-model="email[index]"
                    required
                />
                <small
                    class="form-text text-danger d-none text-xxs fw-bold invalid-dob"
                >
                    Email is invalid
                </small>

                <label
                    for="phone_number"
                    class="fw-bold text-xs text-start d-block mt-3"
                >Phone number <span class="text-danger">*</span></label>
                <input
                    type="text"
                    class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
                    v-model="phone_number[index]"
                    required
                />
                <small
                    class="form-text text-danger d-none text-xxs fw-bold invalid-dob"
                >
                    Phone number is invalid
                </small>
            </div>
        </div>

        <div class="text-center mb-3 mt-3">
            <button
                class="btn btn-sm bg-green text-dark rounded-small fw-bold text-xs shadow-sm"
                @click.prevent="update_director_information"
            >
                Continue
            </button>
        </div>
    </div>
</div>


