import { validateToken } from '@/utils/auth';

export default {
  name: "edit_workflow",

  data: () => ({
    workflow_name: "",
    workflow_description: "",
    workflow_status: "",
    workflow_data: null,
    activeTab: "progress",
    services: [],
    service: "",
    selected_workflow_id: "",
    selected_template_id: "",
    templates: "",
    editing_workflow: false,
    edit_name: "",
    edit_description: "",
  }),

  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    steps() {
      if (!this.workflow_data) {
        return [];
      }

      return [
        {
          step_name: "Create workflow",
          status: this.workflow_data.workflow_exists ? "completed" : "pending",
          path: `/client/project_dashboard/`,
        },
        {
          step_name: "Choose Services",
          status: this.workflow_data.workflow_services_exist
            ? "completed"
            : "pending",
          path: `/client/workflow_services/`,
        },
        {
          step_name: "Order Services",
          status: this.workflow_data.workflow_services_order_exists
            ? "completed"
            : "pending",
          path: `/client/arrange_workflow/`,
        },
        {
          step_name: "Upload Consent",
          status: this.workflow_data.consent_uploaded
            ? "completed"
            : this.workflow_data.selected_use_terms_and_conditions
            ? "completed"
            : "pending",
          path: `/client/upload_consent/`,
        },
        {
          step_name: "Reasons for Usage",
          status: this.workflow_data.reason_exists ? "completed" : "pending",
          path: `/client/reasons_for_usage/`,
        },
        {
          step_name: "Confirm Workflow",
          status: this.workflow_data.confirmed_workflow_exists
            ? "completed"
            : "pending",
          path: `/client/confirm_workflow/`,
        },
      ];
    },

    workflow_status_class() {
      switch (this.workflow_status.toLowerCase()) {
        case "pending":
          return "text-success"; // Green for pending status
        case "inactive":
          return "text-warning"; // Yellow for inactive status
        case "incomplete":
          return "text-danger"; // Red for incomplete status
        default:
          return "text-muted"; // Gray for unknown status
      }
    },
  },

  methods: {
    toggle_edit() {
      this.editing_workflow = !this.editing_workflow;

      this.edit_name = this.workflow_name;
      this.edit_description = this.workflow_description;
    },

    save_edit_workflow() {
      const TOKEN = localStorage.getItem("token");
      const BASE_URL = process.env.API_BASE_URL;
      const EDIT_WORKFLOW_URL = `${BASE_URL}/verify_api/workflow_management_api/edit_workflow_api/`;
      
      if (!this.edit_name || !this.edit_description) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: "Workflow name and description cannot be empty.",
          confirmButtonText: "OK",
        });
        return;
      }
      
      const FORM_DATA = {
        workflow_id: this.workflow_id,
        workflow_name: this.edit_name,
        workflow_description: this.edit_description,
      };

      $.ajax({
        method: "POST",
        url: EDIT_WORKFLOW_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
            icon: "success",
            text: "Workflow edited successfully",
            confirmButtonColor: "#59FF74",
            showConfirmButton: false, // Hide the confirm button
            timer: 3000,
          }).then(() => {
            window.location.reload();
          });
        },
        error: (error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.responseJSON.message,
            confirmButtonColor: "#59FF74",
            showConfirmButton: true,
          });
        },
      });
    },
    showTab(tab) {
      this.activeTab = tab;
    },

    capitalize_first_letter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    get_workflow_information() {
      const TOKEN = localStorage.getItem("token");
      const GET_WORKFLOW_INFORMATION = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_data_api/${this.workflow_id}`;

      $.ajax({
        url: GET_WORKFLOW_INFORMATION,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          if (response.workflow_data) {
            const workflow = response.workflow_data[0]; // Ensure this is the correct structure

            this.workflow_name = this.capitalize_first_letter(
              workflow.workflow_name || ""
            );
            this.workflow_description = this.capitalize_first_letter(
              workflow.workflow_description || ""
            );
            this.workflow_status = workflow.workflow_status || "";

            // Fetch progress after getting workflow information
            this.edit_workflow_progress();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Workflow data is missing.",
              confirmButtonText: "OK",
            });
          }
        },
        error: (error) => {
          const error_message =
            error?.responseJSON?.error || "Error fetching workflow details";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error_message,
            confirmButtonText: "OK",
          });
        },
      });
    },

    get_selected_services_for_workflow() {
      const TOKEN = localStorage.getItem("token");
      const GET_SELECTED_SERVICES_FOR_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_services/${this.workflow_id}`;

      $.ajax({
        url: GET_SELECTED_SERVICES_FOR_WORKFLOW_API,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },

        success: (response) => {
          Swal.close();
          if (response.status === "success" && Array.isArray(response.data)) {
            // Map through the response data
            this.services = response.data.map((item, index) => {
              if (item.service) {
                return {
                  ...item.service,
                  type: "service", // Add a type to distinguish between forms and services
                  service_id: item.project_service_id,
                  count: response.data.length,
                  service_order: item.service_order || index + 1,
                  selected_order: item.service_order || null,
                };
              }
            });
          } else {
            Swal.fire({
              text: "Unexpected response format.",
              icon: "error",
              showCancelButton: true,
              confirmButtonText: "OK",
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = "There was an error fetching selected services";
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error_message,
            confirmButtonText: "OK",
          });
        },
      });
    },

    open_assign_modal(service_id) {
      this.workflow_id;
      this.selected_template_id = "";
      this.service_id = service_id;
    },

    get_templates() {
      const TOKEN = localStorage.getItem("token");
      const GET_TEMPLATES_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/get_template_api/`;

      $.ajax({
        url: GET_TEMPLATES_URL,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          this.templates = response;
          this.loading = false;
        },
        error: () => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = "Error Getting questions";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error_message,
            confirmButtonText: "OK",
          });
        },
      });
    },

    assign_to_workflow(template_id) {
      const TOKEN = localStorage.getItem("token");
      const BASE_URL = process.env.API_BASE_URL;
      const ASSIGN_ASSET_TO_WORKFLOW_URL = `${BASE_URL}/verify_api/customer_management_api/assign_template_to_workflow_api/`;
      const FORM_DATA = {
        template_id: template_id,
        workflow_id: this.workflow_id,
        service_id: this.service_id,
      };

      $.ajax({
        method: "POST",
        url: ASSIGN_ASSET_TO_WORKFLOW_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
            icon: "success",
            text: "template assigned to a workflow successfully",
            confirmButtonColor: "#59FF74",
            showConfirmButton: false, // Hide the confirm button
            timer: 3000,
          }).then(() => {
            window.location.reload();
            $("#add_service").modal("hide");
          });
        },
        error: (error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.responseJSON.message,
            confirmButtonColor: "#59FF74",
            showConfirmButton: true,
          });
        },
      });
    },

    edit_workflow_progress() {
      const TOKEN = localStorage.getItem("token");
      const GET_WORKFLOW_PROGRESS = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/edit_workflow_progress_api/${this.workflow_id}`;

      $.ajax({
        url: GET_WORKFLOW_PROGRESS,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          this.workflow_data = response; // Update workflow_data for the steps
        },
        error: () => {
          let error_message = "Error fetching workflow progress";
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error_message,
            confirmButtonText: "OK",
          });
        },
      });
    },
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_workflow_information();
    this.get_selected_services_for_workflow();
    this.get_templates();
  },
};


