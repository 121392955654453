<form method="post" @submit.prevent="submit_otp">
  <div class="text-center">
    <p class="text-dark fw-bold mb-3 text-x"></p>
  </div>
  <div class="text-center mb-3">
    <p class="text-dark fw-bold text-md">Enter OTP</p>
	<p class="text-dark text-xs">
	  Please enter the one-time pin sent to your email to verify your account
	</p>
  </div>
  <hr class="fw-light" />
  <div class="form-group mb-2">
    <label for="" class="fw-bold text-xs text-start d-block mb-2">One Time Pin</label>
    <input
      type="text"
      class="form-control rounded-small text-xs shadow-sm"
      v-model="otp"
      maxlength="5"
      minlength="5"
      name="pin"
      placeholder="Enter OTP"
      @input="validate_input('otp')"
    />
    <small class="text-danger p-2 text-xxs fw-bold d-none otp-message"
      >Please fill out this field</small
    >
    <small v-if="show_resend_message" class="text-success p-2 text-xxs fw-bold"
      >OTP has been resent</small
    >
    <small v-if="failed_resend_message" class="text-danger p-2 text-xxs fw-bold"
      >Failed to resend otp</small
    >
  </div>
  <small class="text-danger p-2 text-xxs fw-bold d-none error-message"></small>

  <div>
    <GetQuestions
      :client_admin_id="client_admin_id"
      ref="get_questions"
      page_type="otp"
      @update-responses="store_responses"
    />
  </div>

  <div class="mt-3 mb-2">
    <span class="text-xs"
      >Did not receive OTP?
      <a class="text-primary text-xs otp" @click="resend_otp"
        >Resend OTP</a
      ></span
    >
  </div>

  <div class="mb-3">
    <button
      type="submit"
      class="btn btn-bg-green secondary-text-dark text-xs w-75 fw-bold mt-2"
      @click="route_to"
    >
      Continue
    </button>
  </div>
</form>
