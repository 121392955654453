import { ref } from "vue";
import { validate_id } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { validateToken } from '@/utils/auth';
import { FaceMesh } from '@mediapipe/face_mesh';
import { Camera } from '@mediapipe/camera_utils';

export default {
  name: "FacialBiometricsVerification",
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo,
  },

  setup() {
    const id_number = ref("");

    return {
      id_number,
      validate_id
    };
  },

  data: () => ({
    id_number: "",
    photo_taken: false,
    photo_src: "",
    video_stream: null,
    is_mobile_view: false,
    showCamera: false,
    
    // Face detection related data
    current_step: 0,
    is_captured: false,
    progress_percentage: 0,
    oval_size: 0.18,
    step_start_time: 0,
    has_initial_instruction: false,
    selected_voice: null,
    voice_initialized: false,
    timer_count: 3,
    show_timer: false,
    loading: true,
    status_message: "",
    last_spoken_message: '',
    last_spoken_time: 0,
    spoken_message: '',
    
    // Constants
    STEPS: {
      LOOK_LEFT: 0,
      LOOK_RIGHT: 1,
      MOVE_CLOSER: 2,
      CAPTURE: 3
    },
    STEP_HOLD_TIME: 1200,
    SPEECH_COOLDOWN: 1500,

    instruction_message: "",
    status_messages: {
      LOOK_LEFT: "Please turn your head slightly to the left",
      LOOK_RIGHT: "Please turn your head slightly to the right",
      GET_CLOSER: "Please position your face within the oval",
      CAPTURE: "Hold still for photo capture",
      MULTIPLE_FACES: "Only one person should be in frame",
      NO_FACE: "No face detected - please center your face",
      PERFECT_POSITION: "Perfect position! Hold still",

      MOVE_SLIGHTLY_LEFT: "Please move slightly to the left",
      MOVE_SLIGHTLY_RIGHT: "Please move slightly to the right",
      MOVE_SLIGHTLY_UP: "Please move slightly up",
      MOVE_SLIGHTLY_DOWN: "Please move slightly down",
    }
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    progressStyle() {
      const circumference = 2 * Math.PI * 45;
      const offset = circumference - (this.progress_percentage / 100) * circumference;
      return {
        strokeDasharray: `${circumference} ${circumference}`,
        strokeDashoffset: offset
      };
    },
    formattedStatusMessage() {
      return `"${this.status_message.replace(
        /\b(left|right|up|down)\b/g,
        '<span class="highlight">$1</span>'
      )}"`;
    }
  },

  methods: {

    startFaceRecognition() {
      this.showCamera = true;
      this.initialize_camera();
    },
    speak(message) {
      const currentTime = Date.now();
      if (message !== this.last_spoken_message && 
          currentTime - this.last_spoken_time > this.SPEECH_COOLDOWN) {
        if (this.voice_initialized && this.selected_voice) {
          speechSynthesis.cancel();
          const utterance = new SpeechSynthesisUtterance(message);
          utterance.voice = this.selected_voice;
          utterance.rate = 1.0;
          utterance.pitch = 1.1;
          utterance.volume = 1.0;
          speechSynthesis.speak(utterance);
          this.last_spoken_message = message;
          this.last_spoken_time = currentTime;
          this.spoken_message = message; // Display the spoken message
        }
      }
    },
    

    async setupFaceMesh() {
      try {
        const faceMesh = new FaceMesh({
          locateFile: (file) =>
            `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
        });
    
        faceMesh.setOptions({
          maxNumFaces: 1,
          refineLandmarks: true,
          minDetectionConfidence: 0.7,
          minTrackingConfidence: 0.7,
        });
    
        faceMesh.onResults(this.onResults);
    
        // Assigning the camera instance to this.camera
        this.camera = new Camera(this.$refs.video, {
          onFrame: async () => {
            await faceMesh.send({ image: this.$refs.video });
          },
          width: 640,
          height: 480,
        });
    
        this.camera.start();
        this.loading = false;
        this.speak(
          "Face detection system initialized... Please look slightly to the left"
        );
        this.updateStatusMessage(this.status_messages.LOOK_LEFT);
      } catch (error) {
        console.error("Face detection setup failed:", error);
      }
    },

    checkGazeDirection(landmarks) {
      const left_eye = landmarks[33];
      const right_eye = landmarks[263];
      const nose = landmarks[1];
      
      const eye_distance = right_eye.x - left_eye.x;
      const nose_position = (nose.x - left_eye.x) / eye_distance;
      
      let is_correct_position = false;
      
      switch(this.current_step) {
        case this.STEPS.LOOK_LEFT:
          is_correct_position = nose_position > 0.7;
          break;
        case this.STEPS.LOOK_RIGHT:
          is_correct_position = nose_position < 0.3;
          break;
      }
      
      if (is_correct_position) {
        this.progress_percentage = Math.min(100, this.progress_percentage + 5);
      } else {
        this.progress_percentage = Math.max(0, this.progress_percentage - 2);
      }
      
      return is_correct_position;
    },

    checkFaceFit(landmarks) {
      const centerX = this.$refs.canvas.width / 2;
      const centerY = this.$refs.canvas.height / 2;
      const radiusX = this.$refs.canvas.width * this.oval_size;
      const radiusY = this.$refs.canvas.height * (this.oval_size * 1.67);

      const facePoints = [
        landmarks[10],   // Forehead
        landmarks[152],  // Chin
        landmarks[234],  // Left cheek
        landmarks[454],  // Right cheek
        landmarks[1],    // Nose
        landmarks[33],   // Left eye
        landmarks[263],  // Right eye
        landmarks[61],   // Mouth left
        landmarks[291]   // Mouth right
      ];

      const faceWidth = Math.abs(landmarks[234].x - landmarks[454].x) * this.$refs.canvas.width;
      const minRequiredWidth = this.$refs.canvas.width * 0.25;

      if (faceWidth < minRequiredWidth) {
        this.speak('Please move closer to the camera');
        this.updateStatusMessage(this.status_messages.GET_CLOSER);
        this.progress_percentage = Math.max(0, this.progress_percentage - 2);
        return false;
      }

      const isInOval = facePoints.every(point => {
        const x = (point.x * this.$refs.canvas.width - centerX) / radiusX;
        const y = (point.y * this.$refs.canvas.height - centerY) / radiusY;
        return (x * x + y * y) <= 1;
      });

      const noseX = landmarks[1].x * this.$refs.canvas.width;
      const noseY = landmarks[1].y * this.$refs.canvas.height;
      const isCentered = Math.abs(noseX - centerX) < radiusX * 0.2 && 
                          Math.abs(noseY - centerY) < radiusY * 0.2;

      if (!isInOval || !isCentered) {
        if (noseX < centerX - radiusX * 0.2) {
          this.speak('Please move slightly to the right');
          this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_RIGHT);
        } else if (noseX > centerX + radiusX * 0.2) {
          this.speak('Please move slightly to the left');
          this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_LEFT);
        } else if (noseY < centerY - radiusY * 0.2) {
          this.speak('Please move slightly down');
          this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_DOWN);
        } else if (noseY > centerY + radiusY * 0.2) {
          this.speak('Please move slightly up');
          this.updateStatusMessage(this.status_messages.MOVE_SLIGHTLY_UP);
        }
        this.progress_percentage = Math.max(0, this.progress_percentage - 2);
      } else {
        this.progress_percentage = Math.min(100, this.progress_percentage + 5);
        if (this.progress_percentage >= 90) {
          this.speak('Perfect position! Hold still');
          this.updateStatusMessage(this.status_messages.PERFECT_POSITION);
        }
      }

      return isInOval && isCentered && this.progress_percentage >= 100;
    },

    updateStatusMessage(message) {
      this.status_message = message;
      this.instruction_message = this.status_messages[this.current_step] || "";
    },

    progressToNextStep() {
      this.step_start_time = 0;
      this.current_step++;
      this.progress_percentage = 0;
      
      switch(this.current_step) {
        case this.STEPS.LOOK_RIGHT:
          this.speak('Great! Now look slightly to the right');
          this.updateStatusMessage(this.status_messages.LOOK_RIGHT);
          break;
        case this.STEPS.MOVE_CLOSER:
          this.speak('Excellent! Now move closer');
          this.updateStatusMessage(this.status_messages.GET_CLOSER);
          this.oval_size = 0.25;
          break;
        case this.STEPS.CAPTURE:
          this.speak('Perfect! Hold still for the countdown');
          this.updateStatusMessage(this.status_messages.CAPTURE);
          this.startCountdown();
          break;
      }
    },

    startCountdown() {
      this.show_timer = true;
      this.timer_count = 3;
      
      const countdown = setInterval(() => {
        this.timer_count--;
        if (this.timer_count <= 0) {
          clearInterval(countdown);
          this.show_timer = false;
          this.take_photo();
        }
      }, 1000);
    },

    onResults(results) {
      if (this.is_captured) return;

      if (results.multiFaceLandmarks?.length > 0) {
        const numFaces = results.multiFaceLandmarks.length;
        if (numFaces > 1) {
          this.speak('Please ensure only one person is in frame');
          this.updateStatusMessage(this.status_messages.MULTIPLE_FACES);
          return;
        } else if (numFaces === 1) {
          if (this.status_message.includes('No face') || 
              this.status_message.includes('Multiple faces')) {
            this.status_message = '';
          }
          
          const landmarks = results.multiFaceLandmarks[0];
          
          if (this.current_step <= this.STEPS.LOOK_RIGHT) {
            const gazeCorrect = this.checkGazeDirection(landmarks);
            if (gazeCorrect && !this.step_start_time) {
              this.step_start_time = Date.now();
            } else if (gazeCorrect && Date.now() - this.step_start_time >= this.STEP_HOLD_TIME) {
              this.progressToNextStep();
            }
          } else if (this.current_step === this.STEPS.MOVE_CLOSER) {
            if (this.checkFaceFit(landmarks)) {
              this.progressToNextStep();
            }
          }
        }
      } else {
        this.speak('No face detected');
        this.updateStatusMessage(this.status_messages.NO_FACE);
      }
    },

    show_instructions() {
      setTimeout(() => {
        $("#show_instruction").modal("show");
      }, 100);
    },

    take_photo() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      
      this.photo_taken = true;
      this.photo_src = canvas.toDataURL();
      this.is_captured = true;
      
      if (this.is_mobile_view) {
        $("#take_photo").hide();
        $("#submit_photo").show();
        $(".retake-photo").removeClass("d-none");
      }

      // Automatically submit the photo
      this.safps_verification();
    },

    initialize_camera() {
      if (this.video_stream) return;

      const constraints = { video: true };
      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          const video = this.$refs.video;
          video.srcObject = stream;
          this.video_stream = stream;
          
          video.onloadedmetadata = () => {
            video.play();
            this.setupFaceMesh();
          };
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    },

    safps_verification() {
      const TOKEN = localStorage.getItem("token");
      const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/facial_biometrics_liveness_verification/`;
      const DATA = {
        id_number: this.id_number,
        biometric: this.photo_src,
        workflow_id: this.workflow_id,
      };
      $.ajax({
        url: SAFPS_URL,
        method: "POST",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          const status_message = response.status;
          var liveness_value = response.liveness;
          const message = response.message;
          const user_role = response["role"];

          this.stop_camera();
          this.showCamera = false;

          if (status_message === "success") {
            if (user_role === "Director") {
              this.$router.push("/upload_proof_of_address");
            } else {
              this.fetch_current_service();
            }
          } else if (
            status_message ===
            "Results not found please contact your service provider"
          ) {
            Swal.fire({
              icon: "error",
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            });
          }
          if (liveness_value === 'User is wearing a hat'){
            liveness_value = "Please remove your hat and try again"
          }
          if (liveness_value === 'User is wearing glasses'){
            liveness_value = "Please remove your glasses and try again"
          }

          if (liveness_value) {
            Swal.fire({
              icon: "error",
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          }

          if (message) {
            Swal.fire({
              icon: "error",
              title: message,
            }).then(() => {
              location.reload();
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = "Error retrieving data";

          if (error.responseJSON) {
            error_message = error.responseJSON.id_number[0];
            $(".error-message")
              .text(error_message)
              .removeClass("d-none");
            setTimeout(() => {
              $(".error-message").text("").addClass("d-none");
            }, 3000);
          }
        },
      });
    },


    async stop_camera() {
      try {
        // Stop MediaPipe camera
        if (this.camera) {
          await this.camera.stop();
          this.camera = null;
        }
    
        // Stop the video stream
        if (this.video_stream) {
          const tracks = this.video_stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          this.video_stream = null;
        }
    
        // Clear video source
        if (this.$refs.video) {
          this.$refs.video.srcObject = null;
        }
    
        // Reset states
        this.showCamera = false;
        this.is_captured = true;
        this.loading = false;
      } catch (error) {
        console.error("Error stopping camera:", error);
      }
    },

    toggle_photo_sections() {
      this.is_mobile_view = window.innerWidth <= 768;
      if (this.is_mobile_view) {
        $("#take_photo").show();
        $("#submit_photo").hide();
      } else {
        $("#take_photo").show();
        $("#submit_photo").show();
      }
    },

    handle_window_resize() {
      this.toggle_photo_sections();
    },
  },

  mounted() {
    if (!validateToken(this.$router)) return;

    // Initialize speech synthesis
    speechSynthesis.onvoiceschanged = () => {
      const voices = speechSynthesis.getVoices();
      this.selected_voice = voices.find(voice => 
        voice.name.includes('Zira') || voice.name.includes('Microsoft Zira')
      ) || voices.find(voice => 
        voice.name.toLowerCase().includes('female') || 
        voice.name.includes('woman') ||
        voice.name.includes('Victoria') ||
        voice.name.includes('Karen')
      ) || voices.find(voice => 
        voice.lang.startsWith('en') && 
        !voice.name.includes('David') && 
        !voice.name.toLowerCase().includes('male')
      ) || voices[0];
      this.voice_initialized = true;
    };

    const shown_instructions = localStorage.getItem("shown_instructions");

    if (shown_instructions) {
      this.initialize_camera();
    } else {
      $("#show_instruction").on("hidden.bs.modal", () => {
        this.initialize_camera();
        window.addEventListener("resize", this.handle_window_resize);
      });
    }
  },

  beforeDestroy() {
    this.stop_camera();
    window.removeEventListener("resize", this.handle_window_resize);
  },

  beforeRouteLeave(next) {
    this.stop_camera();
    next();
  },
};