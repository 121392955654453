<div class="container-fluid">
    <div v-if="loading" class="text-center mt-4">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="card rounded-3 mt-4 p-0">
        <div class="card-header bg-dark p-2">
          <h6 class="card-heading fw-bold text-center text-white text-s text-start">Services</h6>
        </div>
        <div class="container-fluid mt-3">
          <ul class="nav nav-tabs mb-4 d-flex justify-content-between" id="myTab" role="tablist">
            <li class="nav-item flex-fill me-2" role="presentation">
              <button class="nav-link w-100 fw-bold text-dark border-0" :class="{ active: currentTab === 'Approved' }"
                      @click="switchTab('Approved')">
                      Approved
              </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
              <button class="nav-link w-100 fw-bold text-dark border-0" :class="{ active: currentTab === 'Pending' }"
                      @click="switchTab('Pending')">
                      Pending
              </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
              <button class="nav-link w-100 fw-bold text-dark border-0" :class="{ active: currentTab === 'Declined' }"
                      @click="switchTab('Declined')">
                      Declined
              </button>
            </li>
          </ul>


          <div class="tab-content">
            <div v-if="currentTab === 'Approved'" class="tab-pane fade show active">
              <table  id="serivcesTable" class="table table-clear">
                <thead class="bg-gray text-dark">
                    <tr>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">ID</th>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Service Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-xs" v-for="(approved, index) in approved_requests" :key="approved.id">
                        <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                        <td class="text-xs fw-bold text-start">{{ approved.service_name }}</td>
                    </tr>
                </tbody>
              </table>
            </div>
        
            <div v-if="currentTab === 'Pending'" class="tab-pane fade show active">
              <table id="serivcesTable" class="table table-clear">
                <thead class="bg-gray text-dark">
                    <tr>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">ID</th>
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Service Name</th>    
                        <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-xs" v-for="(pending, index) in pending_requests" :key="pending.id">
                      <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                      <td class="text-xs fw-bold text-start">{{ pending.service_name }}</td>
                      <td>
                        <button class="btn bg-green btn-sm text-xxs fw-bold rounded-pill me-2" @click="action_service_requested(pending.service_id,pending.application_id,'approve')">Approve</button>
                        <button class="btn btn-danger btn-sm text-xxs fw-bold rounded-pill me-2" @click="action_service_requested(pending.service_id,pending.application_id,'decline')">Decline</button>
                      </td>
                    </tr>
                    
                </tbody>
              </table>
            </div>
        
            <div v-if="currentTab === 'Declined'" class="tab-pane fade show active">
              <table id="serivcesTable" class="table table-clear">
                <thead class="bg-gray text-dark">
                  <tr>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">ID</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Service Name</th>
                    <th class="text-uppercase text-xs font-weight-bolder opacity-7 text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-xs" v-for="(declined, index) in declined_requests" :key="declined.id">
                    <td class="text-xs fw-bold text-start">{{ index + 1 }}</td>
                    <td class="text-xs fw-bold text-start">{{ declined.service_name }}</td>
                    <td>
                      <button class="btn bg-green btn-sm text-xxs fw-bold rounded-pill me-2" @click="action_service_requested(declined.service_id,declined.application_id,'approve')">Approve</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>      
</div>