<div class="container-fluid">
    <div class="card rounded-3 mt-4 shadow">
      <div class="card-header bg-white border-0 rounded-top">
        <span class="p-0 px-4 pt-4 fw-bold secondary-text-dark">
            Workflows
        </span>
        <hr>
      </div>
    <div class="container mt-3">
        <ul class="nav nav-tabs mb-4 d-flex justify-content-between text-xs" id="myTab" role="tablist">
            <li class="nav-item flex-fill me-2" role="presentation">
                <button class="nav-link w-100 fw-bold secondary-text-dark border-0 " :class="{ active: current_tab === 'pending' }"
                                @click="switch_tab('pending')">
                    Pending
                </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
                <button class="nav-link w-100 fw-bold secondary-text-dark border-0" :class="{ active: current_tab === 'approved' }"
                                @click="switch_tab('approved')">
                    Approved
                </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
                <button class="nav-link w-100 fw-bold secondary-text-dark border-0" :class="{ active: current_tab === 'declined' }"
                                @click="switch_tab('declined')">
                    Declined
                </button>
            </li>
            <li class="nav-item flex-fill me-2" role="presentation">
                <button class="nav-link w-100 fw-bold secondary-text-dark border-0" :class="{ active: current_tab === 'incomplete' }"
                                @click="switch_tab('incomplete')">
                    Incomplete
                </button>
            </li>
        </ul>

        <div class="tab-content ms-2 me-2">
            <!-- Pending Tab -->
            <div v-if="current_tab === 'pending'" class="tab-pane fade show active">
                <table id="workflows-table" class="table table-clear">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="text-uppercase text-xxs ">ID</th>
                            <th class="text-uppercase text-xxs ">NAME</th>
                            <th class="text-uppercase text-xxs text-start">DATE CREATED</th>
                            <th class="text-uppercase text-xxs text-start">WORKFLOW STATUS</th>
                            <th class="text-uppercase text-xxs text-start">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(workflow, index) in filtered_data" :key="workflow.id">
                            <td class="text-xxs text-start">{{ index + 1 }}</td>
                            <td class="text-xxs ">{{ workflow.workflow_name }}</td>
                            <td class="text-xxs text-start">{{ workflow.date_created }}</td>
                            <td class="text-danger text-xxs ">
                                <span :class="['badge', 'shadow', workflow.is_active ? 'bg-success' : 'bg-danger']">{{ workflow.is_active ? 'Active' : 'Inactive' }}</span>
                            </td>
                            <td>
                                <button class="btn bg-green btn-sm text-xxs rounded-small" @click="view_workflow_information(workflow.id)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Active Tab -->
            <div v-if="current_tab === 'active'" class="tab-pane fade show active">
                <table id="workflows-table" class="table table-clear">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="text-uppercase text-xxs ">ID</th>
                            <th class="text-uppercase text-xxs ">NAME</th>
                            <th class="text-uppercase text-xxs text-start">DATE CREATED</th>
                            <th class="text-uppercase text-xxs text-start">WORKFLOW_STATUS</th>
                            <th class="text-uppercase text-xxs text-start">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(workflow, index) in filtered_data" :key="workflow.id">
                            <td class="text-xxs text-start">{{ index + 1 }}</td>
                            <td class="text-xxs ">{{ workflow.workflow_name }}</td>
                            <td class="text-xxs text-start">{{ workflow.date_created }}</td>
                            <td class="text-danger text-xxs ">
                                <span :class="['badge', 'shadow', workflow.is_active ? 'bg-success' : 'bg-danger']">{{ workflow.is_active ? 'Active' : 'Inactive' }}</span>
                            </td>
                            <td>
                                <button class="btn bg-green btn-sm text-xxs rounded-small"  @click="view_workflow_information(workflow.id)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Approved Tab -->
            <div v-if="current_tab === 'approved'" class="tab-pane fade show active">
                <table id="workflows-table" class="table table-clear">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="text-uppercase text-xxs ">ID</th>
                            <th class="text-uppercase text-xxs ">NAME</th>
                            <th class="text-uppercase text-xxs text-start">DATE CREATED</th>
                            <th class="text-uppercase text-xxs text-start">WORKFLOW_STATUS</th>
                            <th class="text-uppercase text-xxs text-start">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(workflow, index) in filtered_data" :key="workflow.id">
                            <td class="text-xxs text-start">{{ index + 1 }}</td>
                            <td class="text-xxs ">{{ workflow.workflow_name }}</td>
                            <td class="text-xxs text-start">{{ workflow.date_created }}</td>
                            <td class="text-danger text-xxs ">
                                <span :class="['badge', 'shadow', workflow.is_active ? 'bg-success' : 'bg-danger']">{{ workflow.is_active ? 'Active' : 'Inactive' }}</span>
                            </td>
                            <td>
                                <button class="btn bg-green btn-sm text-xxs rounded-small"  @click="view_workflow_information(workflow.id)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Declined Tab -->
            <div v-if="current_tab === 'declined'" class="tab-pane fade show active">
                <table id="workflows-table" class="table table-clear">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="text-uppercase text-xxs ">ID</th>
                            <th class="text-uppercase text-xxs ">NAME</th>
                            <th class="text-uppercase text-xxs text-start">DATE CREATED</th>
                            <th class="text-uppercase text-xxs text-start">WORKFLOW_STATUS</th>
                            <th class="text-uppercase text-xxs text-start">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(workflow, index) in filtered_data" :key="workflow.id">
                            <td class="text-xxs text-start">{{ index + 1 }}</td>
                            <td class="text-xxs ">{{ workflow.workflow_name }}</td>
                            <td class="text-xxs text-start">{{ workflow.date_created }}</td>
                            <td class="text-danger text-xxs ">
                                <span :class="['badge', 'shadow', workflow.is_active ? 'bg-success' : 'bg-danger']">{{ workflow.is_active ? 'Active' : 'Inactive' }}</span>
                            </td>
                            <td>
                                <button class="btn bg-green btn-sm text-xxs rounded-small"  @click="view_workflow_information(workflow.id)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Incomplete Tab -->
            <div v-if="current_tab === 'incomplete'" class="tab-pane fade show active">
                <table id="workflows-table" class="table table-clear">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th class="text-uppercase text-xxs ">ID</th>
                            <th class="text-uppercase text-xxs ">NAME</th>
                            <th class="text-uppercase text-xxs text-start">DATE CREATED</th>
                            <th class="text-uppercase text-xxs text-start">WORKFLOW_STATUS</th>
                            <th class="text-uppercase text-xxs text-start">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(workflow, index) in filtered_data" :key="workflow.id">
                            <td class="text-xxs text-start">{{ index + 1 }}</td>
                            <td class="text-xxs ">{{ workflow.workflow_name }}</td>
                            <td class="text-xxs text-start">{{ workflow.date_created }}</td>
                            <td class="text-danger text-xxs ">
                                <span :class="['badge', 'shadow', workflow.is_active ? 'bg-success' : 'bg-danger']">{{ workflow.is_active ? 'Active' : 'Inactive' }}</span>
                            </td>
                            <td>
                                <button class="btn bg-green btn-sm text-xxs rounded-small" @click="view_workflow_information(workflow.id)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </div>
  </div>