<div class="container py-4">
  <div class="row">
    <!-- QR Code Display -->
    <div v-if="qr_code_data && qr_code_data.link && qr_code_data.qr_code" class="col-lg-12 ms-0 mb-4">
      <div class="card h-80 rounded-small border-0 shadow-sm p-2">
        <div class="card-body row g-0 align-items-start">
          <div class="col-auto">
            <img class="mb-3" :src="qr_code_data.qr_code" width="180px" />
          </div>
          <div class="col mt-3">
            <div>
              <label><strong>Asset Name </strong></label>
              <span class="mb-2"> : {{ asset_data.asset_name }}</span>
            </div>
            <div>
              <label><strong>Assigned WorkFlow </strong></label>
              <span class="mb-3"> : {{ workflow_data.workflow_name }}</span>
            </div>
            <div>
              <label><strong>Link </strong></label>
              <a :href="qr_code_data.link" target="_blank" rel="noopener noreferrer">
                : {{ qr_code_data.link }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Asset Information Form -->
    <div class="col-lg-12 ms-0">
      <div class="card h-100 rounded-small shadow">
        <div class="card-body p-3 mb-5">
          <p class="text-uppercase text-xs text-blue fw-bold mt-4">Asset Information</p>
          <div class="underline rounded-small mt-1"></div>

          <!-- Begin Form -->
          <form @submit.prevent="edit_asset" class="position-relative">
         
            <div class="col-md-6">
              <!-- Asset Name -->
              <div class="mb-3">
                <label for="asset-name" class="form-label fw-bold text-xs">Name</label>
                <input
                  id="asset-name"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Enter asset name"
                  v-model="asset_details.asset_name"
                >
              </div>

              <!-- Description -->
              <div class="mb-3">
                <label for="asset-description" class="form-label fw-bold text-xs">Description</label>
                <input
                  id="asset-description"
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Enter description"
                  v-model="asset_details.asset_description"
                >
              </div>

              <!-- Asset Type -->
              <div class="mb-3">
                <label for="asset-type" class="form-label fw-bold text-xs">Asset Type</label>
                <input
                  id="asset-type"
                  disabled
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Enter asset type"
                  v-model="asset_details.asset_type"
                >
              </div>
              <div class="card-img-top">
                <div>
                <img v-if="imageUrl" :src="imageUrl" alt="Preview" class="img-fluid rounded" />
                <img v-else-if="asset_details.uploaded_image" :src="asset_details.uploaded_image" alt="Current image" class="img-fluid" />
                <div v-else class="placeholder">No image selected</div>
  
              </div>
              </div>
            </div>

        
        
            <div class="d-flex justify-content-between mt-4">
              
              <div>
                <button
                  type="button"
                  class="btn bg-green text-xs fw-bold rounded-small shadow"
                  @click="triggerFileUpload"
                >
                <i class="fa fa-upload "></i> Upload
                </button>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  @change="handle_file_upload"
                  ref="fileInput"
                  class="d-none text-xs upload-input"
                >
              </div>
            
              
              <div>
                <button 
                  type="submit" 
                  class="btn bg-green text-xs fw-bold rounded-small shadow"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        
        </div>
      </div>
    </div>
  </div>
</div>