import LivenessCheck from '@/components/Verification/liveness_check/LivenessCheck.vue';

export default {
  name: 'FacialBiometrics',
  data: () => ({
    client_id: '',
    photo_src: '',
  }),
  components: {
    LivenessCheck
  },
  methods: {
    get_id_number() {
      const TOKEN = localStorage.getItem('token');
      const GET_ID_URL = `${process.env.API_BASE_URL}/verify_api/verify_company_api/get_facial_biometric_information/`;
      $.ajax({
        url: GET_ID_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
          if (response.id_number_exists && response.user_profile_data && response.user_profile_data.length > 0) {
            var id_number = response.user_profile_data[0].id_number;
            this.client_id = id_number;
          } else {
            console.error('ID number not found in response');
          }
        },
        error: (error) => {
          console.error('Error retrieving ID number:', error);
        }
      });
    },
    set_photo_src(photo_src) {
      this.photo_src = photo_src;
      this.submit_photo();
    },
    start_liveness_check() {
      this.$refs.liveness_check.set_id_number(this.client_id);
    },
    submit_photo() {

      const TOKEN = localStorage.getItem("token");
      const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/facial_biometrics_liveness_verification/`;
      const DATA = {
        id_number: this.client_id,
        biometric: this.photo_src
      };
      $.ajax({
        url: SAFPS_URL,
        method: "POST",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          var status_message = response.status;
          var liveness_value = response.liveness;
          var message = response.message;

          this.$refs.liveness_check.close_modal();

          if (status_message === "success") {
            localStorage.setItem('user_state', 'verified');
            this.$router.push('/company_verification');
            
            return;
          } else if (
            status_message ===
            "Results not found please contact your service provider"
          ) {
            Swal.fire({
              icon: "error",
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            });
          }
          if (liveness_value === 'User is wearing a hat'){
            liveness_value = "Please remove your hat and try again"
          }
          if (liveness_value === 'User is wearing glasses'){
            liveness_value = "Please remove your glasses and try again"
          }

          if (liveness_value) {
            Swal.fire({
              icon: "error",
              title: liveness_value,
            }).then(() => {
              location.reload();
            });
          }

          if (message) {
            Swal.fire({
              icon: "error",
              title: message,
            }).then(() => {
              location.reload();
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = "Error retrieving data";

          if (error.responseJSON) {
            error_message = error.responseJSON.id_number[0];
            $(".error-message")
              .text(error_message)
              .removeClass("d-none");
            setTimeout(() => {
              $(".error-message").text("").addClass("d-none");
            }, 3000);
          }
        },
      });
    },
  },
  mounted() {
    this.get_id_number();
  }
};