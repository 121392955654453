import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "ViewSingleWorkflow",
  data() {
    return {
      workflow_data: null,
      view_service: false,
      view_terms: false,
      view_reasons: false,
    };
  },
  methods: {
    get_workflow_information() {
      var workflow_id = this.$route.params.workflow_id;
      const TOKEN = localStorage.getItem("token");
      const GET_WORKFLOW_INFORMATION_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_workflow_information_api/${workflow_id}`;

      $.ajax({
        url: GET_WORKFLOW_INFORMATION_API,
        method: "GET",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          this.workflow_data = response.data;
        },
        error: () => {
          Swal.close();
          Swal.fire({
            text: "Failed to fetch workflow information, try again later.",
            icon: "error",
          });
        },
      });
    },
    decline_workflow() {
      var workflow_id = this.$route.params.workflow_id;
      const TOKEN = localStorage.getItem("token");
      const DECLINE_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/decline_workflow_api/`;
      var data = {
        workflow_id: workflow_id,
      };

      Swal.fire({
        title: "Decline Workflow",
        input: "textarea",
        inputLabel: "Reason for Decline",
        inputPlaceholder: "Type your reason here...",
        inputAttributes: {
          "aria-label": "Type your reason here",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          var reason = result.value;
          data.reason = reason;

          $.ajax({
            url: DECLINE_WORKFLOW_API,
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            headers: {
              Authorization: `Token ${TOKEN}`,
            },
            beforeSend: () => {
              Swal.fire({
                text: "Declining workflow ...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            },
            success: () => {
              Swal.close();
              Swal.fire({
                text: "Workflow declined successfully.",
                icon: "success",
              });
              this.get_workflow_information();
            },
            error: () => {
              Swal.close();
              Swal.fire({
                text: "Failed to decline workflow, try again later.",
                icon: "error",
              });
            },
          });
        }
      });
    },
    approve_workflow() {
      var workflow_id = this.$route.params.workflow_id;
      const TOKEN = localStorage.getItem("token");
      const APPROVE_WORKFLOW_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/approve_workflow_api/`;
      var data = {
        workflow_id: workflow_id,
      };

      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to approve this workflow?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            url: APPROVE_WORKFLOW_API,
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify(data),
            headers: {
              Authorization: `Token ${TOKEN}`,
            },
            beforeSend: () => {
              Swal.fire({
                text: "Approving workflow ...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            },
            success: () => {
              Swal.close();
              Swal.fire({
                text: "Workflow approved successfully.",
                icon: "success",
              });
              this.get_workflow_information();
            },
            error: () => {
              Swal.close();
              Swal.fire({
                text: "Failed to approve workflow, try again later.",
                icon: "error",
              });
            },
          });
        }
      });
    },
    capitilize_string(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  mounted() {
    this.get_workflow_information();
  },
};
