<header>
  <nav class="navbar-landing">
    <img src="@/assets/images/Nutun_logo.png" alt="Logo" class="logo sidebar-logo img-fluid" />
  </nav>
</header>

<section class="hero-section-landing">
  <img src="@/assets/images/Nutun_landing.png" alt="Banner" class="hero-image" />
  <div class="hero-content">
    <h1 class="hero-title">PlaceHolder</h1>
    <button class="hero-button fw-bold" @click="next_to_register">Get Started</button>
  </div>
</section>