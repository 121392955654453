import NavBar from '@/components/NavBar/NavBar.vue';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";


export default {
    name: 'ManageForm',
    mixins: [LOGOUT],
    pageTitle: 'Manage Form', 
    components: {
        NavBar
    },
    
    data: () => ({
        question: '',
        question_type: '',
        questions: [],
        selected_questions: [],
        question_types: [],
        total_questions: 0,
        question_id: '',
        activeTab: 'form'
    }),
    methods: {
        showTab(tab) {
            this.activeTab = tab;
        },
      get_questions(){
        const TOKEN = localStorage.getItem('token');
        const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/question_management_api/get_questions_api/`;
        
        $.ajax({
          url: GET_QUESTIONS_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            this.loading = true;
          },
          success: (response) => {
            if (response.redirect){
            
              this.logout();
              return;
  
            }
            this.questions = response.questions || [];
            this.question_types = response.question_types || [];
            this.total_questions = response.total_questions;

            this.loading = false; // Set loading to false after data is loaded
            this.$nextTick(() => {
              this.initDataTable(); // Initialize DataTable after data is loaded
            });
            this.fetch_custom_service_question()
          },
          error: () => {
            this.loading = false; // Ensure loading is false even if there's an error
            let error_message = 'Error Getting questions';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });

      },

      fetch_custom_service_question(){
        const TOKEN = localStorage.getItem('token');

        const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/form_management_api/get_questions_for_service_api/${this.service_id}`;
        
        $.ajax({
          url: GET_QUESTIONS_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Getting selected service...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            const data = response.questions

          this.questions.forEach((question) => {
            const found = data.find(item => item.id === question.id);
            question.is_checked = !!found;
        });
          this.sortCheckboxes();
            this.loading = false;
            },
          error: () => {
            Swal.fire({
              text: 'An error occurred',
              icon: 'error'
              
            });
            Swal.close()
          }
        });
      },

      sortCheckboxes() {
        // Sort the services array
        this.selected_questions.sort((a, b) => {
          const aChecked = a.is_checked;
          const bChecked = b.is_checked;

          // Checked services should be on top
          if (aChecked && !bChecked) {
            return -1;
          } else if (!aChecked && bChecked) {
            return 1;
          } else {
            return 0;
          }
        });
      },

      assign_question(question_id, is_checked) {
        const TOKEN = localStorage.getItem('token');
        const UPDATE_QUESTION = `${process.env.API_BASE_URL}/verify_api/form_management_api/assign_question_to_service_api/`;
        const FORM_DATA = {
          question_id: question_id,
          service_id: this.service_id,
          is_checked: is_checked,
        };
        
        $.ajax({
          url: UPDATE_QUESTION,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(FORM_DATA),
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          success: () => {
          },
          error: () => {
            this.loading = false; // Ensure loading is false even if there's an error
            let error_message = 'Failed to assign question';
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error_message,
              confirmButtonText: 'OK'
            });
          }
        });
      },

      initDataTable() {
        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable('#question_table')) {
            $('#question_table').DataTable().destroy();
          }
          $('#question_table').DataTable({
            responsive: true
          });
        });

        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable('#file_question_table')) {
            $('#file_question_table').DataTable().destroy();
          }
          $('#file_question_table').DataTable({
            responsive: true
          });
        });
      },

    },
    computed: {
      check_question_type(){
        return this.question_type === 'Checkbox' || this.question_type === 'Selection';
      },

      service_id(){
        return this.$store.getters.get_service_id
      }

    },
    mounted() {
      if (!validateToken(this.$router)) {
        return;
    }
      this.get_questions();
    }
  }