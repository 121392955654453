import { validate_id } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";
import { validateToken } from '@/utils/auth';

export default {
  name: "FacialBiometricsVerification",
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo,
  },

  data: () => ({
    id_number: "",
    photo_taken: false,
    photo_src: "",
    video_stream: null,
    is_mobile_view: false,
  }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
  },

  methods: {
    show_instructions() {
      setTimeout(() => {
        $("#show_instruction").modal("show");
      }, 100);
    },
    initialize_camera() {
      if (this.video_stream) return;

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          const video = this.$refs.video;
          video.srcObject = stream;

          video.onloadedmetadata = () => {
            video.play();
          };

          this.video_stream = stream;
          this.toggle_photo_sections();
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    },
    take_photo() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.photo_taken = true;
      this.photo_src = canvas.toDataURL();
      if (this.is_mobile_view) {
        $("#take_photo").hide();
        $("#submit_photo").show();
        $(".retake-photo").removeClass("d-none");
      }
    },
    close_modal() {
      $("#show_instruction").modal("hide");
      $("#show_correct_image_instruction").modal("hide");
    },
    retake_photo() {
      this.photo_taken = false; // Reset photo_taken to allow taking a new photo
      this.photo_src = ""; // Clear the previous photo source

      // Optionally, you can show a message or instruction to the user here
      if (this.is_mobile_view) {
        $("#take_photo").show(); // Show the 'Take Photo' section
        $("#submit_photo").hide(); // Hide the 'Submit Photo' section
      }
    },
    submit_photo() {
      if (!this.id_number.trim()) {
        $(".id-message").removeClass("d-none");
        setTimeout(() => {
          $(".id-message").addClass("d-none");
        }, 3000);
        return false;
      }

      if (!validate_id(this.id_number)) {
        $(".invalid-message").removeClass("d-none");
        setTimeout(() => {
          $(".invalid-message").addClass("d-none");
        }, 3000);
        return false;
      }
      this.safps_verification();
    },
    safps_verification() {
      const TOKEN = localStorage.getItem("token");
      const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/facial_biometrics_verification/`;
      const DATA = {
        id_number: this.id_number,
        biometric: this.photo_src,
        workflow_id: this.workflow_id,
      };
      $.ajax({
        url: SAFPS_URL,
        method: "POST",
        contentType: "application/json",
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        data: JSON.stringify(DATA),
        beforeSend: () => {
          Swal.fire({
            text: "Loading ...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();
          const status_message = response.status;
          const liveness_value = response.liveness;
          const message = response.message;
          const user_role = response["role"];

          if (status_message === "success") {
            if (user_role === "Director") {
              this.$router.push("/upload_proof_of_address");
            } else {
              this.fetch_current_service();
            }
          } else if (
            status_message ===
            "Results not found please contact your service provider"
          ) {
            Swal.fire({
              icon: "error",
              text: "Our service is currently unavailable. Please contact your service provider and try again later",
            });
          }

          if (liveness_value) {
            Swal.fire({
              icon: "error",
              title: liveness_value,
            });
          }

          if (message) {
            Swal.fire({
              icon: "error",
              title: message,
            }).then(() => {
              location.reload();
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = "Error retrieving data";

          if (error.responseJSON) {
            error_message = error.responseJSON.id_number[0];
            $(".error-message")
              .text(error_message)
              .removeClass("d-none");
            setTimeout(() => {
              $(".error-message").text("").addClass("d-none");
            }, 3000);
          }
        },
      });
    },
    stop_camera() {
      if (this.video_stream) {
        const tracks = this.video_stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });

        this.$refs.video.srcObject = null;
        this.video_stream = null;
      }
    },
    toggle_photo_sections() {
      this.is_mobile_view = window.innerWidth <= 768;
      if (this.is_mobile_view) {
        $("#take_photo").show();
        $("#submit_photo").hide();
      } else {
        $("#take_photo").show();
        $("#submit_photo").show();
      }
    },
    handle_window_resize() {
      this.toggle_photo_sections();
    },
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    const shown_instructions = localStorage.getItem("shown_instructions");
    if (!shown_instructions) {
      setTimeout(() => {
        $("#show_instruction").modal("show");
      }, 100);
      localStorage.setItem("shown_instructions", "true");
    }

    if (shown_instructions) {
      this.initialize_camera();
    } else {
      $("#show_instruction").on("hidden.bs.modal", () => {
        this.initialize_camera();
        window.addEventListener("resize", this.handle_window_resize);
      });
    }
  },
  beforeDestroy() {
    this.stop_camera();
    window.removeEventListener("resize", this.handle_window_resize);
  },
  beforeRouteLeave(to, from, next) {
    this.stop_camera();
    next();
  },
};
