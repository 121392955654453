import { validateToken } from '@/utils/auth';

export default {
    name: 'RefugeeVerification',
    data: () => ({ 
      refugee_number: '',
      error: '',
      json_data: {}
  }),
    methods: {
      refugee_verification_api() {
        const TOKEN = localStorage.getItem('token');
        const REFUGEE_VERIFICATION_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/verify_refugee/`;
  
        this.error = '';
  
        $.ajax({
          url: REFUGEE_VERIFICATION_API,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify({
            refugee_number: this.refugee_number
          }),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => Swal.showLoading(),
            });
          },
          success: (response) => {
            Swal.close();
            Swal.fire({
            icon: 'success',
            title: 'Successful',
            confirmButtonText: 'OK'
            })
            this.json_data = response.result;
            $('#verification_results').modal('show');
          },
          error: (error) => {
            Swal.close();
            let error_message = 'Something went wrong!';
  
            if (error.responseJSON && error.responseJSON.message) {
              error_message = error.responseJSON.message;
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
          }else {
              $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                      $('.error-message').text('').addClass('d-none');
                  }, 3000);
            }
          }
        });
      },
    },

    mounted() {
      if (!validateToken(this.$router)) {
        return;
      }
    },
  };