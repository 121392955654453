import NavBar from '@/components/NavBar/NavBar.vue';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";


export default {
  name: 'QuestionManagement',
  mixins: [LOGOUT],
  pageTitle: 'Question Management',
  components: {
    NavBar
  },
  data: () => ({
    create_question_data: { question: '', question_number: '', question_type: '', mandatory: '', options: [], number_of_options: 0},

    edit_question_data: { question: '', question_number: '', question_type: '', mandatory: '', options: [] },

    questions: [],
    question_types: [],
    total_questions: 0,
    question_id: '',
    question_status: '',
    loading: false
  }),
  methods: {
    validation_message_list(document_id, value) {
      if (value.includes('')) {
        $(document_id).removeClass('d-none');
        return false;
      }
      else {
        $(document_id).addClass('d-none');
        return true;
      }
    },
    validation_message(document_id, value) {
      if (value == '') {
        $(document_id).removeClass('d-none');
        return false;
      }
      else {
        $(document_id).addClass('d-none');
        return true;      
      }
    },
    create_question() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const ADD_QUESTION_URL = `${BASE_URL}/verify_api/question_management_api/save_question_api/`;
      
      let options = this.create_question_data.options;
      if (this.create_question_data.question_type !== 'Selection' && this.create_question_data.question_type !== 'Checkbox' && this.create_question_data.question_type !== 'Multi Selection') {
          options = [];  // Clear options if not relevant for the question type
      }

      const validations = [
        this.validation_message('#question-error', this.create_question_data.question),
        this.validation_message('#question-number-error', this.create_question_data.question_number),
        this.validation_message('#question-type-error', this.create_question_data.question_type),
        this.validation_message('#mandatory-field-error', this.create_question_data.mandatory)
      ];

      if (this.create_question_data.question_type == 'Selection' || this.create_question_data.question_type == 'Checkbox' || this.create_question_data.question_type == 'Multi Selection') {
        validations.push(this.validation_message_list('#option-field-error', this.create_question_data.options));
      }

      if (validations.includes(false)) {
        return;
      }

      const FORM_DATA = {
        question: this.create_question_data.question,
        question_number: this.create_question_data.question_number,
        question_type: this.create_question_data.question_type,
        mandatory: this.create_question_data.mandatory,
        options: options
      };

      $.ajax({
        method: "POST",
        url: ADD_QUESTION_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`

        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            showConfirmButton: false,  // Hide the confirm button
            timer: 3000
          }).then(() => {
            window.location.reload();
          });
        },
        error: (error) => {
          Swal.close();
          let error_message = 'An error occurred while creating the question.';
          
          if (error.responseJSON && error.responseJSON.error) {
            error_message = error.responseJSON.error;
          }

          this.showErrorMessage(error_message);
        }
      });
    },
    showErrorMessage(message) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        confirmButtonText: 'OK'
      });
    },
    get_questions(){
      const TOKEN = localStorage.getItem('token');
      const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/question_management_api/get_questions_api/`;

      $.ajax({
        url: GET_QUESTIONS_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {

          if (response.redirect){
            
            this.logout();
            return;

          }
          // Filter to exclude follow-up questions
          this.questions = (response.questions).filter(
            (question) => !question.is_follow_up_question
          );
          this.question_types = response.question_types || [];
          this.total_questions = response.total_questions;

          this.loading = false; // Set loading to false after data is loaded
          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          });
        },
        error: () => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = 'Error Getting questions';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#question_table')) {
          $('#question_table').DataTable().destroy();
        }
        $('#question_table').DataTable({
          responsive: true
        });
      });
    },
    edit_question(question_id) {
      const question = this.questions.find(ques => ques.id === question_id);
      if (question) {
        this.selected_question_id = question_id; // Set the selected project ID here
        this.edit_question_data.question = question.question;
        this.edit_question_data.question_number = question.number;
        this.edit_question_data.question_type = question.question_type;
        this.edit_question_data.mandatory = question.is_required;
        this.edit_question_data.options = question.options.map(option => option.option)

        if (this.edit_question_data.question_type == 'Selection' || this.edit_question_data.question_type == 'Checkbox' || this.edit_question_data.question_type == 'Multi Selection') {
            this.edit_question_data.number_of_options = this.edit_question_data.options.length;
        }

        this.$nextTick(() => {
          $('#edit_question_modal').modal('show');
        });
      }
    },
    edit_validate_number_of_options() {
      if (this.edit_question_data.number_of_options > 25) {
        this.edit_question_data.number_of_options = 25;
        Swal.fire({
          title: "Too Many Options!",
          text: "You cannot enter more than 25 options.",
          icon: 'error',
          timer: 2000,
          showConfirmButton: true
        });
      }
      // Update the options array dynamically
      this.edit_options_array();
    },
    edit_options_array() {
      if(this.edit_question_data.number_of_options > 0) {
        // If the number of options is reduced, remove excess options
        if (this.edit_question_data.number_of_options < this.edit_question_data.options.length) {
          this.edit_question_data.options.splice(this.edit_question_data.number_of_options);
        }
        // If the number of options is increased, add empty slots
        while (this.edit_question_data.options.length < this.edit_question_data.number_of_options) {
          this.edit_question_data.options.push('');
        }
      }
    },
    update_question(){
      const TOKEN = localStorage.getItem('token');
      const EDIT_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/update_question_api/`;

      const FORM_DATA = {
        question_id: this.selected_question_id, // Set the selected project ID here
        question: this.edit_question_data.question,
        question_number: this.edit_question_data.question_number,
        question_type: this.edit_question_data.question_type,
        mandatory: this.edit_question_data.mandatory,
        options: this.edit_question_data.options
      };

      $.ajax({
        url: EDIT_QUESTION_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating question...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            text: 'Question updated successfully',
            showConfirmButton: false,
            timer: 2000
          });
          $('#edit_question_modal').modal('hide');
          this.get_questions(); // Refresh the questions list
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
    validate_number_of_options() {
      if (this.create_question_data.number_of_options > 25) {
        this.create_question_data.number_of_options = 25;
        Swal.fire({
          title: "Too Many Options!",
          text: "You cannot enter more than 25 options.",
          icon: 'error',
          timer: 2000,
          showConfirmButton: true
        });
      }
      // Update the options array dynamically
      this.update_options_array();
    },
    update_options_array() {
      // If the number of options is reduced, remove excess options
      if (this.create_question_data.number_of_options < this.create_question_data.options.length) {
        this.create_question_data.options.splice(this.create_question_data.number_of_options);
      }
      // If the number of options is increased, add empty slots
      while (this.create_question_data.options.length < this.create_question_data.number_of_options) {
        this.create_question_data.options.push('');
      }
    },
    deactivate_question(question_id){
      const TOKEN = localStorage.getItem('token');
      const DEACTIVATE_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/change_question_status_api/`;

      const FORM_DATA = {
        question_id: question_id, // Set the selected project ID here
        question_status: 'inactive'
      };

      $.ajax({
        url: DEACTIVATE_QUESTION_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating question status...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          this.get_questions(); // Refresh the questions list
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
    activate_question(question_id){
      const TOKEN = localStorage.getItem('token');
      const ACTIVATE_QUESTION_API_URL = `${process.env.API_BASE_URL}/verify_api/question_management_api/change_question_status_api/`;

      const FORM_DATA = {
        question_id: question_id, // Set the selected project ID here
        question_status: 'active'
      };

      $.ajax({
        url: ACTIVATE_QUESTION_API_URL,
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(FORM_DATA),
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Updating question status...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: () => {
          Swal.close();
          this.get_questions(); // Refresh the questions list
        },
        error: () => {
          Swal.fire({
            text: 'An error occurred',
            icon: 'error'
          });
          Swal.close();
        }
      });
    },
    redirect_to_conditional_questions(question_id) {
      this.$router.push({ 
          name: 'conditional_questions',
          params : { question_id }
      });
    }
  },
  
  computed: {
    check_question_type(){
      return this.create_question_data.question_type === 'Checkbox' || this.create_question_data.question_type === 'Selection' || this.create_question_data.question_type === 'Multi Selection';
    },
    check_edit_question_type() {
      return this.edit_question_data.question_type === 'Checkbox' || this.edit_question_data.question_type === 'Selection' || this.edit_question_data.question_type === 'Multi Selection';
    }
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
  }
    this.get_questions();
  }
};