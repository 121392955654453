import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
  name: 'ServiceApplications',
  components: {
    AdminNavBar,
    AdminSidebar
  },
  data: () => ({
    currentTab: 'Pending',
    requested_clients:[]

  }),
  computed: {
    filteredData() {
      return this.data[this.currentTab];
    },
    client_id() {
      return this.$store.getters.get_client_id;
    }
  },
  methods: {
    switchTab(tab) {
      this.currentTab = tab;
      this.$nextTick(() => {
        this.initDataTable();
      });
    },
    get_clients(){
      const TOKEN = localStorage.getItem('token');
      const GET_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/get_instant_verify_applications/`;

      $.ajax({
        url: GET_SELECTED_SERVICES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.requested_clients = response.data;
          this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable('#applications-table')) {
            $('#applications-table').DataTable().destroy();
          }
          $('#applications-table').DataTable({
            responsive: true
          });
         });

        },
        error: (error) => {
          Swal.close();
          let error_message = 'Failed to get selected services';
          if (error.responseJSON) {
            const ERROR_RESPONSE = error.responseJSON;
            if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
              error_message = ERROR_RESPONSE.error;
            }
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    view_client_logs(client_id){
      this.$router.push({ name: 'view_logs'});
      this.$store.commit('set_client_id', client_id);
    },
    view_client_services(client_id){
      this.$router.push({ name: 'view_requested_services'});
      this.$store.commit('set_client_id', client_id);
    },
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
  }
    this.get_clients();
  }
};