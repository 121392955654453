import Swal from 'sweetalert2';
import { validate_id, allow_num } from "@/utils/validations";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from '@/components/BreadCrumb/BreadCrumb.vue';
import ClientLogo from '@/components/ClientLogo/ClientLogo.vue';

export default {
  name: 'CreditReport',
  mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
  components: {
    BreadCrumb,
    ClientLogo
  },

  data: () => ({ id_number: '', loading: false, error: '' }),

  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },

  methods: {
    allow_num(event) {
      this.id_number = allow_num(event);
    },
    handle_id_input(id_number) {
      this.id_number = id_number;
    },
    submit_id() {
      if (!this.id_number.trim()) {
        $('.id-message').removeClass('d-none');
        setTimeout(() => {
          $('.id-message').addClass('d-none');
        }, 3000);
        return false;
      }

      if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
          $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
      }
      this.credit_report();
    },

    credit_report() {
      const TOKEN = localStorage.getItem('token');
      const CREDIT_REPORT_BASE64_API = `${process.env.API_BASE_URL}/credit_verification/credit_report_b64/?workflow_id=${this.workflow_id}`;

      this.loading = true;
      this.error = '';

      $.ajax({
        url: CREDIT_REPORT_BASE64_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          id_number: this.id_number,
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
          });
        },
        success: () => {
          Swal.close();
          this.fetch_current_service();
        },
        error: (xhr) => {
          this.loading = false;
          let response = xhr.responseJSON;
          if (response && response.api_error_message) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: response.api_error_message,
            });
          } else {
            this.loading = false;
            this.error = 'An error occurred while retrieving customer credit report.';
          }
        }
      });
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.get_customer_data();
  }
};