
<h5 class="text-s text-center"><strong>Anti Money Laundering</strong></h5>
<div class="row mt-2 mb-2">
    <div class="col-md-6 text-start">
    <label class="form-label text-xs">First Name <span class="text-danger fw-bold">*</span></label>
    <input v-model="first_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. Joe">
    </div>
    <div class="col-md-6 text-start">
    <label class="form-label text-xs">Last Name <span class="text-danger fw-bold">*</span></label>
    <input v-model="last_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. Doe">
    </div>
</div>
<div class="row mt-2 mb-2">
    <div class="col-md-6 text-start">
        <label class="form-label text-xs">Date of Birth <span class="text-danger fw-bold">*</span></label>
        <input v-model="date_of_birth" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="YYyymmdd">
    </div>
    <div class="col-md-6 text-start">
        <label class="form-label text-xs">Gender <span class="text-danger fw-bold">*</span></label>
        <select 
          v-model="gender" 
          class="form-select form-select-sm text-xxs rounded-small shadow-sm w-100" 
          id="gender" 
          required
        >
          <option value="" disabled hidden>Choose Gender</option>
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
    </div>
</div>
<div class="form-group mb-4 w-75">
    <div class=" text-start">
        <label class="form-label text-xs">Country Code <span class="text-danger fw-bold">*</span></label>
        <input v-model="country_code" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. ZAF">
    </div>
</div>
<div class="col text-end">
    <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="anti_money_laundering_application_api">Submit</button>
</div>

<div class="modal" tabindex="-1" id="verification_results">
<div class="modal-dialog modal-fullscreen">
<div class="modal-content">
<div class="modal-header">
  <h5 class="modal-title">Verification Result</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div v-if="Object.keys(json_data).length > 0" class="card card-border-decoration border-3">
        <h5 class="text-s text-start"><strong>Verification Result</strong></h5>
        <div v-if="nm_results && nm_results.length">
            <table class="table table-striped table-bordered table-hover w-100 mt-3 mb-5" id="verification_result">
                <thead class="text-dark ">
                    <tr>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Product
                            ID
                        </th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Score
                        </th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Source
                            ID
                        </th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Category
                        </th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Country
                        </th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">
                            Sentiment
                            Confidence Level</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Matched
                            Subject</th>
                        <th class="bg-gray text-uppercase text-xxs font-weight-bolder opacity-7 text-start">
                            Highlights
                            &
                            URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in nm_results" :key="result.resultId">
                        <td class="text-xxs text-start">{{ result.productId }}</td>
                        <td class="text-xxs text-start">{{ result.score }}</td>
                        <td class="text-xxs text-start">{{ result.sourceId }}</td>
                        <td class="text-xxs text-start">{{ result.category }}</td>
                        <td class="text-xxs text-start">{{ result.sourceCountry }}</td>
                        <td class="text-xxs text-start">{{ result.sentimentConfidenceLevel }}</td>
                        <td class="text-xxs text-start">{{ result.subjectMatched }}</td>
                        <td class="text-xxs text-start">{{ result.highlights[0] }}
                            <p>{{ result.url }}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else>
        <div class="tab-pane fade show active fw-bold text-xs mb-4">
            <pre>{{ formatted_data }}</pre>
        </div>
    </div>
</div>
<div class="modal-footer">
    <router-link :to="{ name: 'services' }">
        <button class="btn bg-green btn-sm text-xs text-end  rounded-small mt-3" data-bs-dismiss="modal">Back</button>
    </router-link>
</div>
</div>
</div>
</div>