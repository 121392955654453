
<div class="text-center mt-1">
    <nav aria-label="breadcrumb" class="col-12 col-md-8 col-lg-6 mx-auto">
        <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
            <li class="breadcrumb-item active text-white" aria-current="page">
                {{ label }}
            </li>
        </ol>
    </nav>
</div>
