import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";

export default {
  name: 'CreateAsset',
  mixins: [LOGOUT],
  components: {
    ClientSidebar,
    NavBar
  },
  data() {
    return {
      data_table: null,
      assets: [],
      asset_name: '',
      asset_description: '',
      asset_type: '',
      asset_types: [],
      active_workflows: [],
      asset_file: null,
      image_url: null,
      selected_asset_id: null,
      selected_workflow_id: null,
      created_asset_id: null,
      is_assigned: false
    };
  },
  methods: {
    initDataTable() {
      const table = $('#assets_table');
      if ($.fn.DataTable.isDataTable(table)) {
        table.DataTable().destroy(); // Destroy the existing instance
      }
      table.DataTable({
        paging: true,
        searching: true,
        ordering: true,
      });
    },

    fetch_assets() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSETS_URL = `${BASE_URL}/verify_api/workflow_management_api/get_assets/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSETS_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          if (response.redirect) {

            this.logout();
            return;

          }
          this.assets = response.map(asset => ({
            ...asset,
            is_assigned: asset.is_assigned || false
          }));
          this.$nextTick(() => {
            this.initDataTable();
          });
          Swal.close();
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'Failed to load assets.',
            confirmButtonText: 'OK'
          });
        },
      });
    },

    fetch_asset_types() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSETS_TYPES_URL = `${BASE_URL}/verify_api/workflow_management_api/get_asset_types/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSETS_TYPES_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.asset_types = response.asset_types;
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'An error occurred.',
          });
        }
      });
    },

    get_active_worfklows() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSETS_TYPES_URL = `${BASE_URL}/verify_api/workflow_management_api/get_active_workflows_api/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSETS_TYPES_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.active_workflows = response.active_workflows;
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'An error occurred.',
          });
        }
      });
    },

    open_assign_modal(asset_id) {
      this.selected_asset_id = asset_id;
      this.selected_workflow_id = '';
    },

    assign_to_workflow(asset_id, workflow_id) {
      // Find the asset name by matching the asset_id
      const asset = this.assets.find(a => a.id === asset_id);
      const workflow = this.active_workflows.find(w => w.id === workflow_id);

      // Ensure both asset and workflow are found before showing the confirmation dialog
      if (asset && workflow) {
        // Show confirmation dialog with asset name and workflow name
        Swal.fire({
          title: `Are you sure you want to assign the asset: ${asset.asset_name} to the workflow: ${workflow.workflow_name}?`,
          text: 'Changes cannot be reverted.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Assign it',
          cancelButtonText: 'No, Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            // Proceed with assigning the asset to the workflow
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;
            const ASSIGN_ASSET_TO_WORKFLOW_URL = `${BASE_URL}/verify_api/workflow_management_api/assign_asset_to_workflow_api/`;
            const FORM_DATA = {
              asset_id: asset_id,
              workflow_id: workflow_id
            };

            $.ajax({
              method: 'POST',
              url: ASSIGN_ASSET_TO_WORKFLOW_URL,
              data: JSON.stringify(FORM_DATA),
              contentType: 'application/json',
              headers: {
                Authorization: `Token ${TOKEN}`,
              },
              beforeSend: () => {
                Swal.fire({
                  text: 'Loading ...',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
              },
              success: (response) => {
                Swal.close();
                if (response.is_assigned) {
                  this.fetch_assets();
                  Swal.fire({
                    icon: 'success',
                    text: 'Asset assigned to workflow successfully',
                    confirmButtonColor: '#59FF74',
                    showConfirmButton: false,
                    timer: 3000
                  }).then(() => {
                    // Find the asset in the assets array and update its 'is_assigned' status
                    const asset = this.assets.find(a => a.id === asset_id);
                    if (asset) {
                      asset.is_assigned = true;
                    }

                    $('#assign_asset_to_workflow_modal').modal('hide');
                  });
                } else {
                  Swal.fire({
                    icon: 'warning',
                    text: 'Asset already assigned to the selected workflow',
                    confirmButtonColor: '#FFB600',
                    showConfirmButton: true
                  });
                }
              },
              error: (error) => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: error.responseJSON.message,
                  confirmButtonColor: '#59FF74',
                  showConfirmButton: true
                });
              },
            });
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Asset or workflow not found.',
        });
      }
    },

    trigger_file_upload() {
      this.$refs.fileInput.click();
    },

    handle_file_upload(event) {
      const file = event.target.files[0];
    
      // Validate file type
      if (file && !file.type.startsWith('image/')) {
        Swal.fire({
          icon: 'error',
          text: 'Please select a valid image file.',
        });
        return;
      }
    
      // Validate file size (e.g., max 5MB)
      const max_size = 5 * 1024 * 1024; // 5MB
      if (file && file.size > max_size) {
        Swal.fire({
          icon: 'error',
          text: 'File size exceeds 5MB limit.',
        });
        return;
      }
    
      this.asset_file = file;
    
      // Handle image preview
      if (this.asset_file && this.asset_file.type.startsWith('image/')) {
        // Revoke existing object URL if any
        if (this.image_url && this.image_url.startsWith('blob:')) {
          URL.revokeObjectURL(this.image_url);
        }
        // Create a new object URL for the uploaded image
        this.image_url = URL.createObjectURL(this.asset_file);
      } else {
        // If not an image file, reset the image_url and asset_file
        if (this.image_url && this.image_url.startsWith('blob:')) {
          URL.revokeObjectURL(this.image_url);
        }
        this.image_url = null;
        this.asset_file = null;
      }
    },

    create_asset() {
      if (!this.asset_name || !this.asset_description || !this.asset_type) {
        Swal.fire({
          icon: 'error',
          text: 'All fields are required',
        });
        return;
      }

      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const CREATE_ASSET_URL = `${BASE_URL}/verify_api/workflow_management_api/create_asset_api/`;

      const form_data = new FormData();
      form_data.append('asset_name', this.asset_name);
      form_data.append('asset_description', this.asset_description);
      form_data.append('asset_type', this.asset_type);
      form_data.append('asset_file', this.asset_file);

      $.ajax({
        method: 'POST',
        url: CREATE_ASSET_URL,
        data: form_data,
        contentType: false,
        processData: false,
        headers: {
          Authorization: `Token ${TOKEN}`,

        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.message,
            showConfirmButton: false,  // Hide the confirm button
            timer: 3000
          }).then(() => {
            window.location.reload();
          })
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'An error occurred.',
          });
        },
      });
    },

    view_asset(asset_id) {
      this.$router.push({ name: 'view_asset', params: { id: asset_id } });
    }
  },

  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    this.fetch_assets();
    this.fetch_asset_types();
    this.get_active_worfklows();
  },

  before_destroy() {
    const table = $('#assets_table');
    if ($.fn.DataTable.isDataTable(table)) {
      table.DataTable().destroy(); // Destroy the instance before the component is destroyed
    }
    // Revoke the object URL when component is destroyed
    if (this.image_url && this.image_url.startsWith('blob:')) {
      URL.revokeObjectURL(this.image_url);
    }
  },
};