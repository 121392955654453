<div class="container-fluid">
  <nav class="navbar bg-white mt-2 rounded-3">
    <div class="d-flex align-items-center ms-3 flex-grow-1">
      <div v-if="show_logout_icon()">
        <a href="#" @click="route_to">
          <i class="fas fa-sign-out-alt fa-lg green-color p-0"></i>
        </a>
      </div>

      <div class="d-flex align-items-center">
        <i
          v-if="hide_burger_icon()"
          class="fa-solid fa-bars fa-xl me-3 fs-6"
          type="button"
          @click="toggle_sidebar"
        >
        </i>
        <div v-if="$route.name === 'customer_profile'">
          <span
            class="back-label text-xs text-dark rounded-pill me-2 p-2"
            @click="go_back_to_assigned_workflows"
          >
            Back to Assigned Workflows
          </span>
        </div>
        <div v-if="role === 'admin'">
          <span class="fw-normal text-xs">Dashboard</span>/<span
            class="fw-bold text-xs"
            >Admin</span
          >
        </div>
        <div v-if="role === 'client'">
          <i
            v-if="hide_back_icon()"
            type="button"
            class="fa-solid fa-left-long fa-l green-color me-2"
            @click="go_back"
          >
          </i>
        </div>
        <div v-if="user_info.user_role == 'SystemAdmin'">
          <span class="fw-normal text-xs" v-if="!admin_back_to_workflows()">
            Dashboard
          </span>
          <span
            v-if="admin_back_to_workflows()"
            class="back-label text-xs text-dark rounded-pill me-1"
            @click="admin_go_back_to_workflows"
          >
            Back to Workflows
          </span>

          /<span class="fw-bold text-xs"> Admin </span>
        </div>
        <div
          v-if="user_info.user_role == 'ClientAdmin' || user_info.user_role == 'Client'"
        >
          <span
            v-if="!show_back_to_workflows() && !show_back_to_services() && hide_back_icon()"
            class="back-label text-xs text-dark rounded-pill me-1"
            @click="go_back"
          >
            Back to Projects
          </span>
          <span
            v-if="show_back_to_workflows()"
            class="back-label text-xs text-dark rounded-pill me-1"
            @click="go_back_to_workflows"
          >
            Back to Workflows
          </span>
          <span
            v-if="show_back_to_services()"
            class="back-label text-xs text-dark rounded-pill me-1"
            @click="go_back_to_services"
          >
            Back to Services
          </span>
          <!-- Display the slash and page title on the same line -->
          <span v-if="hide_back_icon()" class="text-xs text-dark">/</span>
          <!-- Page title next to the slash -->
          <span v-if="page_title" class="page-title text-xs fw-bold ms-1"
            >{{ page_title }}</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center mt-2 mb-2">
      <div class="search-bar me-4">
        <span
          class="input-group-text border-0 bg-transparent"
          id="search-addon"
        >
          <i class="fas fa-search"></i>
        </span>
        <input
          class="search-bar border-0 text-xs"
          type="text"
          placeholder="Search..."
        />
      </div>
      <span
        class="user-icon rounded-circle bg-green secondary-text-dark d-flex justify-content-center align-items-center fw-bold custom-size"
        @click="redirect_to_customer_profile"
      >
        {{ get_initial(user_info.first_name) }}
      </span>
      <span class="user-name text-xs ms-2 me-3"
        >{{ user_info.first_name }} {{ user_info.last_name }}</span
      >
    </div>
  </nav>
</div>
