<div class="container-fluid">
  <div class="card view-card border-0 mt-5 rounded-3 shadow p-2">
    <ApplicationComponent />
    <div class="text-end mt-4">
      <button
        class="btn btn-danger btn-sm text-xxs fw-bold rounded-small me-2"
        @click.prevent="deactivate_application"
      >
        Deactivate
      </button>
    </div>
  </div>
</div>
