import { LOGOUT } from "@/utils/logout.js";

export default {
  name: 'ThankYouPage',
  mixins: [LOGOUT],
  methods: {
    redirect_to_dashboard(){
      this.logout();
    }
  },
};
