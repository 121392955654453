<div class="container-fluid bg-white vh-100 p-0">
  <!-- Client Logo Component -->
  <ClientLogo />

  <!-- Breadcrumb Component -->
  <BreadCrumb label="Anti Money Laundering" />

  <!-- Form Container -->
  <div>
    <div class="d-flex justify-content-center align-items-center min-vh-50">
      <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
        <!-- Error Message Display -->
        <div v-if="error" class="alert alert-danger text-center">
          {{ error }}
        </div>

        <!-- AML Verification Form -->
        <form @submit.prevent="anti_money_laundering_application_api">
          <!-- First Name Field -->
          <div class="form-group mt-3">
            <label for="firstName" class="fw-bold text-xs text-start d-block">
              First Name <span class="text-danger">*</span>
            </label>
            <input
              v-model="first_name"
              type="text"
              class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              id="firstName"
              placeholder="e.g., Joe"
              @input="validate_first_name"
              required
              :disabled="isFieldDisabled.first_name"
            />
            <small v-if="error_messages.first_name" class="form-text text-danger">
              {{ error_messages.first_name }}
            </small>
          </div>

          <!-- Last Name Field -->
          <div class="form-group mt-3">
            <label for="lastName" class="fw-bold text-xs text-start d-block">
              Last Name <span class="text-danger">*</span>
            </label>
            <input
              v-model="last_name"
              type="text"
              class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              id="lastName"
              placeholder="e.g., Doe"
              @input="validate_last_name"
              required
              :disabled="isFieldDisabled.last_name"
            />
            <small v-if="error_messages.last_name" class="form-text text-danger">
              {{ error_messages.last_name }}
            </small>
          </div>

          <!-- Date of Birth Field -->
          <div class="form-group mt-3">
            <label for="dateOfBirth" class="fw-bold text-xs text-start d-block">
              Date of Birth <span class="text-danger">*</span>
            </label>
            <input
              v-model="date_of_birth"
              type="text"
              class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              id="dateOfBirth"
              placeholder="YYYYMMDD"
              @input="date_of_birth_validation"
              required
              :disabled="isFieldDisabled.date_of_birth"
            />
            <small class="form-text text-danger d-none text-xxs fw-bold invalid-dob">
              Invalid Date of Birth. Please enter a valid date in YYYYMMDD format.
            </small>
            <small v-if="error_messages.date_of_birth" class="form-text text-danger">
              {{ error_messages.date_of_birth }}
            </small>
          </div>

          <!-- Gender Field -->
          <div class="form-group mt-3">
            <label class="fw-bold text-xs text-start d-block">
              Gender <span class="text-danger fw-bold">*</span>
            </label>
            <select
              v-model="gender"
              class="form-select form-select-sm mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small shadow-sm"
              id="gender"
              required
              :disabled="isFieldDisabled.gender"
            >
              <option value="" disabled hidden>Choose Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Other</option>
            </select>
            <small v-if="error_messages.gender" class="form-text text-danger">
              {{ error_messages.gender }}
            </small>
          </div>

          <!-- Country Code Field -->
          <div class="form-group mt-3">
            <label for="countryCode" class="fw-bold text-xs text-start d-block">
              Country Code <span class="text-danger">*</span>
            </label>
            <input
              v-model="country_code"
              type="text"
              class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
              id="countryCode"
              placeholder="e.g., ZAF"
              @input="countries_code"
              required
              :disabled="isFieldDisabled.country_code"
            />
            <small v-if="error_messages.country_code" class="form-text text-danger">
              {{ error_messages.country_code }}
            </small>
          </div>

          <!-- Submit Button -->
          <div class="text-center mb-3 mt-4">
            <button
              type="submit"
              class="btn bg-green btn-sm px-4 rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>