import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validateToken } from '@/utils/auth';

export default {
  name: 'AdminDashboard',

  components: {
    NavBar,
    AdminSidebar,
  },

  data: () => ({ applications: 0, client: 0, client_admin: 0, admins: 0 }),
  methods: {
    get_admin_data() {
      const TOKEN = localStorage.getItem('token');
      const GET_ADIMN_DASHBOARD_DATA_API = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/admin_dashboard_api/`;

      $.ajax({
        url: GET_ADIMN_DASHBOARD_DATA_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
        },
        success: (response) => {
          Swal.close();

          // Update the component's data based on the response
          let users = response.users
          users.forEach(item => {
            if (item.role === 'Client') {
              this.client = item.user_count;
            } else if (item.role === 'SystemAdmin') {
              this.admins = item.user_count;
            } else if (item.role === 'ClientAdmin') {
              this.client_admin = item.user_count; // Assuming 'Customer' represents 'Client Admin'
            }
          });
          this.applications = response.applications

        },
        error: () => {
          Swal.close();
        }
      });
    },
  },

  mounted() {
    if (!validateToken(this.$router)) {
        return;
    }
    this.get_admin_data();
  }
};
