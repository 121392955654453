import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {

    name: 'UserOtp',
    mixins: [MAIN_CONTROLLER],
    components: {
        GetQuestions,
    },
    data: () => ({
        otp: '',
        responses: [],
        client_admin_id: sessionStorage.getItem('client_admin_id'),
        show_resend_message: false,
        failed_resend_message: false
    }),
    mounted() {
        const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
        if (GET_CLIENT_ID == "Customer") {
            this.store_responses();
        }
        this.send_otp();
    },
    methods: {
        store_responses(dynamic_fields = [], follow_up_responses = []) {
            this.responses = dynamic_fields;
            this.follow_up_responses = follow_up_responses;
        },
        // Method to handle redirects based on registration status
        handleRegistrationRedirect(response) {
            const role = response.role.toUpperCase();
            const status = response.status;


            switch (role) {
                case 'CUSTOMER': {

                    const workflow_id = response.workflow_id


                    if (workflow_id !== null) {
                        const TOKEN = localStorage.getItem('token');
                        const BASE_URL = process.env.API_BASE_URL;
                        const CHECK_TERMS_URL = `${BASE_URL}/verify_api/customer_management_api/check_terms_and_conditions_confirmation/${workflow_id}`;


                        $.ajax({
                            method: "GET",
                            url: CHECK_TERMS_URL,
                            contentType: 'application/json',
                            headers: {
                                Authorization: `Token ${TOKEN}`
                            },
                            success: (response) => {
                                this.$store.dispatch('update_workflow_id', workflow_id);
                                if (response.status === 'Success' && response.is_confirmed === true) {
                                    this.fetch_current_service();
                                } else if (response.status === 'error' && response.is_confirmed === false) {
                                    this.$router.push('customer_consent');
                                }
                            },
                            error: () => {
                                let error_message = 'Error while confirming terms and conditions';
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: error_message,
                                    confirmButtonText: 'OK'
                                });
                            }
                        });
                    } else {
                        this.$router.push({ name: 'assigned_workflows' });
                    }
                    break;
                }
                case 'CLIENTADMIN':
                    this.handleClientRedirect(status);
                    break;
                case 'SYSTEMADMIN':
                    this.$router.push('system_admin/admin_dashboard');
                    break;
                case 'DIRECTOR': {
                    switch (status) {
                        case 'no_consent':
                            this.$router.push('/director_consent');
                            break;
                        case 'not_verified':
                            this.$router.push('/facial_biometrics_verification');
                            break;
                        case 'no_proof_of_address':
                            this.$router.push('/upload_proof_of_address');
                            break;
                        default:
                            this.$router.push('/thank_you_page');
                    }
                    break;
                }
                case 'CLIENT':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('/company_pending');
            }
        },

        handleClientRedirect(status) {
            switch (status) {
                case 'no_profile_info':
                    this.$router.push('/id_verification');
                    break;
                case 'not_verified':
                    this.$router.push('/facial_biometrics');
                    break;
                case 'no_company_profile':
                    this.$router.push('/company_verification');
                    break;
                case 'failed_verification':
                case 'pending':
                case 'declined':
                    this.$router.push('/company_pending');
                    break;
                case 'approved':
                    this.$router.push('client/project_table');
                    break;
                default:
                    this.$router.push('client/project_table');
            }
        },
        validate_input(field_name) {
            let input_value = this[field_name];
            input_value = input_value.replace(/\D/g, '');
            this[field_name] = input_value;
        },

        send_otp() {
            const TOKEN = localStorage.getItem('token');
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/send_otp_email_api/`;
            $.ajax({
                url: OTP_URL,
                method: 'GET',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                success: () => {
                    this.$router.push('/user_otp');
                },
                error: () => {
                    this.$router.push('/user_otp');
                }
            });
        },

        resend_otp() {
            const TOKEN = localStorage.getItem('token')
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/send_otp_email_api/`;
            $.ajax({
                url: OTP_URL,
                method: 'GET',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    this.show_resend_message = true;
                    setTimeout(() => {
                        this.show_resend_message = false;
                    }, 5000);
                    this.$router.push('/user_otp');
                },
                error: () => {
                    Swal.close();
                    this.failed_resend_message = true;
                    setTimeout(() => {
                        this.failed_resend_message = false;
                    }, 5000);
                    this.$router.push('/user_otp');
                }
            });
        },

        submit_otp() {

            if (!this.otp.trim()) {
                $('.otp-message').removeClass('d-none');
                setTimeout(() => {
                    $('.otp-message').addClass('d-none');
                }, 3000);
                return false;
            }
            const is_form_valid = this.$refs.get_questions.validate_form();

            if (!is_form_valid) {
                return;
            }
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const OTP_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_otp_api/`;

            const process_responses = () => {
                let processed_responses = [];

                // Process main responses first
                this.responses.forEach(field => {
                    // Add main question response
                    processed_responses.push({
                        service_id: field.service_id,
                        question_id: field.question_id,
                        value: field.value,
                        parent_question_id: null
                    });

                    // Find and add any follow-up responses for this question
                    const follow_ups = this.follow_up_responses.filter(
                        follow_up => follow_up.parent_id === field.question_id
                    );

                    if (follow_ups.length > 0) {
                        const follow_up_data = follow_ups.map(follow_up => ({
                            service_id: field.service_id,
                            question_id: follow_up.question_id,
                            value: follow_up.value,
                            parent_question_id: field.question_id
                        }));
                        processed_responses = [...processed_responses, ...follow_up_data];
                    }
                });
                return processed_responses;
            };

            const all_responses = process_responses();

            const FORM_DATA = {
                client_admin_id: GET_CLIENT_ID,
                otp: this.otp,
                responses: all_responses
            };

            // Check if any response includes a file
            const has_file = this.responses.some(field => field.has_file);

            let data_to_send;
            if (has_file) {
                // If there's a file, create a FormData instance
                const form_data = new FormData();
                form_data.append('client_admin_id', GET_CLIENT_ID);
                form_data.append('otp', this.otp);

                // Append responses, handling files if present
                all_responses.forEach((response, index) => {
                    const field = [...this.responses, ...this.follow_up_responses].find(
                        f => f.id === response.question_id
                    );

                    form_data.append(`responses[${index}][service_id]`, field.service_id);
                    form_data.append(`responses[${index}][question_id]`, field.question_id);

                    if (field && field.has_file && field.value) {
                        form_data.append(`responses[${index}][uploaded_file]`, field.value);
                    } if (field.parent_question_id) {
                        form_data.append(`responses[${index}][parent_question_id]`, field.parent_question_id);
                    } else {
                        form_data.append(`responses[${index}][value]`, response.value);
                    }
                });

                data_to_send = form_data;
            } else {
                data_to_send = JSON.stringify(FORM_DATA);
            }

            // Determine content type based on the presence of files
            const content_type = has_file ? false : 'application/json';

            $.ajax({
                url: OTP_URL,
                method: 'POST',
                data: data_to_send,
                processData: !has_file,  // Do not process data if using FormData
                contentType: content_type,
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: () => {
                    Swal.close();
                    localStorage.setItem('user_state', 'otp_verified');
                    localStorage.setItem('otp_entered', 'true');
                    const CHECK_PROCESS_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;

                    $.ajax({
                        url: CHECK_PROCESS_URL,
                        method: 'GET',
                        contentType: 'application/json',
                        headers: {
                            Authorization: `Token ${TOKEN}`
                        },
                        success: (response) => {
                            this.handleRegistrationRedirect(response);
                        },
                        error: () => {
                            Swal.close();
                            let error_message = 'There was an error while redirecting';
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error_message,
                                confirmButtonText: 'OK'
                            });
                        }
                    })
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Error verifying OTP';

                    if (error.responseText.trim()) {
                        const ERROR_DATA = JSON.parse(error.responseText);
                        if (ERROR_DATA && ERROR_DATA.data) {
                            error_message = ERROR_DATA.data;
                            $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                        }
                    }
                }
            });
        }
    }
};