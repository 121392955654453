
<div class="text-center">
    <h3 class="text-dark fw-bold mb-3">
    <img src="@/assets/images/clock.png" alt="blue clock" width="45%">
    </h3>
</div>

<div class="text-center mb-3">
    <h4 class="text-dark fw-bold mb-3">
        Pending Approval
    </h4>
    <small class="text-secondary fw-bold">
        Your application is pending.  
    </small><br>
    <small class="text-secondary fw-bold">
        You will be notified once approved.  
    </small>
</div>

<div class="text-center mb-2">
    <a class="text-xs green-color" @click="logout">
      <i class="fa fa-sign-out me-1"></i> Logout
    </a>
</div>