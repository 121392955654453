<div class="container-fluid">
  <nav class="navbar bg-white mt-2 rounded-3">
      <div class="d-flex align-items-center ms-3">
          <a class="navbar-brand text-s">
            <span class="fw-normal text-xs">Dashboard</span>/<span class="fw-bold text-xs">Admin</span>
          </a>
        </div>
      <div class="d-flex align-items-center mt-2 mb-2">
        <span class="user-icon rounded-circle bg-green text-white d-flex justify-content-center align-items-center fw-bold custom-size">
          {{ getInitial(user_info.first_name) }}
        </span>
        <span class="user-name text-xs ms-2 me-3">{{ user_info.first_name }} {{ user_info.last_name }}</span>
      </div>
    </nav>
</div>