<div class="container-fluid bg-white vh-100 p-0">
  <ClientLogo />
	<BreadCrumb label="Search FCRA (AML)"/>
  
    <div class="d-flex justify-content-center align-items-center min-vh-50">
      <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 p-3">
        <div  class="form-group mt-3">
          <label for="first_name" class="fw-bold text-xs text-start d-block">First Name <span class="text-danger">*</span></label>
          <input
            v-model="first_name"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="first_name"
            placeholder="e.g., Joe"
            @input="validate_first_name"
            required
          />
          <small v-if="error_messages.first_name" class="form-text text-danger">
            {{ error_messages.first_name }}
          </small>

          <label for="last_name" class="fw-bold text-xs text-start d-block mt-3">Last Name <span class="text-danger">*</span></label>
          <input
            v-model="last_name"
            type="text"
            class="form-control mx-auto col-12 col-md-9 col-lg-8 text-xxs rounded-small p-2 shadow-sm"
            id="last_name"
            placeholder="e.g., Doe"
            @input=" validate_last_name"
            required
          />
          <small v-if="error_messages.last_name" class="form-text text-danger">
            {{ error_messages.last_name }}
          </small>
        </div>
        <div class="text-center mb-3 mt-3">
          <button
            class="btn bg-green btn-sm rounded-small shadow-sm fw-bold secondary-text-dark text-xs"
            @click.prevent="search_fcra_api"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
