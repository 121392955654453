<div class="text-center mb-3">
  <p class="text-dark fw-bold text-md">Invalid URL</p>
</div>
<hr class="fw-light" />
<div class="form-group mb-3">
  <p class="text-left text-xs text-muted">
    <span>Please verify that the <b>link</b> you have entered is correct.</span>
    <br />
    <span>If the issue persists, please contact the administrator.</span>
  </p>
</div>
<div class="mb-3">
  <a href="/">
    <button
      type="button"
      class="btn btn-bg-green secondary-text-dark text-xs w-75 fw-bold mt-2"
    >
      Login
    </button>
  </a>
</div>
