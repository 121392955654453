import { MAIN_CONTROLLER } from "@/utils/main_controller.js";
import { PREPOPULATE_INPUT } from "@/utils/prepopulate_input.js";
import { validateToken } from '@/utils/auth';
import BreadCrumb from "@/components/BreadCrumb/BreadCrumb.vue";
import ClientLogo from "@/components/ClientLogo/ClientLogo.vue";

export default {
    name: 'RefugeeLivenessVerification',
    mixins: [MAIN_CONTROLLER, PREPOPULATE_INPUT],
    components:{
      BreadCrumb,
      ClientLogo
    },
    data: () => ({
        refugee_number: '',
        photo_taken: false,
        photo_src: '',
        video_stream: null,
        is_mobile_view: false,
        error_messages: {
          refugee_number: '',
      }
    }),

    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      }
    },

    methods: {
      show_instructions() {
        setTimeout(() => {
          $('#show_instruction').modal('show');
        }, 100);
      },
      validate_fields() {
        this.error_messages = {}

        if (!this.refugee_number) {
            this.error_messages.refugee_number = "Refugee Number is required.";
        }
        // Check if there are errors and show the correct image instruction modal
        return Object.keys(this.error_messages).length === 0;
      },
      close_modal() {
        $('#show_instruction').modal('hide');
        $('#show_correct_image_instruction').modal('hide');
      },
      initialize_camera() {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            const video = this.$refs.video;
            if ('srcObject' in video) {
              video.srcObject = stream;
            } else {
              video.src = window.URL.createObjectURL(stream);
            }
            video.play();
            this.video_stream = stream;
            this.toggle_photo_sections();
          })
          .catch((error) => {
            console.error('Error accessing camera:', error);
          });
      },
      take_photo() {
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.photo_taken = true;
        this.photo_src = canvas.toDataURL();
        if (this.is_mobile_view) {
          $("#take_photo").hide();
          $("#submit_photo").show();
          $(".retake-photo").removeClass("d-none");
        }
      },
      retake_photo() {
        this.photo_taken = false;
        this.photo_src = '';
  
        if (this.is_mobile_view) {
          $("#take_photo").show();
          $("#submit_photo").hide();
        }
      },
      submit_photo() {
        if (this.validate_fields()) {
          this.refugee_verification_api();
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Refugee Number is required.',
          });
        }
      },
      refugee_verification_api() {
        const TOKEN = localStorage.getItem('token');
        const REFUGEE_VERIFICATION_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/refugee_liveness_verification_api/`;
        const DATA = {
          refugee_number: this.refugee_number,
          photo: this.photo_src,
          workflow_id: this.workflow_id
        };
        $.ajax({
          url: REFUGEE_VERIFICATION_URL,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify(DATA),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            this.fetch_current_service();
            var status_message = response.status;

            if (status_message == "success") {
              this.fetch_current_service();
            } else if (status_message == "Results not found please contact your service provider") {
              Swal.fire({
                icon: 'error',
                text: "Our service is currently unavailable. Please try again later",
              })
            } else{
              Swal.fire({
                icon: 'error',
                text: "Our service is currently unavailable. Please try again later",
              })
            }
          },
          error: (error) => {
            if (error.responseJSON?.liveness) {
                const liveness_value = error.responseJSON.liveness;
        
                // List of known liveness errors
                const known_liveness_errors = [
                    "User's image is too dark",
                    "User is wearing a hat",
                    "User is wearing glasses",
                    "User is not looking at the camera",
                    "User is using a digital device",
                    "There are two faces in the image",
                    "Face is not visible",
                    "Face could not be processed",
                ];
        
                if (known_liveness_errors.includes(liveness_value)) {
                  Swal.fire({
                    icon: 'warning',
                    title: liveness_value,
                  }).then(() => {
                    location.reload();
                  });
                } else {
                  // Handle unknown liveness errors
                  Swal.fire({
                    icon: 'warning',
                    title: "Unknown liveness issue",
                    text: liveness_value,
                  }).then(() => {
                    location.reload();
                  });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    text: "Our service is currently unavailable. Please try again later",
                });
              }
          }
        });
      },
      stop_camera() {
        if (this.video_stream) {
          const tracks = this.video_stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          this.video_stream = null;
        }
      },
      toggle_photo_sections() {
        this.is_mobile_view = window.innerWidth <= 768;
        if (this.is_mobile_view) {
          // Mobile view
          $("#take_photo").show();
          $("#submit_photo").hide();
        } else {
          // Desktop view
          $("#take_photo").show();
          $("#submit_photo").show();
        }
      },
      handle_window_resize() {
        this.toggle_photo_sections();
      }
    },
    mounted() {
      if (!validateToken(this.$router)) {
        return;
      }
      this.get_customer_data();
      const shown_instructions = localStorage.getItem('shown_instructions');
      if (!shown_instructions) {
        setTimeout(() => {
          $('#show_instruction').modal('show');
        }, 100);
        localStorage.setItem('shown_instructions', 'true');
      }
      
      // Initialize camera and other components only if modal is not shown
      if (shown_instructions) {
        this.initialize_camera();
      } else {
        // Initialize camera and other components after modal is closed
        $('#show_instruction').on('hidden.bs.modal', () => {
          this.initialize_camera();
          window.addEventListener('resize', this.handle_window_resize);
        });
      }
    },
    beforeDestroy() {
      this.stop_camera();
      window.removeEventListener('resize', this.handle_window_resize);
    },
    beforeRouteLeave(to, from, next) {
      this.stop_camera();
      next();
    },
  };