import { validateToken } from '@/utils/auth';
import { capitalize } from 'vue';
export default {
    name: "AddressComponent",
    props: {
        // Pass in the entire data array as a prop
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            main_data: this.data ? this.data.filter(
                item =>
                    // Exclude both the list header and any indexed ConsumerAddressHistory items
                    !item.key.startsWith("result.Consumer.ConsumerAddressHistory") &&
                    item.label !== 'result' &&
                    item.label !== 'status' &&
                    item.label !== 'Consumer'
            ).map(item => {
                item.label = item.label.replace(/([a-z])([A-Z])/g, '$1 $2');
                return item;
            }) : []
        };
    },
    computed: {
        // Group the ConsumerAddressHistory items by their index found in the key.
        // For example, keys like "result.Consumer.ConsumerAddressHistory[0].DisplayText" are grouped together.
        consumer_address_history_groups() {
            var groups = {};
            this.data.forEach(item => {
                // Look for the index inside square brackets
                var match = item.key.match(/ConsumerAddressHistory\[(\d+)\]/);
                if (match) {
                    var index = match[1];
                    if (!groups[index]) {
                        groups[index] = [];
                    }
                    groups[index].push(item);
                }
            });
            // Return an array of groups sorted by the index (so they display in order)
            return Object.keys(groups)
                .sort((a, b) => a - b)
                .map(key => groups[key].map(item => {
                    item.label = item.label.replace(/([a-z])([A-Z])/g, '$1 $2');
                    return item;
                }));
        },
        // Get the workflow ID from the store
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        },
    },
    mounted() {
        if (!validateToken(this.$router)) {
            return;
        }
        this.get_current_address_information();
    },
    methods: {
        get_current_address_information(){
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;

            const user_id = this.$route.params.id;
            const GET_ADDRESS_SELECTION_URL = `${BASE_URL}/verify_api/personnel_verification/get_address_selection/${user_id}/${this.workflow_id}/`;

            $.ajax({
                method: 'GET',
                url: GET_ADDRESS_SELECTION_URL,
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}`
                },
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    if (response.data && response.data.length > 0) {
                        var address_data = response.data[0];
                        var list_data = this.main_data
                        list_data.push(
                            { type: 'heading', key: 'current_address_header', label: 'Current User Address' },
                            ...Object.keys(address_data).map(key => ({
                                type: 'input',
                                key: `current_address.${key}`,
                                label: capitalize(key),
                                value: address_data[key]
                            }))
                        );
                        this.main_data = list_data;
                    }
                },
                error: (error) => {
                    Swal.fire({
                        icon: 'error',
                        text: error.responseJSON.error,
                        confirmButtonText: 'OK'
                    });
                }
            });
        }
    }
};