import Swal from 'sweetalert2';
import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
import { handle_registration_redirect } from '@/utils/redirects';

export default {
    name: 'UserRegistration',
    components: {
        GetQuestions,
    },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            password: '',
            confirm_password: '',
            phone_number_error: false,
            show_password: false,
            show_confirm_password: false,
            client_admin_id: sessionStorage.getItem('client_admin_id'),
            responses: [],
            follow_up_responses: [],
            password_strength: '',
            password_field_active: false,
            requirements: {
                lowercase: false,
                uppercase: false,
                numeric: false,
                length: false,
                special: false
            }
        };
    },

    computed: {
        strengthTextClass() {
            return {
                'text-danger': this.password_strength === 'Weak',
                'text-warning': this.password_strength === 'Medium',
                'text-success': this.password_strength === 'Strong'
            }
        },
        strengthBarClass() {
            return {
                'weak': this.password_strength === 'Weak',
                'medium': this.password_strength === 'Medium',
                'strong': this.password_strength === 'Strong'
            }
        }
    },

    mounted() {
        const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id');

        if (token && user_id) {
            this.check_and_redirect(token, GET_CLIENT_ID);
        }
    },

    methods: {
        toggle_password_view() {
            this.show_password = !this.show_password;
        },

        toggle_confirm_password_view() {
            this.show_confirm_password = !this.show_confirm_password;
        },

        convert_email_to_lowercase() {
            if (this.email) {
                this.email = this.email.toLowerCase();
            }
        },

        check_and_redirect(token, GET_CLIENT_ID) {
            const CHECK_PROCESS_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/user_redirect_api/`;

            $.ajax({
                url: CHECK_PROCESS_URL,
                method: 'GET',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${token}`
                },
                success: (response) => {
                    handle_registration_redirect(
                        response,
                        this.$router,
                        this.$store,
                        this.fetch_current_service,
                        GET_CLIENT_ID
                    );
                },
                error: () => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'There was an error while redirecting',
                        confirmButtonText: 'OK'
                    });
                }
            });
        },

        check_password_strength() {
            this.requirements = {
                lowercase: /[a-z]/.test(this.password),
                uppercase: /[A-Z]/.test(this.password),
                numeric: /[0-9]/.test(this.password),
                length: this.password.length >= 8,
                special: /[!@#$%^&*]/.test(this.password),
            };

            const met_requirements = Object.values(this.requirements).filter(Boolean).length;

            if (met_requirements <= 2) {
                this.password_strength = 'Weak';
            } else if (met_requirements <= 4) {
                this.password_strength = 'Medium';
            } else {
                this.password_strength = 'Strong';
            }
        },

        validate_password() {
            return Object.values(this.requirements).every(Boolean);
        },

        password_match() {
            if (this.password !== this.confirm_password) {
                $('.match-password-message').removeClass('d-none');
            } else {
                $('.match-password-message').addClass('d-none');
            }
            this.check_password_strength();
        },

        validate_phone_number() {
            this.phone_number = this.phone_number.replace(/\D/g, '');
            const PHONE_PATTERN = /^\d{10}$/;

            if (this.phone_number.length === 10 && PHONE_PATTERN.test(this.phone_number)) {
                this.phone_number_error = false;
            } else {
                this.phone_number_error = true;
            }
        },

        validate_name(field_name) {
            let input_value = this[field_name];
            input_value = input_value.replace(/[^a-zA-Z\s]/g, '');
            input_value = input_value
                .split(' ')
                .filter(word => word.length > 0)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
            this[field_name] = input_value;
        },

        store_responses(dynamic_fields, follow_up_responses = []) {
            this.responses = dynamic_fields;
            this.follow_up_responses = follow_up_responses;
        },

        handle_register() {
            if (this.validate_inputs()) {
                const client_admin_id = sessionStorage.getItem('client_admin_id');
                const customer_application = sessionStorage.getItem('customer_application');
                if ((client_admin_id && client_admin_id !== "null") || (customer_application && customer_application !== "null")) {
                    this.user_registration();
                } else {
                    this.client_registration();
                }
            }
        },

        validate_inputs() {
            if (!this.first_name.trim()) {
                $('.first-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.first-name-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.last_name.trim()) {
                $('.last-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.last-name-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.email.trim()) {
                $('.email-message').removeClass('d-none');
                setTimeout(() => {
                    $('.email-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.phone_number.trim()) {
                $('.phone-number-message').removeClass('d-none');
                setTimeout(() => {
                    $('.phone-number-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.password.trim()) {
                $('.password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.confirm_password.trim()) {
                $('.confirm-password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.confirm-password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (this.password !== this.confirm_password) {
                $('.match-password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.match-password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.validate_password()) {
                return false;
            }

            return true;
        },

        client_registration() {
            if (!this.validate_inputs()) {
                return;
            }
            const BASE_URL = process.env.API_BASE_URL;
            const REGISTRATION_URL = `${BASE_URL}/verify_api/system_management_api/register_api/`;
            const FORM_DATA = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone_number: this.phone_number,
                password: this.password,
                confirm_password: this.confirm_password
            };

            $.ajax({
                type: "POST",
                url: REGISTRATION_URL,
                data: JSON.stringify(FORM_DATA),
                contentType: 'application/json',
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('token', response.token);
                    this.$router.push('/user_otp');
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Registration failed';

                    if (error.responseJSON) {
                        if (error.responseJSON.email_exists_error) {
                            error_message = error.responseJSON.email_exists_error;
                            $('.email-exists-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.email-exists-message').text('').addClass('d-none');
                            }, 3000);
                        } else if (error.responseJSON.phone_number_exists_error) {
                            error_message = error.responseJSON.phone_number_exists_error;
                            $('.mobile-exists-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.mobile-exists-message').text('').addClass('d-none');
                            }, 3000);
                        } else {
                            if (error.responseJSON.email) {
                                error_message = error.responseJSON.email;
                                $('.email-error-message').text(error_message).removeClass('d-none');
                                setTimeout(() => {
                                    $('.email-error-message').text('').addClass('d-none');
                                }, 3000);
                            } else {
                                error_message = 'An error occurred during registration.';
                                $('.error-message').text(error_message).removeClass('d-none');
                                setTimeout(() => {
                                    $('.error-message').text('').addClass('d-none');
                                }, 3000);
                            }
                        }
                    }
                }
            });
        },

        user_registration() {
            const is_form_valid = this.$refs.get_questions.validate_form();

            if (!is_form_valid || !this.validate_inputs() ) {
                return;
            }

            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const CUSTOMER_APPLICATION = sessionStorage.getItem('customer_application');
            const GET_ASSET_ID = sessionStorage.getItem('asset_id');
            const GET_WORKFLOW_ID = sessionStorage.getItem('workflow_id');
            const BASE_URL = process.env.API_BASE_URL;
            const CUSTOMER_REGISTRATION_URL = `${BASE_URL}/verify_api/system_management_api/register_customer_api/`;

            const process_responses = () => {
                let processed_responses = [];

                this.responses.map(field => {
                    processed_responses.push({
                        service_id: field.service_id,
                        question_id: field.question_id,
                        value: field.value,
                        parent_question_id: null
                    });

                    const follow_ups = this.follow_up_responses.filter(
                        follow_up => follow_up.parent_id === field.question_id
                    );

                    if (follow_ups.length > 0) {
                        const follow_up_data = follow_ups.map(follow_up => ({
                            service_id: field.service_id,
                            question_id: follow_up.question_id,
                            value: follow_up.value,
                            parent_question_id: field.question_id
                        }));
                        processed_responses = [...processed_responses, ...follow_up_data];
                    }
                });
                return processed_responses;
            };

            const all_responses = process_responses();

            const FORM_DATA = {
                client_admin_id: GET_CLIENT_ID,
                customer_application: CUSTOMER_APPLICATION,
                asset_id: GET_ASSET_ID,
                workflow_id: GET_WORKFLOW_ID,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone_number: this.phone_number,
                password: this.password,
                confirm_password: this.confirm_password,
                responses: all_responses
            };

            const has_file = this.responses.some(field => field.has_file);

            let data_to_send;
            if (has_file) {
                const form_data = new FormData();
                form_data.append('client_admin_id', GET_CLIENT_ID);
                form_data.append('customer_application', CUSTOMER_APPLICATION);
                form_data.append('first_name', this.first_name);
                form_data.append('last_name', this.last_name);
                form_data.append('email', this.email);
                form_data.append('phone_number', this.phone_number);
                form_data.append('password', this.password);
                form_data.append('confirm_password', this.confirm_password);
                form_data.append('workflow_id', this.workflow_id);

                all_responses.forEach((response, index) => {
                    const field = [...this.responses, ...this.follow_up_responses].find(
                        f => f.id === response.question_id
                    );

                    form_data.append(`responses[${index}][service_id]`, field.service_id);
                    form_data.append(`responses[${index}][question_id]`, field.question_id);

                    if (field && field.has_file && field.value) {
                        form_data.append(`responses[${index}][uploaded_file]`, field.value);
                    } if (field.parent_question_id) {
                        form_data.append(`responses[${index}][parent_question_id]`, field.parent_question_id);
                    } else {
                        form_data.append(`responses[${index}][value]`, response.value);
                    }
                });

                data_to_send = form_data;
            } else {
                data_to_send = JSON.stringify(FORM_DATA);
            }

            const content_type = has_file ? false : 'application/json';

            $.ajax({
                method: "POST",
                url: CUSTOMER_REGISTRATION_URL,
                data: data_to_send,
                processData: !has_file,
                contentType: content_type,
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('token', response.token);
                    this.$router.push('/user_otp');
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Registration failed';

                    if (error.responseJSON) {
                        if (error.responseJSON.email_exists_error) {
                            error_message = error.responseJSON.email_exists_error;
                            $('.email-exists-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.email-exists-message').text('').addClass('d-none');
                            }, 3000);
                        } else if (error.responseJSON.phone_number_exists_error) {
                            error_message = error.responseJSON.phone_number_exists_error;
                            $('.mobile-exists-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.mobile-exists-message').text('').addClass('d-none');
                            }, 3000);
                        } else {
                            if (error.responseJSON.email) {
                                error_message = error.responseJSON.email;
                                $('.email-error-message').text(error_message).removeClass('d-none');
                                setTimeout(() => {
                                    $('.email-error-message').text('').addClass('d-none');
                                }, 3000);
                            } else {
                                error_message = 'An error occurred during registration.';
                                $('.error-message').text(error_message).removeClass('d-none');
                                setTimeout(() => {
                                    $('.error-message').text('').addClass('d-none');
                                }, 3000);
                            }
                        }
                    }
                }
            });
        }
    }
};