// view_assets.js

import NavBar from '@/components/NavBar/NavBar.vue';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import Swal from 'sweetalert2';
import { validateToken } from '@/utils/auth';
import { LOGOUT } from "@/utils/logout.js";

export default {
  name: 'ViewAsset',
  mixins: [LOGOUT],
  components: {
    ClientSidebar,
    NavBar,
  },
  data() {
    return {
      asset_details: {
        id: '',
        asset_name: '',
        asset_description: '',
        asset_type: '',       // Ensure this is initialized appropriately
        date_created: '',
        created_asset_id: null,
      },
      asset_file: null,        // To store the selected image file
      imageUrl: null,          // To store the image URL for preview
      asset_data: '',
      qr_code_data: '',
      workflow_data: '',
      qr_link: '',             // Assuming this is used somewhere else
      success_message: '',     // For copy link operation
      error_messages: '',      // For copy link operation
    };
  },
  methods: {
    fetch_asset_details(asset_id) {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSET_DETAILS_URL = `${BASE_URL}/verify_api/workflow_management_api/get_asset_details_api/${asset_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSET_DETAILS_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          if (response.status === 'success' && response.data) {
            this.asset_details = response.data;

            // Assign the image URL for display
            if (this.asset_details.uploaded_image_url) {
              this.imageUrl = this.asset_details.uploaded_image_url;
            } else {
              this.imageUrl = null;
            }

            // Correct assignment based on API response
            if (this.asset_details.asset_type__asset_type) {
              // If API provides asset_type__asset_type, use it
              this.asset_details.asset_type = this.asset_details.asset_type__asset_type;
            } else if (this.asset_details.asset_type && this.asset_details.asset_type.name) {
              // If asset_type is an object with a name property
              this.asset_details.asset_type = this.asset_details.asset_type.name;
            }
            // Else, asset_type is already correct or not available
          }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON
              ? error.responseJSON.message
              : 'Failed to load asset details.',
            confirmButtonText: 'OK',
          });
        },
      });
    },
    browse_file() {
      this.$refs.fileInput.click();
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handle_file_upload(event) {
      const file = event.target.files[0];

      // Validate file type
      if (file && !file.type.startsWith('image/')) {
        Swal.fire({
          icon: 'error',
          text: 'Please select a valid image file.',
        });
        return;
      }

      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file && file.size > maxSize) {
        Swal.fire({
          icon: 'error',
          text: 'File size exceeds 5MB limit.',
        });
        return;
      }

      this.asset_file = file;

      // Handle image preview
      if (this.asset_file && this.asset_file.type.startsWith('image/')) {
        // Revoke existing object URL if any
        if (this.imageUrl && this.imageUrl.startsWith('blob:')) {
          URL.revokeObjectURL(this.imageUrl);
        }
        // Create a new object URL for the uploaded image
        this.imageUrl = URL.createObjectURL(this.asset_file);
      } else {
        // If not an image file, reset the imageUrl and asset_file
        if (this.imageUrl && this.imageUrl.startsWith('blob:')) {
          URL.revokeObjectURL(this.imageUrl);
        }
        this.imageUrl = null;
        this.asset_file = null;
      }
    },

    edit_asset() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const EDIT_ASSET_URL = `${BASE_URL}/verify_api/workflow_management_api/edit_asset_api/${this.asset_details.id}/`;

      const form_data = new FormData();
      form_data.append('asset_name', this.asset_details.asset_name);
      form_data.append('asset_description', this.asset_details.asset_description);
      form_data.append('asset_type', this.asset_details.asset_type);

      if (this.asset_file) {
        form_data.append('uploaded_image', this.asset_file);
      }

      $.ajax({
        method: 'POST',
        url: EDIT_ASSET_URL,
        data: form_data,
        contentType: false,
        processData: false,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          if (response.message === 'Asset with the same name already exists') {
            Swal.fire({
              icon: 'error',
              text: 'Asset with the same name already exists',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              icon: 'success',
              text: 'Asset updated successfully.',
              confirmButtonText: 'OK',
            }).then(() => {
              window.location.reload();
            });
          }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON
              ? error.responseJSON.error
              : 'Failed to update asset.',
          });
        },
      });
    },
    view_asset(asset_id) {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSET_WORKFLOW_URL = `${BASE_URL}/verify_api/workflow_management_api/get_asset_workflows_api/${asset_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSET_WORKFLOW_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          if (response.asset_workflows && response.asset_workflows.length > 0) {
            this.asset_data = response.asset_workflows[0].asset;
            this.qr_code_data = response.asset_workflows[0].qr_code;
            this.workflow_data = response.asset_workflows[0].workflow;
          }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON
              ? error.responseJSON.error
              : 'Failed to load asset workflow data.',
          });
        },
      });
    },
    copy_qr_link() {
      navigator.clipboard
        .writeText(this.qr_link)
        .then(() => {
          this.success_message = 'Link copied to clipboard!';
          this.error_messages = '';
        })
        .catch(() => {
          this.error_messages = 'Failed to copy the link.';
          this.success_message = '';
        });
    },
  },
  mounted() {
    if (!validateToken(this.$router)) {
      return;
    }
    const asset_id = this.$route.params.id;
    this.fetch_asset_details(asset_id);
    this.view_asset(asset_id);
  },
  beforeDestroy() {
    // Revoke the object URL when the component is destroyed
    if (this.imageUrl && this.imageUrl.startsWith('blob:')) {
      URL.revokeObjectURL(this.imageUrl);
    }
  },
};