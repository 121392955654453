export const LOGOUT = {
    data: () => ({
      current_service: null,
    }),
  

    methods: {
        logout() {
            const TOKEN = localStorage.getItem('token');
            const BASE_URL = process.env.API_BASE_URL;
            const LOGOUT_URL = `${BASE_URL}/verify_api/system_management_api/logout_api/`;
    
            $.ajax({
            url: LOGOUT_URL,
            method: 'POST',
            contentType: 'application/json',
            headers: {
                Authorization: `Token ${TOKEN}`
            },
            success: (response) => {
                
                if (response.status.toLowerCase() == 'success') {
                    sessionStorage.clear(); 
                    localStorage.clear(); 

                    this.$router.push('/');
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Could not logout, please try again',
                        confirmButtonText: 'OK'
                    });
                }
              
            },
            error: () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to logout, please try again',
                    confirmButtonText: 'OK'
                });
            }
            });
    
        },
    }
  };
  